import gql from 'graphql-tag';

export const ADS_MANAGER_CAMPAIGN_ALL = gql`
  query campaignReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
    $getGoals: Boolean!
  ) {
    campaignReportsAll(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, campSearch: $campSearch) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
        draftReportsCount
      }
      grandTotals {
        total {
          tapInstalls
          viewInstalls
          totalInstalls
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          tapInstallCPI {
            amount
          }
          totalAvgCPI {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          tapInstalls
          viewInstalls
          totalInstalls
          ttr
          tapInstallRate
          totalInstallRate
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          app {
            adamId
            appName
          }
          adChannelType
        }
        campaignMetadata {
          id
          orgId
          name
          deleted
          status
          displayStatus
          servingStateReasons
          countriesOrRegions
          supplySources
          budgetAmount {
            amount
            currency
          }
          dailyBudgetAmount {
            amount
            currency
          }
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
        campaignAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_CAMPAIGN_FILTER_ALL = gql`
  query campaignReportsAll(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
  ) {
    campaignReportsForFilter: campaignReportsAll(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      campSearch: $campSearch
    ) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
      }
      row {
        campaignMetadata {
          id
          name
        }
      }
    }
  }
`;

export const ADS_MANAGER_CAMPAIGN_LIVE = gql`
  query campaignReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
    $getGoals: Boolean!
  ) {
    campaignReportsLive(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, campSearch: $campSearch) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
        draftReportsCount
      }
      grandTotals {
        total {
          tapInstalls
          viewInstalls
          totalInstalls
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          tapInstallCPI {
            amount
          }
          totalAvgCPI {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          tapInstalls
          viewInstalls
          totalInstalls
          ttr
          tapInstallRate
          totalInstallRate
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          app {
            adamId
            appName
          }
          adChannelType
        }
        campaignMetadata {
          id
          orgId
          name
          deleted
          status
          displayStatus
          servingStateReasons
          countriesOrRegions
          supplySources
          budgetAmount {
            amount
            currency
          }
          dailyBudgetAmount {
            amount
            currency
          }
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
        campaignAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_CAMPAIGN_FILTER_LIVE = gql`
  query campaignReportsLive(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
  ) {
    campaignReportsForFilter: campaignReportsLive(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      campSearch: $campSearch
    ) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
      }
      row {
        campaignMetadata {
          id
          name
        }
      }
    }
  }
`;

export const ADS_MANAGER_CAMPAIGN_PAUSED = gql`
  query campaignReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
    $getGoals: Boolean!
  ) {
    campaignReportsPaused(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, campSearch: $campSearch) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
        draftReportsCount
      }
      grandTotals {
        total {
          tapInstalls
          viewInstalls
          totalInstalls
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          tapInstallCPI {
            amount
          }
          totalAvgCPI {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          tapInstalls
          viewInstalls
          totalInstalls
          ttr
          tapInstallRate
          totalInstallRate
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          app {
            adamId
            appName
          }
          adChannelType
        }
        campaignMetadata {
          id
          orgId
          name
          deleted
          status
          displayStatus
          servingStateReasons
          countriesOrRegions
          supplySources
          budgetAmount {
            amount
            currency
          }
          dailyBudgetAmount {
            amount
            currency
          }
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
        campaignAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_CAMPAIGN_FILTER_PAUSED = gql`
  query campaignReportsPaused(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
  ) {
    campaignReportsForFilter: campaignReportsPaused(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      campSearch: $campSearch
    ) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
      }
      row {
        campaignMetadata {
          id
          name
        }
      }
    }
  }
`;

export const ADS_MANAGER_CAMPAIGN_DELETED = gql`
  query campaignReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
    $getGoals: Boolean!
  ) {
    campaignReportsDeleted(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, campSearch: $campSearch) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
        draftReportsCount
      }
      grandTotals {
        total {
          tapInstalls
          viewInstalls
          totalInstalls
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          tapInstallCPI {
            amount
          }
          totalAvgCPI {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          tapInstalls
          viewInstalls
          totalInstalls
          ttr
          tapInstallRate
          totalInstallRate
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          app {
            adamId
            appName
          }
          adChannelType
        }
        campaignMetadata {
          id
          orgId
          name
          deleted
          status
          displayStatus
          servingStateReasons
          countriesOrRegions
          supplySources
          budgetAmount {
            amount
            currency
          }
          dailyBudgetAmount {
            amount
            currency
          }
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
        campaignAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const ADS_MANAGER_CAMPAIGN_FILTER_DELETED = gql`
  query campaignReportsDeleted(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $campSearch: CampSearchReq!
  ) {
    campaignReportsForFilter: campaignReportsDeleted(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      campSearch: $campSearch
    ) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
      }
      row {
        campaignMetadata {
          id
          name
        }
      }
    }
  }
`;

export const ADS_MANAGER_CAMPAIGN_DRAFTED = gql`
  query campaignReportsDrafted($filter: Filters!, $offset: Float!, $limit: Float!, $getGoals: Boolean!) {
    campaignReportsDrafted(filter: $filter, offset: $offset, limit: $limit) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
        draftReportsCount
      }
      grandTotals {
        total {
          tapInstalls
          viewInstalls
          totalInstalls
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          tapInstallCPI {
            amount
          }
          totalAvgCPI {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          tapInstalls
          viewInstalls
          totalInstalls
          ttr
          tapInstallRate
          totalInstallRate
          tapNewDownloads
          viewNewDownloads
          totalNewDownloads
          tapRedownloads
          viewRedownloads
          totalRedownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          app {
            adamId
            appName
          }
          adChannelType
        }
        campaignMetadata {
          id
          orgId
          name
          deleted
          status
          displayStatus
          servingStateReasons
          countriesOrRegions
          budgetAmount {
            amount
            currency
          }
          dailyBudgetAmount {
            amount
            currency
          }
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
        campaignAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;
export const CAMPAIGN_TOTAL_SUMMARY = gql`
  query campaignTotalSummary($filter: Filters!) {
    campaignTotalSummary(filter: $filter) {
      tapInstalls
      viewInstalls
      totalInstalls
      tapNewDownloads
      viewNewDownloads
      totalNewDownloads
      tapRedownloads
      viewRedownloads
      totalRedownloads
      ttr
      taps
      impressions
      localSpend {
        amount
      }
    }
  }
`;

export const ADS_MANAGER_CAMPAIGN_FILTER_DRAFTED = gql`
  query campaignReportsDrafted($filter: Filters!, $offset: Float!, $limit: Float!) {
    campaignReportsForFilter: campaignReportsDrafted(filter: $filter, offset: $offset, limit: $limit) {
      campaignCounts {
        allReportsCount
        liveReportsCount
        disabledReportsCount
        deletedReportsCount
      }
      row {
        campaignMetadata {
          id
          name
        }
      }
    }
  }
`;
