var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: { flat: "", width: "100%", height: "auto", "min-height": "350px" },
    },
    [
      _c(
        "div",
        { attrs: { id: "app" } },
        [
          this.tabindex !== 0
            ? _c(
                "v-row",
                {
                  staticClass:
                    "d-flex align-start justify-space-between my-2 mx-0 pt-1",
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex pa-0",
                      attrs: { cols: "12", lg: "3", md: "3", sm: "3" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-start w-100",
                        },
                        [
                          _c("v-select", {
                            staticClass: "  ",
                            attrs: {
                              items: _vm.headerView,
                              "item-text": "text",
                              attach: "",
                              label: "Select Datasets",
                              dense: "",
                              outlined: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.headerViewChanged($event)
                              },
                            },
                            model: {
                              value: _vm.selectedHeaderView,
                              callback: function ($$v) {
                                _vm.selectedHeaderView = $$v
                              },
                              expression: "selectedHeaderView",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex justify-end align-center pa-0 report-btn",
                      attrs: { cols: "12", lg: "9", md: "9", sm: "9" },
                    },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            class: [
                              " ",
                              _vm.isSuperAdmin
                                ? "primary"
                                : _vm.permissions.includes(
                                    _vm.SubscriptionPermissionsEnums.REPORTS
                                  )
                                ? "primary"
                                : "disabled-button",
                            ],
                            attrs: { color: "" },
                          },
                          _vm.isSuperAdmin
                            ? {
                                click: function () {
                                  return _vm.$router.push({
                                    name: "ScheduleReports",
                                    path: "/schedule-reports",
                                  })
                                },
                              }
                            : _vm.permissions.includes(
                                _vm.SubscriptionPermissionsEnums.REPORTS
                              ) == false
                            ? {
                                click: function () {
                                  return _vm.openPermissionPopup()
                                },
                              }
                            : {
                                click: function () {
                                  return _vm.$router.push({
                                    name: "ScheduleReports",
                                    path: "/schedule-reports",
                                  })
                                },
                              }
                        ),
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v(" mdi-calendar ")]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("sr.scheduleReports")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d-flex",
              style:
                this.tabInfo == "strategies"
                  ? { display: "none !important" }
                  : "",
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.headers,
                  "item-text": "text",
                  attach: "",
                  dense: "",
                  outlined: "",
                  "return-object": "",
                  multiple: "",
                  "small-chips": "",
                  "deletable-chips": "",
                },
                model: {
                  value: _vm.selectedHeaders,
                  callback: function ($$v) {
                    _vm.selectedHeaders = $$v
                  },
                  expression: "selectedHeaders",
                },
              }),
            ],
            1
          ),
          this.tabInfo !== "strategies"
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-center justify-space-between w-100 mb-3 px-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "filter-chips d-flex align-center flex-wrap",
                    },
                    [
                      this.tabInfo == "apps"
                        ? _c(
                            "div",
                            _vm._l(_vm.headers, function (header, i) {
                              return _c(
                                "span",
                                { key: i },
                                [
                                  _vm.appsFilter.hasOwnProperty(header.value)
                                    ? _c(
                                        "v-chip",
                                        [
                                          _vm._v(
                                            _vm._s(header.text) +
                                              ": " +
                                              _vm._s(
                                                _vm.appsFilter[header.value]
                                              )
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { primary: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removedFilter(
                                                    header.value,
                                                    $event,
                                                    i
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                  this.tabInfo == "accounts"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.accountsFilter.hasOwnProperty(header.value)
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.accountsFilter[header.value]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "searchterm"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.searchTermFilter.hasOwnProperty(header.value)
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.searchTermFilter[header.value]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "livecampaign"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.campaignsSearchLiveFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.campaignsSearchLiveFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "pausedcampaign"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.campaignsSearchPausedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.campaignsSearchPausedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "deletedcampaign"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.campaignsSearchDeletedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.campaignsSearchDeletedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "allcampaign"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.campaignsSearchAllFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.campaignsSearchAllFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "liveadgroup"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adgroupSearchLiveFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adgroupSearchLiveFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "pausedadgroup"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adgroupSearchPausedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adgroupSearchPausedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "deletedadgroup"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adgroupSearchDeletedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adgroupSearchDeletedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "alladgroup"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adgroupSearchAllFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adgroupSearchAllFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "livekeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.liveKeywordSearchFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.liveKeywordSearchFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "pausedkeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.pausedKeywordSearchFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.pausedKeywordSearchFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "deletedkeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.deletedKeywordSearchFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.deletedKeywordSearchFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "allkeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.allKeywordSearchFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.allKeywordSearchFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "ads"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adsdSearchLiveFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adsdSearchLiveFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "pausedads"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adsdSearchPausedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adsdSearchPausedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "deletedads"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adsdSearchDeletedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adsdSearchDeletedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "allads"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.adsdSearchAllFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.adsdSearchAllFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "livenegativekeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.negKeywordSearchLiveFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.negKeywordSearchLiveFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "pausednegativekeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.negKeywordSearchPausedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.negKeywordSearchPausedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "deletednegativekeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.negKeywordSearchDeletedFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.negKeywordSearchDeletedFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  this.tabInfo == "allnegativekeyword"
                    ? _c(
                        "div",
                        _vm._l(_vm.headers, function (header, i) {
                          return _c(
                            "span",
                            { key: i },
                            [
                              _vm.negKeywordSearchAllFilter.hasOwnProperty(
                                header.value
                              )
                                ? _c(
                                    "v-chip",
                                    [
                                      _vm._v(
                                        _vm._s(header.text) +
                                          ": " +
                                          _vm._s(
                                            _vm.negKeywordSearchAllFilter[
                                              header.value
                                            ]
                                          )
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { primary: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removedFilter(
                                                header.value,
                                                $event,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "floating-filter cursor-pointer d-flex align-baseline",
                    },
                    [
                      _vm.selectedItems.length &&
                      (_vm.tab == "All-Campaigns" ||
                        _vm.tab == "Live-Campaigns" ||
                        _vm.tab == "All-Adgroups" ||
                        _vm.tab == "Live-Adgroups" ||
                        _vm.tab == "All-Targeting-Keywords" ||
                        _vm.tab == "Live-Targeting-Keywords")
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "primarydark" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "mx-2 bold-icon strats-icon",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: _vm.getStrategies,
                                                },
                                              },
                                              "span",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  width: "24",
                                                  height: "21",
                                                  viewBox: "0 0 24 24",
                                                  fill: "none",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M10.4386 8.06348C10.0797 8.84738 10.0532 10.8515 12.8186 12.5969C13.7631 13.0786 15.0135 14.2715 15.4536 15.3169C15.907 16.3936 15.6803 17.4136 14.6603 18.462",
                                                    stroke: "#365CA8",
                                                    "stroke-width": "1.25",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M10.4389 8.09176C10.6844 8.68677 11.5212 9.85978 12.9039 9.79178C13.1306 10.0468 13.6803 10.5398 14.0656 10.4718C14.5473 10.3868 14.9723 10.0751 15.2273 9.70678C15.4823 9.33844 15.3406 8.48843 15.2273 8.29009C15.1366 8.13142 12.9984 5.67394 11.9406 4.46504C12.0728 4.00225 12.2976 2.86135 12.1389 2C11.9217 2.00944 11.2379 2.272 10.2405 3.24669C10.2028 3.01057 9.97889 2.6918 9.27719 2.59501C8.45549 2.48167 5.9338 2.31167 4.23377 5.37172C2.87375 7.81975 4.0071 11.3407 4.74378 12.7952C5.21601 13.9568 6.0528 16.7222 5.62213 18.4902",
                                                    stroke: "#365CA8",
                                                    "stroke-width": "1.25",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                  },
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M16.3 18H3.7C3.3134 18 3 18.3134 3 18.7C3 19.0866 3.3134 19.4 3.7 19.4H16.3C16.6866 19.4 17 19.0866 17 18.7C17 18.3134 16.6866 18 16.3 18Z",
                                                    fill: "#365CA8",
                                                  },
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M18 20H2C1.44772 20 1 20.4477 1 21C1 21.5523 1.44772 22 2 22H18C18.5523 22 19 21.5523 19 21C19 20.4477 18.5523 20 18 20Z",
                                                    fill: "#365CA8",
                                                  },
                                                }),
                                                _c("rect", {
                                                  attrs: {
                                                    x: "14",
                                                    y: "4",
                                                    width: "7",
                                                    height: "1.25",
                                                    fill: "#365CA8",
                                                  },
                                                }),
                                                _c("rect", {
                                                  attrs: {
                                                    x: "18.125",
                                                    y: "1.125",
                                                    width: "7",
                                                    height: "2",
                                                    transform:
                                                      "rotate(90 18.125 1.125)",
                                                    fill: "#365CA8",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                141760520
                              ),
                            },
                            [_c("span", [_vm._v("Add to Strategy")])]
                          )
                        : _vm._e(),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500", persistent: "" },
                          model: {
                            value: _vm.addToStrategyModal,
                            callback: function ($$v) {
                              _vm.addToStrategyModal = $$v
                            },
                            expression: "addToStrategyModal",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                                },
                                [
                                  _vm._v(" Add to Strategy "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "white--text",
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.strategyOptions,
                                      label: "Add to which Strategy?",
                                      "menu-props": {
                                        contentClass: "selected-strategy",
                                      },
                                    },
                                    on: { change: _vm.handleStrategyChange },
                                    model: {
                                      value: _vm.selectedStrategy,
                                      callback: function ($$v) {
                                        _vm.selectedStrategy = $$v
                                      },
                                      expression: "selectedStrategy",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        disabled: !_vm.selectedStrategy,
                                        color: _vm.selectedStrategy
                                          ? "primary"
                                          : "",
                                      },
                                      on: { click: _vm.addEntities },
                                    },
                                    [_vm._v("Add")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      on: { click: _vm.closeDialog },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _vm.selectedStrategy &&
                                  _vm.cautionMessage &&
                                  _vm.cautionMessage.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "16px",
                                          },
                                        },
                                        [
                                          _c("v-textarea", {
                                            staticClass:
                                              "mx-2 mt-6 caution-box",
                                            attrs: {
                                              readonly: "",
                                              "auto-grow": "",
                                              outlined: "",
                                              label:
                                                "Caution: one or more selected entities belong to an existing strategy.",
                                              rows: "3",
                                            },
                                            model: {
                                              value: _vm.cautionMessage,
                                              callback: function ($$v) {
                                                _vm.cautionMessage = $$v
                                              },
                                              expression: "cautionMessage",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500", persistent: "" },
                          model: {
                            value: _vm.addEntitiesPopup,
                            callback: function ($$v) {
                              _vm.addEntitiesPopup = $$v
                            },
                            expression: "addEntitiesPopup",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                                },
                                [_vm._v("Added to Strategy")]
                              ),
                              _c("br"),
                              _c("v-card-text", [
                                _vm._v(
                                  " Entities added to '" +
                                    _vm._s(
                                      _vm.selectedStrategy
                                        ? _vm.selectedStrategy.name
                                        : ""
                                    ) +
                                    "' Strategy "
                                ),
                              ]),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.returnsToAdsManager },
                                    },
                                    [_vm._v("Return to Ads Manager")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "success" },
                                      on: { click: _vm.viewStrategies },
                                    },
                                    [_vm._v("View Strategy")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.selectedItems.length &&
                      (_vm.tab == "All-Campaigns" ||
                        _vm.tab == "Live-Campaigns" ||
                        _vm.tab == "All-Adgroups" ||
                        _vm.tab == "Live-Adgroups" ||
                        _vm.tab == "All-Targeting-Keywords" ||
                        _vm.tab == "Live-Targeting-Keywords")
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "primarydark" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-2",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bulkAction(
                                                      "PAUSED"
                                                    )
                                                  },
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("mdi-pause")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3286066502
                              ),
                            },
                            [_c("span", [_vm._v("Pause")])]
                          )
                        : _vm._e(),
                      _vm.selectedItems.length &&
                      (_vm.tab == "All-Campaigns" ||
                        _vm.tab == "Disabled-Campaigns" ||
                        _vm.tab == "All-Adgroups" ||
                        _vm.tab == "Disabled-Adgroups" ||
                        _vm.tab == "All-Targeting-Keywords" ||
                        _vm.tab == "Disabled-Targeting-Keywords")
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "primarydark" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-2",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bulkAction(
                                                      "ENABLED"
                                                    )
                                                  },
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("mdi-replay")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1643638036
                              ),
                            },
                            [_c("span", [_vm._v("Live")])]
                          )
                        : _vm._e(),
                      _vm.selectedItems.length &&
                      (_vm.tab == "All-Campaigns" ||
                        _vm.tab == "Live-Campaigns" ||
                        _vm.tab == "Disabled-Campaigns" ||
                        _vm.tab == "All-Adgroups" ||
                        _vm.tab == "Live-Adgroups" ||
                        _vm.tab == "Disabled-Adgroups" ||
                        _vm.tab == "All-Targeting-Keywords" ||
                        _vm.tab == "Live-Targeting-Keywords" ||
                        _vm.tab == "Disabled-Targeting-Keywords")
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "primarydark" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mx-2",
                                                attrs: { color: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.bulkAction(
                                                      "DELETE"
                                                    )
                                                  },
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("mdi-delete-outline")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2947582575
                              ),
                            },
                            [_c("span", [_vm._v("Delete")])]
                          )
                        : _vm._e(),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", color: "primarydark" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mx-2",
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                _vm.searchInput = !_vm.searchInput
                                              },
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-filter-variant")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4069304545
                          ),
                        },
                        [_c("span", { staticClass: " " }, [_vm._v("Filter")])]
                      ),
                      !this.progressBar
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-end img-outer",
                              on: { click: _vm.downloadReport },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-img",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: {
                                                      src: require("../assets/images/csv.svg"),
                                                    },
                                                  },
                                                  "v-img",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2980180571
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("sr.downloadReport"))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      this.progressBar
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex justify-end img-outer",
                              staticStyle: { margin: "auto" },
                              attrs: { disabled: "" },
                            },
                            [
                              _c(
                                "v-progress-circular",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ma-0 mr-1 cursor-pointer",
                                      attrs: {
                                        size: "16",
                                        width: "2",
                                        indeterminate: "",
                                        color: "primary",
                                      },
                                    },
                                    "v-progress-circular",
                                    _vm.attrs,
                                    false
                                  ),
                                  _vm.on
                                )
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.customTab == "keywords" ||
          _vm.customTab == "ads" ||
          _vm.customTab == "searchterm" ||
          _vm.customTab == "apps" ||
          _vm.customTab == "accounts" ||
          _vm.customTab == "adgroups" ||
          _vm.customTab == "negativekeywords" ||
          _vm.customTab == "campaigns"
            ? _c("v-data-table", {
                directives: [
                  {
                    name: "sortable-table",
                    rawName: "v-sortable-table",
                    value: { onEnd: _vm.sortTheHeadersAndUpdateTheKey },
                    expression: "{ onEnd: sortTheHeadersAndUpdateTheKey }",
                  },
                ],
                key: _vm.anIncreasingNumber,
                staticClass:
                  "elevation-0 table-style-3 custom-header h-fit-content",
                class: [
                  _vm.customTab == "campaigns"
                    ? "camp-table-tooltip"
                    : _vm.customTab == "adgroups"
                    ? "adgrp-table-tooltip"
                    : _vm.customTab == "keywords"
                    ? "keywrd-table-tooltip"
                    : "",
                ],
                attrs: {
                  "header-props": { sortIcon: "mdi-arrow-down" },
                  "items-per-page": 10,
                  "fixed-header": "",
                  "item-key": "name",
                  height: _vm.tableHeight,
                  loading: _vm.loading,
                  headers: _vm.finalHeaders,
                  items: _vm.formattedItems,
                  "no-data-text":
                    _vm.tabInfo === "strategies"
                      ? "Created strategies with at least one target entity are displayed here"
                      : _vm.$t("dropdowns.noData"),
                  "server-items-length": _vm.itemsLength,
                  options: _vm.pagination,
                  "footer-props": {
                    itemsPerPageOptions: [10, 25, 50],
                  },
                  "show-select":
                    (_vm.customTab == "keywords" ||
                      _vm.customTab == "adgroups" ||
                      _vm.customTab == "campaigns") &&
                    _vm.tabInfo != "draftcampaign" &&
                    _vm.tabInfo != "deletedcampaign" &&
                    _vm.tabInfo != "deletedadgroup" &&
                    _vm.tabInfo != "deletedkeyword"
                      ? true
                      : false,
                  "single-select": _vm.singleSelect,
                  "item-key":
                    _vm.customTab == "campaigns"
                      ? "campaignMetadata.id"
                      : _vm.customTab == "adgroups"
                      ? "adgroupMetadata.id"
                      : _vm.customTab == "keywords"
                      ? "keywordMetadata.id"
                      : "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                      return {
                        key: slot,
                        fn: function (scope) {
                          return [_vm._t(slot, null, null, scope)]
                        },
                      }
                    }),
                    {
                      key: "body.prepend",
                      fn: function (ref) {
                        var headers = ref.headers
                        return [
                          _c(
                            "tr",
                            {},
                            _vm._l(headers, function (header, i) {
                              return _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.searchInput,
                                      expression: "searchInput",
                                    },
                                  ],
                                  key: i,
                                  staticClass: " ",
                                },
                                [
                                  header.value != "calcAMPU" &&
                                  header.value != "calcARPU" &&
                                  header.value != "calcGoalRoas" &&
                                  header.value != "calcCostPerGoal" &&
                                  header.value != "calcCostPerGoalSec" &&
                                  header.value != "calcRevenuePerGoal" &&
                                  header.value != "calcGoalsRevenue" &&
                                  header.value != "calcEventCount" &&
                                  header.value != "calcEventCountSec" &&
                                  header.value != "orgMetadata.orgName" &&
                                  header.value !=
                                    "campaignMetadata.countriesOrRegions" &&
                                  header.value != "status" &&
                                  header.value != "deleted" &&
                                  header.value != "Action" &&
                                  header.value !=
                                    "campaignMetadata.displayStatus" &&
                                  header.value != "campaignMetadata.deleted" &&
                                  header.value != "campaignMetadata.status" &&
                                  header.value != "adgroupMetadata.status" &&
                                  header.value !=
                                    "metadata.adGroupDisplayStatus" &&
                                  header.value != "adgroupMetadata.deleted" &&
                                  header.value != "keywordMetadata.status" &&
                                  header.value != "keywordMetadata.deleted" &&
                                  header.value != "adgroupMetadata.status" &&
                                  header.value != "adsMetadata.status" &&
                                  header.value != "adsMetadata.deleted" &&
                                  header.value != "conversion" &&
                                  header.value != "modificationTime" &&
                                  header.value != "campaign.name" &&
                                  header.value !=
                                    "campaign.countriesOrRegions" &&
                                  header.value !=
                                    "campaign.budgetAmount.amount" &&
                                  header.value != "resume" &&
                                  _vm.customTab != "keywords"
                                    ? _c("v-text-field", {
                                        key: i,
                                        staticClass:
                                          "v-sel-custom min-120 pt-0 mt-2 filter-search",
                                        attrs: {
                                          color: "primary",
                                          loading: _vm.loadEnabled == i,
                                          type: "text",
                                          placeholder: header.text,
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            return _vm.debouncedInput(
                                              header.value,
                                              $event,
                                              i
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "progress",
                                              fn: function () {
                                                return [
                                                  _vm.custom
                                                    ? _c("v-progress-linear", {
                                                        attrs: {
                                                          value: "80",
                                                          color: "primary",
                                                          absolute: "",
                                                          height: "7",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.multiSearch[header.value],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.multiSearch,
                                              header.value,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "multiSearch[header.value]",
                                        },
                                      })
                                    : _vm._e(),
                                  header.value != "calcAMPU" &&
                                  header.value != "calcARPU" &&
                                  header.value != "calcGoalRoas" &&
                                  header.value != "calcCostPerGoal" &&
                                  header.value != "calcRevenuePerGoal" &&
                                  header.value != "calcGoalsRevenue" &&
                                  header.value != "orgMetadata.orgName" &&
                                  header.value !=
                                    "campaignMetadata.countriesOrRegions" &&
                                  header.value != "status" &&
                                  header.value != "deleted" &&
                                  header.value != "Action" &&
                                  header.value !=
                                    "campaignMetadata.displayStatus" &&
                                  header.value != "campaignMetadata.deleted" &&
                                  header.value != "campaignMetadata.status" &&
                                  header.value != "adgroupMetadata.status" &&
                                  header.value !=
                                    "metadata.adGroupDisplayStatus" &&
                                  header.value != "adgroupMetadata.deleted" &&
                                  header.value != "keywordMetadata.status" &&
                                  header.value != "keywordMetadata.deleted" &&
                                  header.value != "adgroupMetadata.status" &&
                                  header.value != "adsMetadata.status" &&
                                  header.value != "adsMetadata.deleted" &&
                                  header.value != "conversion" &&
                                  header.value != "modificationTime" &&
                                  header.value != "campaign.name" &&
                                  header.value !=
                                    "campaign.countriesOrRegions" &&
                                  header.value !=
                                    "campaign.budgetAmount.amount" &&
                                  header.value != "resume" &&
                                  header.value != "total.avgCPM.amount" &&
                                  header.value != "total.avgCPA.amount" &&
                                  header.value != "total.avgCPT.amount" &&
                                  header.value != "total.ttr" &&
                                  header.value != "total.conversionRate" &&
                                  _vm.customTab == "keywords"
                                    ? _c("v-text-field", {
                                        key: i,
                                        staticClass:
                                          "v-sel-custom min-120 pt-0 mt-2 filter-search",
                                        attrs: {
                                          color: "primary",
                                          loading: _vm.loadEnabled == i,
                                          type: "text",
                                          placeholder: header.text,
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            return _vm.debouncedInput(
                                              header.value,
                                              $event,
                                              i
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "progress",
                                              fn: function () {
                                                return [
                                                  _vm.custom
                                                    ? _c("v-progress-linear", {
                                                        attrs: {
                                                          value: "80",
                                                          color: "primary",
                                                          absolute: "",
                                                          height: "7",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.multiSearch[header.value],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.multiSearch,
                                              header.value,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "multiSearch[header.value]",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    },
                    _vm.items.length != 0
                      ? {
                          key: "body.append",
                          fn: function (ref) {
                            var headers = ref.headers
                            return [
                              _c(
                                "tr",
                                _vm._l(headers, function (header, i) {
                                  return _c("td", { key: i }, [
                                    header.value == "total.impressions"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "impressions"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.taps"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("taps")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapInstalls"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapInstalls"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.viewInstalls"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "viewInstalls"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalInstalls"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalInstalls"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapNewDownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapNewDownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.viewNewDownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "viewNewDownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalNewDownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalNewDownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapRedownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapRedownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.viewRedownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "viewRedownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalRedownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalRedownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.ttr"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("ttr")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.localSpend.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getCurrencyIcon(
                                                    _vm.componentCurrency
                                                  )
                                                ) +
                                                _vm._s(
                                                  _vm.calculateSum("localSpend")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapInstallCPI.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapInstallCPI"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalAvgCPI.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalAvgCPI"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.avgCPT.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("avgCPT")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.avgCPM.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("avgCPM")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapInstallRate"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapInstallRate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalInstallRate"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalInstallRate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcGoalsRevenue"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcGoalsRevenue",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcGoalRoas"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcGoalRoas",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcEventCount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcEventCount"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcEventCountSec"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcEventCountSec"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcCostPerGoal"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcCostPerGoal",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcCostPerGoalSec"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcCostPerGoalSec",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcRevenuePerGoal"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcRevenuePerGoal",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcARPU"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcARPU",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcAMPU"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcAMPU",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("div"),
                                  ])
                                }),
                                0
                              ),
                            ]
                          },
                        }
                      : null,
                    {
                      key: "item.total.localSpend.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCurrencyIcon(item.orgMetadata.currency)
                              ) +
                              _vm._s(
                                _vm.numberDecimal(item.total.localSpend.amount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.avgCPA.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCurrencyIcon(item.orgMetadata.currency)
                              ) +
                              _vm._s(
                                _vm.numberDecimal(item.total.avgCPA.amount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.tapInstallCPI.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCurrencyIcon(item.orgMetadata.currency)
                              ) +
                              _vm._s(
                                _vm.numberDecimal(
                                  item.total.tapInstallCPI.amount
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.totalAvgCPI.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCurrencyIcon(item.orgMetadata.currency)
                              ) +
                              _vm._s(
                                _vm.numberDecimal(item.total.totalAvgCPI.amount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.avgCPM.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCurrencyIcon(item.orgMetadata.currency)
                              ) +
                              _vm._s(
                                _vm.numberDecimal(item.total.avgCPM.amount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.avgCPT.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getCurrencyIcon(item.orgMetadata.currency)
                              ) +
                              _vm._s(
                                _vm.numberDecimal(item.total.avgCPT.amount)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.impressions",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.numberFormat(item.total.impressions)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.tapInstalls",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.numberFormat(item.total.tapInstalls)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.viewInstalls",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.viewInstalls)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.totalInstalls",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.totalInstalls)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.taps",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.numberFormat(item.total.taps)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.tapNewDownloads",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.tapNewDownloads)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.viewNewDownloads",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.viewNewDownloads)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.totalNewDownloads",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.totalNewDownloads)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.tapRedownloads",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.tapRedownloads)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.viewRedownloads",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.viewRedownloads)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.totalRedownloads",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberFormat(item.total.totalRedownloads)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.ttr",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.numberDecimal(item.total.ttr) + "%") +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.tapInstallRate",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberDecimal(item.total.tapInstallRate) +
                                  "%"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.total.totalInstallRate",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.numberDecimal(item.total.totalInstallRate) +
                                  "%"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedItems,
                  callback: function ($$v) {
                    _vm.selectedItems = $$v
                  },
                  expression: "selectedItems",
                },
              })
            : _vm._e(),
          _vm.customTab != "keywords" &&
          _vm.customTab != "ads" &&
          _vm.customTab != "searchterm" &&
          _vm.customTab != "apps" &&
          _vm.customTab != "accounts" &&
          _vm.customTab != "adgroups" &&
          _vm.customTab != "negativekeywords" &&
          _vm.customTab != "campaigns"
            ? _c("v-data-table", {
                directives: [
                  {
                    name: "sortable-table",
                    rawName: "v-sortable-table",
                    value: { onEnd: _vm.sortTheHeadersAndUpdateTheKey },
                    expression: "{ onEnd: sortTheHeadersAndUpdateTheKey }",
                  },
                ],
                key: _vm.anIncreasingNumber,
                staticClass:
                  "elevation-0 table-style-3 custom-header h-fit-content",
                attrs: {
                  "items-per-page": 10,
                  loading: _vm.loading,
                  "fixed-header": "",
                  height: _vm.tableHeight,
                  headers: _vm.finalHeaders,
                  items: _vm.formattedItems,
                  options: _vm.pagination,
                  "no-data-text":
                    _vm.tabInfo === "strategies"
                      ? "Created strategies with at least one target entity are displayed here"
                      : _vm.$t("dropdowns.noData"),
                  "footer-props": {
                    itemsPerPageOptions: [10, 25, 50],
                  },
                },
                on: {
                  "update:options": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                      return {
                        key: slot,
                        fn: function (scope) {
                          return [_vm._t(slot, null, null, scope)]
                        },
                      }
                    }),
                    _vm.items.length != 0
                      ? {
                          key: "body.append",
                          fn: function (ref) {
                            var headers = ref.headers
                            return [
                              _c(
                                "tr",
                                _vm._l(headers, function (header, i) {
                                  return _c("td", { key: i }, [
                                    header.value == "total.impressions"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "impressions"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.taps"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("taps")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapInstalls"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapInstalls"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.viewInstalls"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "viewInstalls"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalInstalls"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalInstalls"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapNewDownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapNewDownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.viewNewDownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "viewNewDownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalNewDownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalNewDownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapRedownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapRedownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.viewRedownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "viewRedownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalRedownloads"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalRedownloads"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.ttr"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("ttr")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.localSpend.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("localSpend")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapInstallCPI.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapInstallCPI"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalAvgCPI.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalAvgCPI"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.avgCPT.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("avgCPT")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.avgCPM.amount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum("avgCPM")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.tapInstallRate"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "tapInstallRate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "total.totalInstallRate"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calculateSum(
                                                    "totalInstallRate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcGoalsRevenue"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcGoalsRevenue",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcGoalRoas"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcGoalRoas",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcEventCount"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcEventCount"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcEventCountSec"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcEventCountSec"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcCostPerGoal"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcCostPerGoal",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcCostPerGoalSec"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcCostPerGoalSec",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcRevenuePerGoal"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcRevenuePerGoal",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcARPU"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcARPU",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    header.value == "calcAMPU"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold primary--text text-end",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.calcAttrSum(
                                                    "calcAMPU",
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("div"),
                                  ])
                                }),
                                0
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("PermissionPopup", {
        attrs: { permissionMetadata: _vm.permissionMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }