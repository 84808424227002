






























































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { numberFormat } from 'src/utils/util';
import Vue from 'vue';

export default Vue.extend({
  name: 'TotalSummary',
  props: {
    tab: {
      type: Number,
    },
    values: {
      required: true,
      type: Object,
      default() {
        return {
          taps: 0,
          impressions: 0,
          ttr: 0,
          tapNewDownloads: 0,
          viewNewDownloads: 0,
          totalNewDownloads: 0,
          tapRedownloads: 0,
          viewRedownloads: 0,
          totalRedownloads: 0,
          tapInstalls: 0,
          viewInstalls: 0,
          totalInstalls: 0,
          localSpend: {
            amount: 0,
          },
          goalEventCount: 0,
          goalRevenue: 0
        };
      },
    },
    rowItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  computed: {
    summaryItems: {
      cache: false,
      get() {
        const items = [
          {
            title: this.$t('summary.impressions'),
            key: 'impressions',
            tooltip: 'The number of times your ad appeared in App Store search results within the reporting time period.',
          },
          {
            title: this.$t('summary.taps'),
            key: 'taps',
            tooltip: 'The number of times your ad was tapped by users within the reporting time period.',
          },
          {
            title: this.$t('summary.totalInstalls'),
            key: 'totalInstalls',
            tooltip:
              'The total number of conversions from new downloads and redownloads resulting from an ad within the reporting period. Apple Search Ads tapInstalls are attributed within a 30-day tap-through window.',
          },
          {
            title: this.$t('summary.ttr'),
            key: 'ttr',
            tooltip:
              'The tap-through rate (TTR) is the number of times your ad was tapped by customers divided by the total impressions your ad received.',
            percentage: true,
          },
          {
              title: this.$t('summary.totalNewDownloads'),
              key: 'totalNewDownloads',
              tooltip: 'These represent app downloads from new users who have never before downloaded your app and tapped your ad within a 30-day attribution window.',
            },
            {
              title: this.$t('summary.totalRedownloads'),
              key: 'totalRedownloads',
              tooltip:
                'Redownloads occur when a user downloads your app, deletes it, and downloads the same app again following a tap on an ad on the App Store, or downloads the same app on an additional device.',
            }
        ];

        // let subItems = []

        // if(this.tab == 2 || this.tab == 3 || this.tab == 4 || this.tab == 5) {
        //   subItems = [{
        //     title: 'Goal Event Count',
        //     key: 'goalEventCount',
        //     tooltip: 'Total event count of MMP attributed users.',
        //   },
        //   {
        //     title: 'Goal Revenue',
        //     key: 'goalRevenue',
        //     tooltip: 'Total revenue generated by MMP attributed users.',
        //     currency: true,
        //   }]
        // }
        // else {
        //   subItems = [{
        //     title: this.$t('summary.newDownloads'),
        //     key: 'newDownloads',
        //     tooltip: 'These represent app downloads from new users who have never before downloaded your app.',
        //   },
        //   {
        //     title: this.$t('summary.reDownloads'),
        //     key: 'redownloads',
        //     tooltip:
        //       'Redownloads occur when a user downloads your app, deletes it, and downloads the same app again following a tap on an ad on the App Store, or downloads the same app on an additional device.',
        //   }]
        // }

        // items.push(...subItems)
        return items
      },
    },
  },
  methods: {
    getValue(key: string): string {
      if(key == 'goalEventCount' || key == 'goalRevenue') {
        let tabString = '';
        switch(this.tab) {
          case 1: tabString = 'appAttributionData'
          break;
          case 2: tabString = 'campaignAttributionData'
          break;
          case 3: tabString = 'adgroupAttributionData'
          break;
          case 4: tabString = 'keywordAttributionData'
          break;
          case 5: tabString = 'productPageAttributionData'
          break;
          default: tabString = ''
          break;
        }
        const subArray = this.rowItems.map((item) => ({
          goalEventCount: item?.[tabString]?.content?.eventCount?.count,
          goalRevenue: item?.[tabString]?.content?.revenue?.total
        }))

        const total = subArray.reduce(
          (a, b) => (Number(a) + (Number(b[key]) || 0)).toFixed(2),
          0
        );

        return total
      }
      else {
        return this.values[key];
      }
    },
    convertToShortCurr(curr) {
      if (curr === undefined || curr === null ) return ''
      const num = numberFormat(curr);
      if (curr<=1000) return num;
      return Intl.NumberFormat('en-US', {
        notation: 'compact',
      }).format(curr);
    }
  },
});
