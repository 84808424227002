









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as moment from 'moment';
import * as _ from 'lodash';
import Vue from 'vue';
import XLSX from 'xlsx';
import CampaginViewDialog from '../components/campaign-generator/CampaignViewDialog.vue';
import SearchResultDialog from '../components/campaign-generator/SearchResultDialog.vue';
import ProductPageDialog from '../components/campaign-generator/ProductPageDialog.vue';
import TodayTabDialog from '../components/campaign-generator/TodayTabDailog.vue';
import SearchTabDialog from '../components/campaign-generator/SearchTabDailog.vue';
import DataTable from '../components/DataTable.vue';
import TotalSummary from '../components/ads-manager/TotalSummary.vue';
import TotalSummaryAds from '../components/ads-manager/TotalSummaryAds.vue';
import TotalSummaryKeywords from '../components/ads-manager/TotalSummaryKeywords.vue';
import TotalSummaryCampaigns from '../components/ads-manager/TotalSummaryCampaigns.vue';
import TotalSummaryAdGroups from '../components/ads-manager/TotalSummaryAdGroups.vue';
import Chart from '../components/ads-manager/Chart.vue';
import EditCampaign from '../components/campaign/EditCampaign.vue';
import AddKeywords from '../components/keywords/AddKeywords.vue';
import AddAdGroup from '../components/adgroup/AddAdGroup.vue';
import SelectAdGroup from '../components/keywords/SelectAdGroup.vue';
import EditAdGroup from '../components/adgroup/EditAdGroup.vue';
import AddCreativeSet from '../components/campaign/AddCreativeSet.vue';
import AddNegativeKeywords from '../components/negativekeywords/AddNegativeKeywords.vue';
import EditBidAmount from '../components/keywords/EditBidAmount.vue';
import EditCreativeSet from 'src/components/creativeset/EditCreativeSet.vue';
import DeleteCampaign from '../components/campaign/DeleteCampaign.vue';
import DeleteAdgroup from '../components/adgroup/DeleteAdgroup.vue';
import DeleteKeyword from '../components/keywords/DeleteKeyword.vue';
import DeleteNegKey from '../components/negativekeywords/DeleteNegKey.vue';
import EditAd from '../components/campaign/EditAd.vue';
import DeleteAd from '../components/campaign/DeleteAd.vue';
import DecisionAlert from '../components/DecisionAlert.vue';
import { Kochava } from 'kochava';
import { logActivity } from 'src/utils/sam-activity-log';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AdsManagerTabs, AdsManagerFilters, AdGroupSelectorContext, BusEvents } from '../utils/enums';
import { DEFAULT_GRANULARITY, LS_DRAFT_KEY } from '@shared/config';
import {
  GET_GOALS,
  APPS_GRAPH,
  APPS_REPORTS,
  CAMPAIGN_REPORTS,
  CAMPAIGN_GRAPH,
  SEARCH_TERMS_REPORTS,
  CREATIVE_SET_REPORTS,
  TARGETING_KEYWORD_REPORTS,
  NEGATIVE_KEYWORD_REPORTS,
  ACCOUNTS_REPORTS,
  AD_GROUP_REPORTS,
  KEYWORDS_GRAPH,
  AD_GROUP_GRAPH,
  CREATIVE_SET_GRAPH,
  SEARCH_TERMS_GRAPH,
  ACCOUNTS_GRAPH,
  GET_CAMPAIGN_DRAFTS,
  ACCOUNTS_REPORTS_FOR_FILTER,
  APPS_REPORTS_FOR_FILTER,
  CAMPAIGN_REPORTS_FOR_FILTER,
  AD_GROUP_REPORTS_FOR_FILTER,
  APP_DATA_WITH_SPLIT_DATE,
  PRODUCT_PAGES_GRAPH,
  PRODUCT_PAGE_REPORTS,
  PRODUCT_PAGE_REPORTS_FOR_FILTER,
  FETCH_CUSTOM_PRESETS,
  SEARCH_TERM_REPORTS_FOR_FILTER,
  PROCESS_KBBU,
  GET_ALL_STRATEGIC_VIEW,
  GET_ALL_TIMELINE_VIEW,
} from '../utils/apollo-queries';
import {
  ADS_MANAGER_CAMPAIGN_LIVE,
  ADS_MANAGER_CAMPAIGN_PAUSED,
  ADS_MANAGER_CAMPAIGN_DELETED,
  ADS_MANAGER_CAMPAIGN_ALL,
  CAMPAIGN_TOTAL_SUMMARY,
  ADS_MANAGER_CAMPAIGN_DRAFTED,
  ADS_MANAGER_CAMPAIGN_FILTER_LIVE,
  ADS_MANAGER_CAMPAIGN_FILTER_PAUSED,
  ADS_MANAGER_CAMPAIGN_FILTER_DELETED,
  ADS_MANAGER_CAMPAIGN_FILTER_ALL,
  ADS_MANAGER_CAMPAIGN_FILTER_DRAFTED,
} from '../utils/ads-manager/campaigns-apollo-queries';
import {
  ADD_CREATIVE_SET,
  TOGGLE_ADGROUP,
  TOGGLE_CREATIVE_SET,
  TOGGLE_CAMPAIGNS,
  TARGET_KEYWORD_STATUS,
  SAVE_CHART_PRESETS,
  SAVE_DATATABLE_PRESETS,
  SAVE_CUSTOM_VIEW,
  PINNED_CUSTOM_VIEW,
  INSERT_CUSTOM_VIEW,
  DELETE_CUSTOM_VIEW,
} from '../utils/apollo-mutations';
import {
  ADS_MANAGER_KEYWORD_LIVE,
  ADS_MANAGER_KEYWORD_FILTER_LIVE,
  ADS_MANAGER_KEYWORD_PAUSED,
  ADS_MANAGER_KEYWORD_FILTER_PAUSED,
  ADS_MANAGER_KEYWORD_DELETED,
  ADS_MANAGER_KEYWORD_ALL,
  ADS_MANAGER_KEYWORD_FILTER_DELETED,
  KEYWORDS_TOTAL_SUMMARY,
} from '../utils/ads-manager/keywords-apollo-queries';
import {
  ADS_MANAGER_NEG_KEYWORD_LIVE,
  ADS_MANAGER_NEG_KEYWORD_PAUSED,
  ADS_MANAGER_NEG_KEYWORD_DELETED,
  ADS_MANAGER_NEG_KEYWORD_ALL,
} from '../utils/ads-manager/neg-keywords-apollo-queries';
import {
  ADS_MANAGER_AD_GROUP_LIVE,
  ADS_MANAGER_AD_GROUP_PAUSED,
  ADS_MANAGER_AD_GROUP_DELETED,
  ADS_MANAGER_AD_GROUP_ALL,
  ADGROUPS_TOTAL_SUMMARY,
  ADS_MANAGER_AD_GROUP_FILTER_LIVE,
  ADS_MANAGER_AD_GROUP_FILTER_PAUSED,
  ADS_MANAGER_AD_GROUP_FILTER_DELETED,
} from '../utils/ads-manager/ad-groups-apollo-queries';
import { updateCLF, getOwnApps, numberFormat } from '../utils/util';
import { numberDecimal, isReadOnly } from '../utils/util';
import { bus } from '../main';
import { random, update } from 'lodash';
import { LSS } from '@core/services';
import { SubscriptionPermissions } from '@shared/enums';
import PermissionPopup from './../components/PermissionPopup.vue';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
import { decodeToken } from '@shared/utils/commom.util';

import {
  ADS_MANAGER_AD_LIVE,
  ADS_MANAGER_AD_PAUSED,
  ADS_MANAGER_AD_DELETED,
  ADS_MANAGER_AD_ALL,
  ADS_MANAGER_AD_FILTER_LIVE,
  ADS_MANAGER_AD_FILTER_DELETED,
  ADS_MANAGER_AD_FILTER_PAUSED,
} from '../utils/ads-manager/ads-apollo-queries';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    SearchTabDialog,
    TodayTabDialog,
    CampaginViewDialog,
    SearchResultDialog,
    ProductPageDialog,
    TotalSummary,
    TotalSummaryAds,
    TotalSummaryKeywords,
    TotalSummaryCampaigns,
    TotalSummaryAdGroups,
    Chart,
    DataTable,
    EditCampaign,
    EditAd,
    AddKeywords,
    SelectAdGroup,
    AddCreativeSet,
    AddAdGroup,
    EditAdGroup,
    AddNegativeKeywords,
    EditBidAmount,
    EditCreativeSet,
    DeleteCampaign,
    DeleteAd,
    DeleteAdgroup,
    DeleteKeyword,
    DeleteNegKey,
    PermissionPopup,
    PermissionReadOnlyPopup,
    DecisionAlert,
  },
  props: {
    kochava: Kochava,
  },
  data() {
    const today = moment().utc().format('YYYY-MM-DD');
    const dayAWeekAgo = moment().utc().subtract(7, 'days').format('YYYY-MM-DD');
    return {
      selectedSecondaryGoal: 0,
      timeLineEntries: [],
      strategyId: 0,
      isSelectedStrategies: false,
      isStrategyEntityEmpty: false,
      filterStrategy: [],
      redirectStrategyId: null,
      isIconDisabled: false,
      isTooltipVisible: false,
      selectedStatestrategy: 0,
      strategyitems: [],
      TeamID: '',
      logMessage: '',
      enabledGoals: false,
      logType: 'activity',
      logFeaturedName: 'Ads Manager',
      //account
      accountsEndTime: '',
      accountsChartEndTime: '',
      logAccountFeaturedPath: '',
      startAccountTime: '',
      islogAccountProcess: 0,
      //app
      appsEndTime: '',
      appsChartEndTime: '',
      logAppsFeaturedPath: 'My Account',
      startAppTime: '',
      islogAppProcess: 0,
      //search term
      searchTermEndTime: '',
      searchTermChartEndTime: '',
      logSearchTermFeaturedPath: 'My Ad Group',
      startSearchTermTime: '',
      islogSearchTermProcess: 0,
      //negKeyword
      negKeywordLiveEndTime: '',
      negKeywordPausedEndTime: '',
      negKeywordDeletedEndTime: '',
      negKeywordAllEndTime: '',
      negKeywordSummaryEndTime: '',
      negKeywordChartEndTime: '',
      logNegKeywordFeaturedPath: 'My Ad Group',
      startNegKeywordTime: '',
      islogNegKeywordProcess: 0,
      //campaign
      campaignSummaryEndTime: '',
      campaignAllEndTime: '',
      campaignPausedEndTime: '',
      campaignDeletedTime: '',
      campaignLiveEndTime: '',
      campaignChartEndTime: '',
      logCampaignFeaturedPath: 'My App',
      startCampaignTime: '',
      islogCampaignProcess: 0,
      //adgroups
      adgroupSummaryEndTime: '',
      adgroupAllEndTime: '',
      adgroupPausedEndTime: '',
      adgroupDeletedTime: '',
      adgroupLiveEndTime: '',
      adgroupChartEndTime: '',
      logAdgroupFeaturedPath: 'My Campaign',
      startAdgroupTime: '',
      islogAdgroupProcess: 0,
      // Ads
      adsSummaryEndTime: '',
      adsAllEndTime: '',
      adsPausedEndTime: '',
      adsDeletedTime: '',
      adsLiveEndTime: '',
      adsChartEndTime: '',
      logAdsFeaturedPath: 'My Ad Group',
      startAdTime: '',
      islogAdProcess: 0,
      //Keyword
      keywordLiveEndTime: '',
      keywordPausedEndTime: '',
      keywordDeletedEndTime: '',
      keywordAllEndTime: '',
      keywordSummaryEndTime: '',
      keywordChartEndTime: '',
      logKeywordFeaturedPath: 'My Ad Group',
      startKeywordTime: '',
      islogKeywordProcess: 0,
      //
      keywordBulkBidUpdateModal: false,
      KBBUFile: null,
      KBBUPreview: [],
      KBBUUpdating: false,
      KBBUFileContents: [],
      errorViewMessage: '',
      errorEditViewMessage: '',
      isEditInputValid: '',
      isInputValid: false,
      maxDate: new Date().toISOString().split('T')[0],
      showGoalinCampaignAll: false,
      showGoalinCampaignLive: false,
      showGoalinCampaignPaused: false,
      showGoalinCampaignDeleted: false,
      showGoalinAdgroupAll: false,
      showGoalinAdgroupLive: false,
      showGoalinAdgroupPaused: false,
      showGoalinAdgroupDeleted: false,
      showGoalinKeywordAll: false,
      showGoalinKeywordLive: false,
      showGoalinKeywordPaused: false,
      showGoalinKeywordDeleted: false,
      showGoalinAdAll: false,
      showGoalinAdLive: false,
      showGoalinAdPaused: false,
      showGoalinAdDeleted: false,
      showGoalinSearchTerm: false,
      showGoalinCampaignChart: false,
      showGoalinAdgroupChart: false,
      showGoalinKeywordChart: false,
      showGoalinAdChart: false,
      showGoalSearchtermChart: false,

      disableGoalFilter: false,

      goalAttr: [
        'calcGoalsRevenue',
        'calcEventCount',
        'calcCostPerGoal',
        'calcGoalRoas',
        'calcRevenuePerGoal',
        'calcARPU',
        'calcAMPU',
        'calcEventCountSec',
        'calcCostPerGoalSec',
      ],
      chartGoalAttr: [
        'Goal Revenue',
        'Goal Event Count',
        'Cost Per Goal',
        'Goal ROAS',
        'Revenue Per Goal',
        'Goal ARPU',
        'Goal AMPU',
        'Secondary Goal Event Count',
        'Secondary Cost Per Goal',
      ],
      searchResultMetadata: {},
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertData: {},
      SubscriptionPermissionsEnums: SubscriptionPermissions,
      permissionMetadata: {},
      permissionReadOnlyMetadata: {},
      syncStatus: false,
      editMode: false,
      showTextField: false,
      customViews: [{ CustomView: 'Default View', _id: '0' }],
      supplySourcesFilters: [
        { name: 'Search Results', supplySources: 'APPSTORE_SEARCH_RESULTS' },
        { name: 'Search Tab', supplySources: 'APPSTORE_SEARCH_TAB' },
        { name: 'Todays Tab', supplySources: 'APPSTORE_TODAY_TAB' },
        { name: 'Product Pages', supplySources: 'APPSTORE_PRODUCT_PAGES_BROWSE' },
      ],
      datasets: {},
      datatable: {},
      showViewForm: false,
      customView: { CustomView: 'Default View', _id: '0' },
      newCustomView: '',
      locale: 'en',
      attrs: null,
      on: null,
      syncing: false,
      // tabs variables
      accounts_tab: true,
      apps_tab: false,
      campaigns_tab: false,
      ad_groups_tab: false,
      keywords_tab: false,
      creative_sets_tab: false,
      search_terms_tab: false,
      neg_keywords_tab: false,
      product_page_tab: false,
      appFilterData: null,
      adsManagerFilters: AdsManagerFilters,

      currentTab: AdsManagerTabs.ACCOUNTS,
      select: '',
      drawer: false,
      selectedAccount: 0,
      selectedApp: 0,
      selectedGoal: 0,
      selectedCampaign: 0,
      selectedCampaignSupplySource: 0,
      selectedCampaignType: null,
      selectedAdGroup: 0,
      selectedTargetingKeyword: 0,
      selectedAd: 0,
      filter: {
        dateRange: [
          LSS.dateRange && LSS.dateRange[0]
            ? moment(LSS.dateRange[0]).format('YYYY-MM-DD')
            : moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),
          LSS.dateRange && LSS.dateRange[1] ? moment(LSS.dateRange[1]).format('YYYY-MM-DD') : today,
        ],
      },
      graphFilter: {
        granularity: DEFAULT_GRANULARITY,
        dateRange: [
          LSS.dateRange && LSS.dateRange[0]
            ? moment(LSS.dateRange[0]).format('YYYY-MM-DD')
            : moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),
          LSS.dateRange && LSS.dateRange[1] ? moment(LSS.dateRange[1]).format('YYYY-MM-DD') : today,
        ],
      },
      chartGranularity: DEFAULT_GRANULARITY,
      timeProps: {
        outlined: true,
        dense: true,
      },
      mini: true,
      e1: 1,
      checkbox: true,
      switch1: true,

      row: null,
      radioGroup: 1,

      //Search Filter
      keyFilter: '',

      filteredAccountReports: null,
      accountsReport: null,
      filteredAccountsReportsCount: 0,

      filteredAppReports: null,
      appsReport: null,
      filteredAppReportsCount: 0,

      filteredCampaignReports: null,
      campaignReport: null,
      campaignTotalSummaryData: {
        taps: 0,
        impressions: 0,
        ttr: 0,
        tapNewDownloads: 0,
        viewNewDownloads: 0,
        totalNewDownloads: 0,
        tapRedownloads: 0,
        viewRedownloads: 0,
        totalRedownloads: 0,
        tapInstalls: 0,
        viewInstalls: 0,
        totalInstalls: 0,
        localSpend: {
          amount: 0,
        },
        goalEventCount: 0,
        goalRevenue: 0,
      },

      filteredAdgroupReports: null,
      adgroupReport: null,
      adgroupsTotalSummaryData: {
        taps: 0,
        impressions: 0,
        ttr: 0,
        tapNewDownloads: 0,
        viewNewDownloads: 0,
        totalNewDownloads: 0,
        tapRedownloads: 0,
        viewRedownloads: 0,
        totalRedownloads: 0,
        tapInstalls: 0,
        viewInstalls: 0,
        totalInstalls: 0,
        localSpend: {
          amount: 0,
        },
        goalEventCount: 0,
        goalRevenue: 0,
      },

      filteredKeywordReports: null,
      keywordReport: null,
      keywordsTotalSummaryData: {
        taps: 0,
        impressions: 0,
        ttr: 0,
        tapNewDownloads: 0,
        viewNewDownloads: 0,
        totalNewDownloads: 0,
        tapRedownloads: 0,
        viewRedownloads: 0,
        totalRedownloads: 0,
        tapInstalls: 0,
        viewInstalls: 0,
        totalInstalls: 0,
        localSpend: {
          amount: 0,
        },
        goalEventCount: 0,
        goalRevenue: 0,
      },
      filteredCreativeSetReports: null,
      creativeSetReport: null,

      filteredSearchTermsReports: null,
      searchTermsReport: null,
      productSearchTermCount: 0,

      filteredNegKeywordReports: null,
      negKeywordReport: null,

      productPageReport: null,
      productPageReportCount: 0,
      adsTotalSummary: {
        taps: 0,
        impressions: 0,
        ttr: 0,
        tapNewDownloads: 0,
        viewNewDownloads: 0,
        totalNewDownloads: 0,
        tapRedownloads: 0,
        viewRedownloads: 0,
        totalRedownloads: 0,
        tapInstalls: 0,
        viewInstalls: 0,
        totalInstalls: 0,
        localSpend: {
          amount: 0,
        },
        goalEventCount: 0,
        goalRevenue: 0,
      },
      adDeletedReportCount: 0,
      adDeletedReport: null,

      adPauseReportCount: 0,
      adPauseReport: null,

      adAllReportCount: 0,
      adAllReport: null,

      //Reporting Sub Tabs Data
      liveCampaignReports: null,
      disabledCampaignReports: null,
      deletedCampaignReports: null,
      allCampaignReports: null,

      draftedCampaignReports: null,
      disabledAdgroupReports: null,
      deletedAdgroupReports: null,
      allAdgroupReports: null,

      disabledKeywordReports: null,
      deletedKeywordReports: null,
      allKeywordReports: null,

      disabledCreativeSetReports: null,
      deletedCreativeSetReports: null,

      disabledNegKeywordReports: null,
      deletedNegKeywordReports: null,
      allNegKeywordReports: null,

      liveReportsCount: 0,
      disabledReportsCount: 0,
      deletedReportsCount: 0,
      draftReportsCount: 0,
      allReportsCount: 0,

      filteredAdgroupReportsCount: 0,
      disabledAdgroupReportsCount: 0,
      deletedAdgroupReportsCount: 0,
      allAdgroupReportsCount: 0,

      filteredKeywordReportsCount: 0,
      disabledKeywordReportsCount: 0,
      deletedKeywordReportsCount: 0,
      allKeywordReportsCount: 0,

      filteredCreativeSetReportsCount: 0,
      disabledCreativeSetReportsCount: 0,
      deletedCreativeSetReportsCount: 0,

      filteredNegKeywordReportsCount: 0,
      disabledNegKeywordReportsCount: 0,
      deletedNegKeywordReportsCount: 0,
      allNegKeywordReportsCount: 0,

      tab: null,
      campaignListingTab: null,
      adgroupListingTab: null,
      keywordListingTab: null,
      creativeListingTab: null,
      negKeyListingTab: null,
      switchLoading: false,
      adListingTab: null,

      /**
       * search input tab specific variables
       */
      searchCampDeletedInputs: {
        taps: '',
      },

      loading: true,
      filterDateRangeModel: [
        LSS.dateRange && LSS.dateRange[0]
          ? moment(LSS.dateRange[0]).format('YYYY-MM-DD')
          : moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),
        LSS.dateRange && LSS.dateRange[1] ? moment(LSS.dateRange[1]).format('YYYY-MM-DD') : today,
      ],
      filterDateRange: [
        LSS.dateRange && LSS.dateRange[0]
          ? moment(LSS.dateRange[0]).format('YYYY-MM-DD')
          : moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),
        LSS.dateRange && LSS.dateRange[1] ? moment(LSS.dateRange[1]).format('YYYY-MM-DD') : today,
      ],
      rangePicker: false,
      mobileRangePickerDialog: false,
      modal: false,
      menu2: false,

      // for editing campaign
      campaignMetadata: null,
      // for campaign deletion
      campaignDeletionMetadata: {},

      adgroupDeletionMetadata: {},

      keywordDeletionMetadata: {},
      // for adding keywords
      adgroupMetadata: null,
      // editing adgroup
      editAdgroupMetadata: null,
      // adgroup selection for keywords
      selectAdgroupForKeywords: null,
      // adgroup selection for negative keyword
      openAddNegativeKeywords: null,
      // adgroup selection for creative set
      selectAdgroupForCs: null,
      // for adding creative set
      creativeSetMetadata: null,
      // for editing bid amount
      keywordBidData: null,
      //for adding adgroup
      openAddAdgroup: null,
      //for editing creativeSet
      updateCreativeSetData: null,
      //for Editing adgroup
      openEditAdgroup: null,
      //for Deleting Neg Key
      negKeyDeletionData: null,
      // for editing ad
      adsMetadata: null,
      // for ad deletion
      adsDeletionMetadata: {},
      campaignChartData: {
        labels: [],
        datasets: [],
      },
      appsChartData: {
        labels: [],
        datasets: [],
      },
      keywordsChartData: {
        labels: [],
        datasets: [],
      },
      adGroupChartData: {
        labels: [],
        datasets: [],
      },
      creativeSetChartData: {
        labels: [],
        datasets: [],
      },
      searchTermsChartData: {
        labels: [],
        datasets: [],
      },
      productPagesChartData: {
        labels: [],
        datasets: [],
      },

      accountsChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
      chartDataSets: [],

      panel: [],

      selectedItem: 1,
      listitems: [
        { text: 'Test', icon: 'mdi-circle-small' },
        { text: 'MyCamp2', icon: 'mdi-circle-small' },
        { text: 'MyCamp3', icon: 'mdi-circle-small' },
        { text: 'MyCamp4', icon: 'mdi-circle-small' },
      ],

      search: '',
      headers: [
        {
          text: 'addKeyword',
          value: 'addKeyword',
        },
        { text: 'Keyword', value: 'keyword' },
        { text: 'Popularity', value: 'popularity' },
        { text: 'Ranking', value: 'ranking' },
      ],
      reportLoading: false,

      addAttrFieldInReport: false,

      keyOffset: 0,
      keyLimit: 10,
      fetchLimit: 10,
      AccountskeyOffset: 0,
      AccountfetchLimit: 10,
      AppskeyOffset: 0,
      AppsfetchLimit: 10,
      LivekeAdskeyOffset: 0,
      LivekeAdsfetchLimit: 10,
      DeletedAdskeyOffset: 0,
      DeletedAdsfetchLimit: 10,
      AllAdskeyOffset: 0,
      AllAdsfetchLimit: 10,
      LivekeywordskeyOffset: 0,
      LivekeywordsfetchLimit: 10,
      PausedkeywordskeyOffset: 0,
      PausedAdskeyOffset: 0,
      PausedAdsfetchLimit: 10,
      PausedkeywordsfetchLimit: 10,
      DeletedkeywordskeyOffset: 0,
      DeletedkeywordsfetchLimit: 10,
      AllkeywordskeyOffset: 0,
      AllkeywordsfetchLimit: 10,
      LiveNegtivekeyOffset: 0,
      LiveNegtivefetchLimit: 10,
      PausedNegtivekeyOffset: 0,
      PausedNegtivefetchLimit: 10,
      DeletedNegtivekeyOffset: 0,
      DeletedNegtivefetchLimit: 10,
      AllNegtivekeyOffset: 0,
      AllNegtivefetchLimit: 10,
      LiveCampaignkeyOffset: 0,
      LiveCampaignfetchLimit: 10,
      PausedCampaignkeyOffset: 0,
      PausedCampaignfetchLimit: 10,
      DeletedCampaignkeyOffset: 0,
      DeletedCampaignfetchLimit: 10,
      AllCampaignkeyOffset: 0,
      AllCampaignfetchLimit: 10,
      DraftCampaignkeyOffset: 0,
      DraftCampaignfetchLimit: 10,

      LiveAdGroupkeyOffset: 0,
      LiveAdGroupfetchLimit: 10,
      PausedAdGroupkeyOffset: 0,
      PausedAdGroupfetchLimit: 10,
      DeletedAdGroupkeyOffset: 0,
      DeletedAdGroupfetchLimit: 10,
      AllAdGroupkeyOffset: 0,
      AllAdGroupfetchLimit: 10,
      SearchTermskeyOffset: 0,
      SearchTermsfetchLimit: 10,
      pageCount: 0,
      goalsLength: 0,
      //Accounts sorting
      AccountSortBy: 'none',
      AccountSortDesc: false,
      //Apps Sorting
      appsReportSortBy: 'none',
      appsReportSortDesc: false,
      //searchTerms
      searchTermsReportsSortBy: 'none',
      searchTermsReportsSortDesc: false,

      //NegTiveKeywords sorting
      LiveNegtiveKeySortBy: 'none',
      LiveNegtiveKeySortDesc: false,
      PausedNegtiveKeySortBy: 'none',
      PausedNegtiveKeySortDesc: false,
      DeletedNegtiveKeySortBy: 'none',
      DeletedNegtiveKeySortDesc: false,
      AllNegtiveKeySortBy: 'none',
      AllNegtiveKeySortDesc: false,

      //AdGroup Sorting
      LiveAdGroupSortBy: 'none',
      LiveAdGroupSortDesc: false,
      PausedAdGroupSortBy: 'none',
      PausedAdGroupSortDesc: false,
      DeletedAdGroupSortBy: 'none',
      DeletedAdGroupSortDesc: false,
      AllAdGroupSortBy: 'none',
      AllAdGroupSortDesc: false,

      //Campaign Sorting
      LiveCampaignSortBy: 'none',
      LiveCampaignSortDesc: false,
      PausedCampaignSortBy: 'none',
      PausedCampaignSortDesc: false,
      DeletedCampaignSortBy: 'none',
      DeletedCampaignSortDesc: false,
      DraftCampaignSortBy: 'none',
      DraftCampaignSortDesc: false,
      AllCampaignSortBy: 'none',
      AllCampaignSortDesc: false,
      //Keywords Sorting
      LiveKeywordSortBy: 'none',
      LiveKeywordsSortDesc: false,
      PausedKeywordSortBy: 'none',
      PausedKeywordsSortDesc: false,
      DeletedKeywordSortBy: 'none',
      DeletedKeywordsSortDesc: false,
      AllKeywordSortBy: 'none',
      AllKeywordsSortDesc: false,
      LiveAdsSortBy: 'none',
      LiveAdsSortDesc: false,
      PausedAdsSortBy: 'none',
      PausedAdsSortDesc: false,
      DeletedAdsSortBy: 'none',
      DeletedAdsSortDesc: false,
      AllAdsSortBy: 'none',
      AllAdsSortDesc: false,
      searchAccountInputs: {},

      // For NegTiveKeywords
      searchNegativeInputsLive: {},
      searchNegativeInputsPaused: {},
      searchNegativeInputsDeleted: {},
      searchNegativeInputsAll: {},

      //campaigns
      searchCampaignInputsLive: {},
      searchCampaignInputsPaused: {},
      searchCampaignInputsDeleted: {},
      searchCampaignInputsAll: {},
      searchTermsSearchInputs: {},
      //for AdGroup search
      searchAdgroupInputsLive: {},
      searchAdgroupInputsPaused: {},
      searchAdGroupInputsDeleted: {},
      searchAdGroupInputsAll: {},
      //For search apps
      searchAppsInputs: {},
      //keywords
      searchKeywordsInputsLive: {},
      searchKeywordsInputsPaused: {},
      searchKeywordsInputsDeleted: {},
      searchKeywordsInputsAll: {},

      searchAdsInputsLive: {},
      searchAdsInputsPaused: {},
      searchAdsInputsDeleted: {},
      searchAdsInputsAll: {},
      //camp view dialog
      campaignViewMetadata: {},
      searchTabMetadata: {},
      todayTabMetadata: {},
      productPageMetadata: {},

      campaignAttGraphString: '',
      campaignGraphData: {},
      accountGraphData: {},
      appsGraphData: {},
      adgroupsGraphData: {},
      adsGraphData: {},
      searchTermGraphData: {},
      keyordsGraphData: {},
      negkeywordsGraphData: {},
      KBBUPreviewheaders: [
        {
          text: 'Keyword Name',
          sortable: false,
          value: 'keyword',
        },
        {
          text: 'Keyword Id',
          sortable: false,
          value: 'keywordId',
        },
        {
          text: 'New Bid Amount',
          sortable: false,
          value: 'bidAmount',
        },
      ],
    };
  },
  apollo: {
    getGoals: {
      query: GET_GOALS,
    },
    appsChart: {
      query: APPS_GRAPH,
      skip() {
        return !this.apps_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.enabledGoals = false;
        this.setChartData(data.appsChart, 'appsChartData');
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAppsFeaturedPath, 'chart');
      },
      error(error) {
        this.enabledGoals = false;
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAppsFeaturedPath, 'chart');
      },
    },
    campaignChart: {
      query: CAMPAIGN_GRAPH,
      fetchPolicy() {
        return this.campaignChartData?.datasets?.some((dataset) => dataset.label === 'Goal Event Count') ? 'cache-first' : 'network-only';
      },
      skip() {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
          attrGraphString: this.campaignAttGraphString,
          getGoals: this.showGoalinCampaignChart,
        };
      },
      update(data) {
        if (this.goalsLength === 0) {
          const lowerCaseChartGoalAttr = this.chartGoalAttr.map((attr) => attr.toLowerCase());
          const filteredDatasets = data.campaignChart.datasets.filter((dataset) => {
            const label = dataset.label.toLowerCase();
            return !lowerCaseChartGoalAttr.includes(label);
          });
          data.campaignChart.datasets = filteredDatasets;
        }
        this.setChartData(data.campaignChart, 'campaignChartData');
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'chart');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'chart');
      },
    },

    appsReports: {
      query: APPS_REPORTS,
      skip(): boolean {
        return !this.apps_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.AppskeyOffset,
          limit: this.AppsfetchLimit,
          sortBy: this.appsReportSortBy,
          sortDesc: this.appsReportSortDesc,
          search: this.searchAppsInputs,
        };
      },
      update(data) {
        this.enabledGoals = false;
        this.filteredAppReports = data.appsReports;
        this.appsReport = data.appsReports;
        this.filteredAppReportsCount = data.appsReports.totalRecords;
        if (this.filteredAppReportsCount > 0 && data.appsReports.row.length == 0) {
          this.$refs.appsDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAppsFeaturedPath, 'app');
      },
      error(error) {
        this.enabledGoals = false;
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAppsFeaturedPath, 'app');
      },
    },
    getCampaignDrafts: {
      query: GET_CAMPAIGN_DRAFTS,

      skip(): boolean {
        return !this.campaigns_tab;
      },
    },
    campaignReportsLive: {
      query: ADS_MANAGER_CAMPAIGN_LIVE,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.LiveCampaignkeyOffset,
          limit: this.LiveCampaignfetchLimit,
          sortBy: this.LiveCampaignSortBy,
          sortDesc: this.LiveCampaignSortDesc,
          campSearch: this.searchCampaignInputsLive,
          getGoals: this.showGoalinCampaignLive,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.campaignReport = data.campaignReportsLive;
        this.liveReportsCount = data.campaignReportsLive.campaignCounts.liveReportsCount;
        if (this.liveReportsCount > 0 && data.campaignReportsLive.row.length == 0) {
          this.$refs.liveCampaignDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'live');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'live');
      },
    },
    campaignReportsPaused: {
      query: ADS_MANAGER_CAMPAIGN_PAUSED,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.PausedCampaignkeyOffset,
          limit: this.PausedCampaignfetchLimit,
          sortBy: this.PausedCampaignSortBy,
          sortDesc: this.PausedCampaignSortDesc,
          campSearch: this.searchCampaignInputsPaused,
          getGoals: this.showGoalinCampaignPaused,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.disabledCampaignReports = data.campaignReportsPaused;
        this.disabledReportsCount = data.campaignReportsPaused.campaignCounts.disabledReportsCount;
        if (this.disabledReportsCount > 0 && data.campaignReportsPaused.row.length == 0) {
          this.$refs.pausedCampaignDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'paused');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'paused');
      },
    },
    campaignReportsDeleted: {
      query: ADS_MANAGER_CAMPAIGN_DELETED,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.DeletedCampaignkeyOffset,
          limit: this.DeletedCampaignfetchLimit,
          sortBy: this.DeletedCampaignSortBy,
          sortDesc: this.DeletedCampaignSortDesc,
          campSearch: this.searchCampaignInputsDeleted,
          getGoals: this.showGoalinCampaignDeleted,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.deletedCampaignReports = data.campaignReportsDeleted;
        this.deletedReportsCount = data.campaignReportsDeleted.campaignCounts.deletedReportsCount;
        if (this.deletedReportsCount > 0 && data.campaignReportsDeleted.row.length == 0) {
          this.$refs.deletedCampaignDatatable.goToFirstPage();
        }
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logCampaignFeaturedPath,
          'deleted'
        );
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logCampaignFeaturedPath,
          'deleted'
        );
      },
    },

    campaignReportsAll: {
      query: ADS_MANAGER_CAMPAIGN_ALL,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.AllCampaignkeyOffset,
          limit: this.AllCampaignfetchLimit,
          sortBy: this.AllCampaignSortBy,
          sortDesc: this.AllCampaignSortDesc,
          campSearch: this.searchCampaignInputsAll,
          getGoals: this.showGoalinCampaignAll,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.allCampaignReports = data.campaignReportsAll;
        this.allReportsCount = data.campaignReportsAll.campaignCounts.allReportsCount;
        if (this.allReportsCount > 0 && data.campaignReportsAll.row.length == 0) {
          this.$refs.allCampaignDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'all');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logCampaignFeaturedPath, 'all');
      },
    },

    //ADGROUP//
    adGroupReportsLive: {
      query: ADS_MANAGER_AD_GROUP_LIVE,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.LiveAdGroupkeyOffset,
          limit: this.LiveAdGroupfetchLimit,
          sortBy: this.LiveAdGroupSortBy,
          sortDesc: this.LiveAdGroupSortDesc,
          adGroupSearch: this.searchAdgroupInputsLive,
          getGoals: this.showGoalinAdgroupLive,
        };
      },

      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.adgroupReport = data.adGroupReportsLive;
        this.filteredAdgroupReportsCount = data.adGroupReportsLive.adgroupcounts.liveadgroupcount;
        if (this.filteredAdgroupReportsCount > 0 && data.adGroupReportsLive.row.length == 0) {
          this.$refs.liveAdgroupDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'live');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'live');
      },
    },
    adGroupReportsPaused: {
      query: ADS_MANAGER_AD_GROUP_PAUSED,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.PausedAdGroupkeyOffset,
          limit: this.PausedAdGroupfetchLimit,
          sortBy: this.PausedAdGroupSortBy,
          sortDesc: this.PausedAdGroupSortDesc,
          adGroupSearch: this.searchAdgroupInputsPaused,
          getGoals: this.showGoalinAdgroupPaused,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.disabledAdgroupReports = data.adGroupReportsPaused;
        this.disabledAdgroupReportsCount = data.adGroupReportsPaused.adgroupcounts.pausedadgroupcount;
        if (this.disabledAdgroupReportsCount > 0 && data.adGroupReportsPaused.row.length == 0) {
          this.$refs.pausedAdgroupDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'paused');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'paused');
      },
    },
    adGroupReportsDeleted: {
      query: ADS_MANAGER_AD_GROUP_DELETED,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.DeletedAdGroupkeyOffset,
          limit: this.DeletedAdGroupfetchLimit,
          sortBy: this.DeletedAdGroupSortBy,
          sortDesc: this.DeletedAdGroupSortDesc,
          adGroupSearch: this.searchAdGroupInputsDeleted,
          getGoals: this.showGoalinAdgroupDeleted,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.deletedAdgroupReports = data.adGroupReportsDeleted;
        this.deletedAdgroupReportsCount = data.adGroupReportsDeleted.adgroupcounts.deletedadgroupcount;
        if (this.deletedAdgroupReportsCount > 0 && data.adGroupReportsDeleted.row.length == 0) {
          this.$refs.deletedAdgroupDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'deleted');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'deleted');
      },
    },
    adGroupReportsAll: {
      query: ADS_MANAGER_AD_GROUP_ALL,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.AllAdGroupkeyOffset,
          limit: this.AllAdGroupfetchLimit,
          sortBy: this.AllAdGroupSortBy,
          sortDesc: this.AllAdGroupSortDesc,
          adGroupSearch: this.searchAdGroupInputsAll,
          getGoals: this.showGoalinAdgroupAll,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.allAdgroupReports = data.adGroupReportsAll;
        this.allAdgroupReportsCount = data.adGroupReportsAll.adgroupcounts.alladgroupcount;
        if (this.allAdgroupReportsCount > 0 && data.adGroupReportsAll.row.length == 0) {
          this.$refs.allAdgroupDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'all');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'all');
      },
    },
    searchTermsGraph: {
      query: SEARCH_TERMS_GRAPH,
      skip(): boolean {
        return !this.search_terms_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
          attrGraphString: this.campaignAttGraphString,
          getGoals: this.showGoalSearchtermChart,
        };
      },
      update(data) {
        if (this.goalsLength === 0) {
          const lowerCaseChartGoalAttr = this.chartGoalAttr.map((attr) => attr.toLowerCase());
          const filteredDatasets = data.searchTermsChart.datasets.filter((dataset) => {
            const label = dataset.label.toLowerCase();
            return !lowerCaseChartGoalAttr.includes(label);
          });
          data.searchTermsChart.datasets = filteredDatasets;
        }
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.setChartData(data.searchTermsChart, 'searchTermsChartData');
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logSearchTermFeaturedPath,
          'chart'
        );
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logSearchTermFeaturedPath,
          'searchTerm'
        );
      },
    },

    searchTermsReports: {
      query: SEARCH_TERMS_REPORTS,
      skip(): boolean {
        return !this.search_terms_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.SearchTermskeyOffset,
          limit: this.SearchTermsfetchLimit,
          sortBy: this.searchTermsReportsSortBy,
          sortDesc: this.searchTermsReportsSortDesc,
          searchTermSearch: this.searchTermsSearchInputs,
          getGoals: this.showGoalinSearchTerm,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.searchTermsReport = data.searchTermsReports;
        this.filteredSearchTermsReports = data.searchTermsReports;
        this.productSearchTermCount = data.searchTermsReports.totalRecords;
        if (this.productSearchTermCount > 0 && data.searchTermsReports.row.length == 0) {
          this.$refs.searchtermDatatable.goToFirstPage();
        }
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logSearchTermFeaturedPath,
          'searchTerm'
        );
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logSearchTermFeaturedPath,
          'searchTerm'
        );
      },
    },

    productPagesGraph: {
      query: PRODUCT_PAGES_GRAPH,
      skip(): boolean {
        return !this.product_page_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
          attrGraphString: this.campaignAttGraphString,
          getGoals: this.showGoalinAdChart,
        };
      },
      update(data) {
        if (this.goalsLength === 0) {
          const lowerCaseChartGoalAttr = this.chartGoalAttr.map((attr) => attr.toLowerCase());
          const filteredDatasets = data.productPagesChart.datasets.filter((dataset) => {
            const label = dataset.label.toLowerCase();
            return !lowerCaseChartGoalAttr.includes(label);
          });
          data.productPagesChart.datasets = filteredDatasets;
        }
        this.setChartData(data.productPagesChart, 'productPagesChartData');
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logkeywordFeaturedPath, 'chart');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logkeywordFeaturedPath, 'chart');
      },
    },
    productPageReportsLive: {
      query: ADS_MANAGER_AD_LIVE,
      skip(): boolean {
        return !this.product_page_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.LivekeAdskeyOffset,
          limit: this.LivekeAdsfetchLimit,
          sortBy: this.LiveAdsSortBy,
          sortDesc: this.LiveAdsSortDesc,
          adsSearch: this.searchAdsInputsLive,
          getGoals: this.showGoalinAdLive,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.productPageReport = data.productPageReportsLive;
        this.productPageReport.row.forEach((element) => {
          if (element.adTypeRow === 'DEFAULT') {
            element.adsMetadata.creativeType = 'DEFAULT_PRODUCT_PAGE';
            element.adsMetadata.status = 'N/A';
            if (!element.adsMetadata.name.includes(' (default)')) {
              element.adsMetadata.name = element.adsMetadata.name + ' (default)';
            }
            element.adgroupMetadata.id = 'N/A';
            element.adgroupMetadata.name = 'N/A';
            element.adgroupMetadata.status = 'N/A';
          }
        });
        this.productPageReportCount = data.productPageReportsLive.totalRecords;
        if (this.productPageReportCount > 0 && data.productPageReportsLive.row.length == 0) {
          this.$refs.adsDatatable.goToFirstPage();
        }
        this.calculateAdsTotalSummary();
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'live');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'live');
      },
    },
    // product Page Reports Paused
    productPageReportsPaused: {
      query: ADS_MANAGER_AD_PAUSED,
      skip(): boolean {
        return !this.product_page_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.PausedAdskeyOffset,
          limit: this.PausedAdsfetchLimit,
          sortBy: this.PausedAdsSortBy,
          sortDesc: this.PausedAdsSortDesc,
          adsSearch: this.searchAdsInputsPaused,
          getGoals: this.showGoalinAdPaused,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.adPauseReport = data.productPageReportsPaused;
        this.adPauseReport.row.forEach((element) => {
          if (element.adTypeRow === 'DEFAULT') {
            element.adsMetadata.creativeType = 'DEFAULT_PRODUCT_PAGE';
            element.adsMetadata.status = 'N/A';
            if (!element.adsMetadata.name.includes(' (default)')) {
              element.adsMetadata.name = element.adsMetadata.name + ' (default)';
            }
            element.adgroupMetadata.id = 'N/A';
            element.adgroupMetadata.name = 'N/A';
            element.adgroupMetadata.status = 'N/A';
          }
        });
        this.adPauseReportCount = data.productPageReportsPaused.totalRecords;
        if (this.adPauseReportCount > 0 && data.productPageReportsPaused.row.length == 0) {
          this.$refs.pausedAdsDatatable.goToFirstPage();
        }
        this.calculateAdsTotalSummary();
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'paused');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'paused');
      },
    },
    // product Page Reports  Deleted

    productPageReportsDeleted: {
      query: ADS_MANAGER_AD_DELETED,
      skip(): boolean {
        return !this.product_page_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.DeletedAdskeyOffset,
          limit: this.DeletedAdsfetchLimit,
          sortBy: this.DeletedAdsSortBy,
          sortDesc: this.DeletedAdsSortDesc,
          adsSearch: this.searchAdsInputsDeleted,
          getGoals: this.showGoalinAdDeleted,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.adDeletedReport = data.productPageReportsDeleted;
        this.adDeletedReport.row.forEach((element) => {
          if (element.adTypeRow === 'DEFAULT') {
            element.adsMetadata.creativeType = 'DEFAULT_PRODUCT_PAGE';
            element.adsMetadata.status = 'N/A';
            if (!element.adsMetadata.name.includes(' (default)')) {
              element.adsMetadata.name = element.adsMetadata.name + ' (default)';
            }
            element.adgroupMetadata.id = 'N/A';
            element.adgroupMetadata.name = 'N/A';
            element.adgroupMetadata.status = 'N/A';
          }
        });
        this.adDeletedReportCount = data.productPageReportsDeleted.totalRecords;
        if (this.adDeletedReportCount > 0 && data.productPageReportsDeleted.row.length == 0) {
          this.$refs.deletedAdsDatatable.goToFirstPage();
        }
        this.calculateAdsTotalSummary();
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'deleted');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'deleted');
      },
    },
    productPageReportsAll: {
      query: ADS_MANAGER_AD_ALL,
      skip(): boolean {
        return !this.product_page_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.AllAdskeyOffset,
          limit: this.AllAdsfetchLimit,
          sortBy: this.AllAdsSortBy,
          sortDesc: this.AllAdsSortDesc,
          adsSearch: this.searchAdsInputsAll,
          getGoals: this.showGoalinAdAll,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.adAllReport = data.productPageReportsAll;
        this.adAllReport.row.forEach((element) => {
          if (element.adTypeRow === 'DEFAULT') {
            element.adsMetadata.creativeType = 'DEFAULT_PRODUCT_PAGE';
            element.adsMetadata.status = 'N/A';
            if (!element.adsMetadata.name.includes(' (default)')) {
              element.adsMetadata.name = element.adsMetadata.name + ' (default)';
            }
            element.adgroupMetadata.id = 'N/A';
            element.adgroupMetadata.name = 'N/A';
            element.adgroupMetadata.status = 'N/A';
          }
        });
        this.adAllReportCount = data.productPageReportsAll.totalRecords;
        if (this.adAllReportCount > 0 && data.productPageReportsAll.row.length == 0) {
          this.$refs.allAdsDatatable.goToFirstPage();
        }
        this.calculateAdsTotalSummary();
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'all');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdsFeaturedPath, 'all');
      },
    },

    creativeSetGraph: {
      query: CREATIVE_SET_GRAPH,
      skip(): boolean {
        return !this.creative_sets_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.creativeSetChart, 'creativeSetChartData');
      },
    },

    creativeSetReports: {
      query: CREATIVE_SET_REPORTS,
      skip(): boolean {
        return !this.creative_sets_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.creativeSetReport = data.creativeSetReports;
        this.deletedCreativeSetReport('');
        this.disabledCreativeSetReport('');
        this.creativesetSearchFilter('');
      },
    },

    keywordsGraph: {
      query: KEYWORDS_GRAPH,
      skip(): boolean {
        return !this.keywords_tab;
      },
      fetchPolicy() {
        return this.keywordsChartData?.datasets?.some((dataset) => dataset.label === 'Goal Event Count') ? 'cache-first' : 'network-only';
      },
      variables() {
        return {
          filter: this.graphFilter,
          attrGraphString: this.campaignAttGraphString,
          getGoals: this.showGoalinKeywordChart,
        };
      },
      update(data) {
        if (this.goalsLength === 0) {
          const lowerCaseChartGoalAttr = this.chartGoalAttr.map((attr) => attr.toLowerCase());
          const filteredDatasets = data.keywordsChart.datasets.filter((dataset) => {
            const label = dataset.label.toLowerCase();
            return !lowerCaseChartGoalAttr.includes(label);
          });
          data.keywordsChart.datasets = filteredDatasets;
        }
        this.setChartData(data.keywordsChart, 'keywordsChartData');
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'chart');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'chart');
      },
    },

    keywordsTotalSummary: {
      query: KEYWORDS_TOTAL_SUMMARY,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.keywordsTotalSummaryData = data.keyTotalSummary;
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logkeywordFeaturedPath, 'summary');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logkeywordFeaturedPath, 'summary');
      },
    },
    campaignTotalSummary: {
      query: CAMPAIGN_TOTAL_SUMMARY,
      skip(): boolean {
        return !this.campaigns_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.campaignTotalSummaryData = data.campaignTotalSummary;
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logCampaignFeaturedPath,
          'summary'
        );
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logCampaignFeaturedPath,
          'summary'
        );
      },
    },
    adgroupsTotalSummary: {
      query: ADGROUPS_TOTAL_SUMMARY,
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.filter,
        };
      },
      update(data) {
        this.adgroupsTotalSummaryData = data.adgroupsTotalSummary;
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'summary');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAdgroupFeaturedPath, 'summary');
      },
    },
    targetingKeywordReportsLive: {
      query: ADS_MANAGER_KEYWORD_LIVE,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.LivekeywordskeyOffset,
          limit: this.LivekeywordsfetchLimit,
          sortBy: this.LiveKeywordSortBy,
          sortDesc: this.LiveKeywordsSortDesc,
          keywordSearch: this.searchKeywordsInputsLive,
          getGoals: this.showGoalinKeywordLive,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.keywordReport = data.targetingKeywordReportsLive;
        this.filteredKeywordReportsCount = data.targetingKeywordReportsLive.counts?.livekeywords;
        if (this.filteredKeywordReportsCount > 0 && data.targetingKeywordReportsLive.row.length == 0) {
          this.$refs.livekeywordDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'live');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'live');
      },
    },

    targetingKeywordReportsPaused: {
      query: ADS_MANAGER_KEYWORD_PAUSED,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.PausedkeywordskeyOffset,
          limit: this.PausedkeywordsfetchLimit,
          sortBy: this.PausedKeywordSortBy,
          sortDesc: this.PausedKeywordsSortDesc,
          keywordSearch: this.searchKeywordsInputsPaused,
          getGoals: this.showGoalinKeywordPaused,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.disabledKeywordReports = data.targetingKeywordReportsPaused;
        this.disabledKeywordReportsCount = data.targetingKeywordReportsPaused.counts?.pausedkeywords;
        if (this.disabledKeywordReportsCount > 0 && data.targetingKeywordReportsPaused.row.length == 0) {
          this.$refs.pausedKeywordDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'paused');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'paused');
      },
    },
    targetingKeywordReportsDeleted: {
      query: ADS_MANAGER_KEYWORD_DELETED,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.DeletedkeywordskeyOffset,
          limit: this.DeletedkeywordsfetchLimit,
          sortBy: this.DeletedKeywordSortBy,
          sortDesc: this.DeletedKeywordsSortDesc,
          keywordSearch: this.searchKeywordsInputsDeleted,
          getGoals: this.showGoalinKeywordDeleted,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.deletedKeywordReports = data.targetingKeywordReportsDeleted;
        this.deletedKeywordReportsCount = data.targetingKeywordReportsDeleted.counts?.deletedkeywords;
        if (this.deletedKeywordReportsCount > 0 && data.targetingKeywordReportsDeleted.row.length == 0) {
          this.$refs.deletedKeywordDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'deleted');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'deleted');
      },
    },
    targetingKeywordReportsAll: {
      query: ADS_MANAGER_KEYWORD_ALL,
      skip(): boolean {
        return !this.keywords_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.AllkeywordskeyOffset,
          limit: this.AllkeywordsfetchLimit,
          sortBy: this.AllKeywordSortBy,
          sortDesc: this.AllKeywordsSortDesc,
          keywordSearch: this.searchKeywordsInputsAll,
          getGoals: this.showGoalinKeywordAll,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.allKeywordReports = data.targetingKeywordReportsAll;
        this.allKeywordReportsCount = data.targetingKeywordReportsAll.counts?.allkeywords;
        if (this.allKeywordReportsCount > 0 && data.targetingKeywordReportsAll.row.length == 0) {
          this.$refs.allKeywordDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'all');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logKeywordFeaturedPath, 'all');
      },
    },

    negativeKeywordReportsLive: {
      query: ADS_MANAGER_NEG_KEYWORD_LIVE,
      skip(): boolean {
        return !this.neg_keywords_tab;
      },
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
              ...(this.selectedAdGroup && {
                adGroupId: this.selectedAdGroup.id,
              }),
            },
            dateRange: this.filterDateRange,
          },
          offset: this.LiveNegtivekeyOffset,
          limit: this.LiveNegtivefetchLimit,
          sortBy: this.LiveNegtiveKeySortBy,
          sortDesc: this.LiveNegtiveKeySortDesc,
          negSearch: this.searchNegativeInputsLive,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.negKeywordReport = data.negativeKeywordReportsLive;
        this.filteredNegKeywordReportsCount = data.negativeKeywordReportsLive.negCounts.livenegativekeyword;
        if (this.filteredNegKeywordReportsCount > 0 && data.negativeKeywordReportsLive.row.length == 0) {
          this.$refs.livenegativeDatatable.goToFirstPage();
        }

        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logNegKeywordFeaturedPath, 'live');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logNegKeywordFeaturedPath, 'live');
      },
    },
    negativeKeywordReportsPaused: {
      query: ADS_MANAGER_NEG_KEYWORD_PAUSED,
      skip(): boolean {
        return !this.neg_keywords_tab;
      },
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),

              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
              ...(this.selectedAdGroup && {
                adGroupId: this.selectedAdGroup.id,
              }),
            },
            dateRange: this.filterDateRange,
          },
          offset: this.PausedNegtivekeyOffset,
          limit: this.PausedNegtivefetchLimit,
          sortBy: this.PausedNegtiveKeySortBy,
          sortDesc: this.PausedNegtiveKeySortDesc,
          negSearch: this.searchNegativeInputsPaused,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.disabledNegKeywordReports = data.negativeKeywordReportsPaused;
        this.disabledNegKeywordReportsCount = data.negativeKeywordReportsPaused.negCounts.pausednegativekeyword;
        if (this.disabledNegKeywordReportsCount > 0 && data.negativeKeywordReportsPaused.row.length == 0) {
          this.$refs.pausedNegativeKeywordsDatatable.goToFirstPage();
        }
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logNegKeywordFeaturedPath,
          'paused'
        );
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logNegKeywordFeaturedPath,
          'paused'
        );
      },
    },
    negativeKeywordReportsDeleted: {
      query: ADS_MANAGER_NEG_KEYWORD_DELETED,
      skip(): boolean {
        return !this.neg_keywords_tab;
      },
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
              ...(this.selectedAdGroup && {
                adGroupId: this.selectedAdGroup.id,
              }),
            },
            dateRange: this.filterDateRange,
          },
          offset: this.DeletedNegtivekeyOffset,
          limit: this.DeletedNegtivefetchLimit,
          sortBy: this.DeletedNegtiveKeySortBy,
          sortDesc: this.DeletedNegtiveKeySortDesc,
          negSearch: this.searchNegativeInputsDeleted,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.deletedNegKeywordReports = data.negativeKeywordReportsDeleted;
        this.deletedNegKeywordReportsCount = data.negativeKeywordReportsDeleted.negCounts.deletednegativekeyword;
        if (this.deletedNegKeywordReportsCount > 0 && data.negativeKeywordReportsDeleted.row.length == 0) {
          this.$refs.deletednegativeDatatable.goToFirstPage();
        }
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logNegKeywordFeaturedPath,
          'deleted'
        );
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(
          this.enabledGoals,
          this.currentTab,
          this.logType,
          this.logFeaturedName,
          this.logNegKeywordFeaturedPath,
          'deleted'
        );
      },
    },
    negativeKeywordReportsAll: {
      query: ADS_MANAGER_NEG_KEYWORD_ALL,
      skip(): boolean {
        return !this.neg_keywords_tab;
      },
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
              ...(this.selectedAdGroup && {
                adGroupId: this.selectedAdGroup.id,
              }),
            },
            dateRange: this.filterDateRange,
          },
          offset: this.AllNegtivekeyOffset,
          limit: this.AllNegtivefetchLimit,
          sortBy: this.AllNegtiveKeySortBy,
          sortDesc: this.AllNegtiveKeySortDesc,
          negSearch: this.searchNegativeInputsAll,
        };
      },
      update(data) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }

        this.allNegKeywordReports = data.negativeKeywordReportsAll;
        this.allNegKeywordReportsCount = data.negativeKeywordReportsAll.negCounts.allnegativekeyword;
        if (this.allNegKeywordReportsCount > 0 && data.negativeKeywordReportsAll.row.length == 0) {
          this.$refs.allnegativeDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logNegKeywordFeaturedPath, 'all');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logNegKeywordFeaturedPath, 'all');
      },
    },
    accountsGraph: {
      query: ACCOUNTS_GRAPH,
      skip() {
        return !this.accounts_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
        };
      },
      update(data) {
        this.setChartData(data.accountsChart, 'accountsChartData');
        this.enabledGoals = false;
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAccountFeaturedPath, 'chart');
      },
      error(error) {
        this.enabledGoals = false;
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAccountFeaturedPath, 'chart');
      },
    },

    accountsReports: {
      query: ACCOUNTS_REPORTS,
      skip(): boolean {
        return !this.accounts_tab;
      },
      variables() {
        return {
          filter: this.filter,
          offset: this.AccountskeyOffset,
          limit: this.AccountfetchLimit,
          sortBy: this.AccountSortBy,
          sortDesc: this.AccountSortDesc,
          accountSearch: this.searchAccountInputs,
        };
      },
      update(data) {
        this.enabledGoals = false;

        this.accountsReport = data.accountsReports;
        this.filteredAccountReports = data.accountsReports;
        this.filteredAccountsReportsCount = data.accountsReports.totalRecords;
        if (this.filteredAccountsReportsCount > 0 && data.accountsReports.row.length == 0) {
          this.$refs.accountDatatable.goToFirstPage();
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAccountFeaturedPath, 'account');
      },
      error(error) {
        this.enabledGoals = false;
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAccountFeaturedPath, 'account');
      },
    },

    adGroupGraph: {
      query: AD_GROUP_GRAPH,
      fetchPolicy() {
        return this.adGroupChartData?.datasets?.some((dataset) => dataset.label === 'Goal Event Count') ? 'cache-first' : 'network-only';
      },
      skip(): boolean {
        return !this.ad_groups_tab;
      },
      variables() {
        return {
          filter: this.graphFilter,
          attrGraphString: this.campaignAttGraphString,
          getGoals: this.showGoalinAdgroupChart,
        };
      },
      update(data) {
        if (this.goalsLength === 0) {
          const lowerCaseChartGoalAttr = this.chartGoalAttr.map((attr) => attr.toLowerCase());
          const filteredDatasets = data.adGroupChart.datasets.filter((dataset) => {
            const label = dataset.label.toLowerCase();
            return !lowerCaseChartGoalAttr.includes(label);
          });

          data.adGroupChart.datasets = filteredDatasets;
        }
        this.setChartData(data.adGroupChart, 'adGroupChartData');
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAccountFeaturedPath, 'chart');
      },
      error(error) {
        if (this.filter.goalId) {
          this.enabledGoals = true;
        }
        this.logType = 'failure';
        this.logAdsActivity(this.enabledGoals, this.currentTab, this.logType, this.logFeaturedName, this.logAccountFeaturedPath, 'chart');
      },
    },

    //----------Queries for Filter Data Start--------------

    accountsReportsForFilter: {
      query: ACCOUNTS_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: this.filter,
        };
      },
    },

    appsReportsForFilter: {
      query: APPS_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
            },
            dateRange: this.filterDateRange,
          },
          offset: this.keyOffset,
          limit: this.fetchLimit,
          sortBy: this.appsReportSortBy,
          sortDesc: this.appsReportSortDesc,
        };
      },
      update(data) {
        this.appListing(data);
      },
    },

    campaignReportsForFilter: {
      query: CAMPAIGN_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaignSupplySource && {
                supplySources: this.selectedCampaignSupplySource.supplySources,
              }),
            },
            dateRange: this.filterDateRange,
          },
        };
      },
      update(data) {
        data.campaignReportsForFilter.row.sort((a, b) => {
          const nameA = a.campaignMetadata?.name?.toUpperCase();
          const nameB = b.campaignMetadata?.name?.toUpperCase();
          if (nameA < nameB) return -1;
        });
        const campaignReport = data.campaignReportsForFilter;
        const campaignIds = campaignReport?.row.map((item) => item.campaignMetadata?.id);
        if (campaignIds.length) {
          this.campaignAttGraphString = '&' + 'campaign_id' + '=' + campaignIds.join('&' + 'campaign_id' + '=');
        }
        return data.campaignReportsForFilter;
      },
    },

    adgroupReportsForFilter: {
      query: AD_GROUP_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
            },
            ...(this.selectedGoal && {
              goalId: this.selectedGoal._id,
            }),
            ...(this.selectedSecondaryGoal && {
              secondaryGoalId: this.selectedSecondaryGoal._id,
            }),
            dateRange: this.filterDateRange,
          },
        };
      },
      update(data) {
        data.adgroupReportsForFilter.row.sort((a, b) => {
          const nameA = a.adgroupMetadata?.name?.toUpperCase();
          const nameB = b.adgroupMetadata?.name?.toUpperCase();
          if (nameA < nameB) return -1;
        });
        return data.adgroupReportsForFilter;
      },
    },

    productPageReportsForFilter: {
      query: PRODUCT_PAGE_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: {
            metadata: {
              ...(this.selectedAccount && {
                orgId: this.selectedAccount.orgId,
              }),
              ...(this.selectedApp && {
                app: {
                  adamId: this.selectedApp.adamId,
                },
              }),
              ...(this.selectedCampaign && {
                campaignId: this.selectedCampaign.id,
              }),
            },
            ...(this.selectedGoal && {
              goalId: this.selectedGoal._id,
            }),
            ...(this.selectedSecondaryGoal && {
              secondaryGoalId: this.selectedSecondaryGoal._id,
            }),
            dateRange: this.filterDateRange,
          },
        };
      },
      update(data) {
        const productPagesReport = data.productPageReportsForFilter;
        this.productPageReportCount = productPagesReport?.totalRecords;
        productPagesReport.row.sort((a, b) => {
          const nameA = a.adsMetadata?.name?.toUpperCase();
          const nameB = b.adsMetadata?.name?.toUpperCase();
          if (nameA < nameB) return -1;
        });
        return productPagesReport;
      },
    },
  },
  computed: {
    secondaryGoals: {
      cache: false,
      get() {
        if (this.selectedGoal) {
          return this.getGoals.filter((g) => g._id !== this.selectedGoal._id);
        } else {
          return [];
        }
      },
    },
    disableTogglePopup: {
      cache: false,
      get() {
        return LSS.disableTogglePopup == true ? true : false;
      },
    },
    applyButtonDisabled() {
      return !(
        this.selectedAccount == 0 ||
        this.selectedApp == 0 ||
        this.selectedCampaign == 0 ||
        this.selectedCampaignType == 0 ||
        this.selectedAdGroup == 0
      );
    },

    permissions: {
      cache: false,
      get() {
        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            return [];
          } else {
            return decodeToken(LSS.token).permissions ? decodeToken(LSS.token).permissions : [];
          }
        } else {
          return [];
        }
      },
    },
    isSuperAdmin: {
      cache: false,
      get() {
        if (decodeToken(LSS.token) && decodeToken(LSS.token).Role == 'SUPERADMIN') {
          return true;
        }
        return false;
      },
    },
    tabItems: {
      cache: false,
      get() {
        return [
          {
            tab: this.$t('tabs.Strategies'),
            content: 'Strategies 1 Content',
            tabKey: AdsManagerTabs.STRATEGIES,
            filters: [],
          },
          {
            tab: this.$t('tabs.accounts'),
            content: 'Accounts 2 Content',
            tabKey: AdsManagerTabs.ACCOUNTS,
            filters: [],
          },
          {
            tab: this.$t('tabs.apps'),
            content: 'Apps 3 Content',
            tabKey: AdsManagerTabs.APPS,
            filters: [AdsManagerFilters.ACCOUNT],
          },
          {
            tab: this.$t('tabs.campaigns'),
            content: 'Campaigns 4 Content',
            tabKey: AdsManagerTabs.CAMPAIGNS,
            filters: [AdsManagerFilters.ACCOUNT, AdsManagerFilters.APPS],
          },
          {
            tab: this.$t('tabs.adgroups'),
            content: 'Ad Groups 5 Content',
            tabKey: AdsManagerTabs.AD_GROUPS,
            filters: [AdsManagerFilters.ACCOUNT, AdsManagerFilters.APPS, AdsManagerFilters.CAMPAIGN],
          },
          {
            tab: this.$t('tabs.keywords'),
            content: 'Keywords 6 Content',
            tabKey: AdsManagerTabs.KEYWORDS,
            filters: [AdsManagerFilters.ACCOUNT, AdsManagerFilters.APPS, AdsManagerFilters.CAMPAIGN, AdsManagerFilters.ADGROUP],
          },
          {
            tab: this.$t('tabs.ad'),
            content: 'Product Page 7 Content',
            tabKey: AdsManagerTabs.PRODUCT_PAGES,
            filters: [
              AdsManagerFilters.ACCOUNT,
              AdsManagerFilters.APPS,
              AdsManagerFilters.CAMPAIGN,
              AdsManagerFilters.ADGROUP,
              AdsManagerFilters.AD,
            ],
          },
          {
            tab: this.$t('tabs.searchTerms'),
            tabKey: AdsManagerTabs.SEARCH_TERMS,
            filters: [AdsManagerFilters.ACCOUNT, AdsManagerFilters.APPS, AdsManagerFilters.CAMPAIGN, AdsManagerFilters.ADGROUP],
          },
          {
            tab: this.$t('tabs.negKeywords'),
            content: 'Negative Keywords 8 Content',
            tabKey: AdsManagerTabs.NEG_KEYWORDS,
            filters: [AdsManagerFilters.ACCOUNT, AdsManagerFilters.APPS, AdsManagerFilters.CAMPAIGN, AdsManagerFilters.ADGROUP],
          },
        ];
      },
    },

    //Data Tables
    strategyHeaders: {
      cache: false,
      get() {
        return [
          {
            text: 'Name',
            align: 'start',
            value: 'strategyitems.name',
          },
          {
            text: 'Target Apps',
            value: 'targetEntities',
          },
          {
            text: 'Campaigns',
            value: 'stratCampaigns',
          },
          {
            text: 'Ad Groups',
            value: 'stratAdGroups',
          },
          {
            text: 'Keywords',
            value: 'stratKeywords',
          },
        ];
      },
    },
    // 1-Accounts Data Table
    accountHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('accountHeaders.account'),
            align: 'start',
            sortable: false,
            value: 'orgMetadata.orgName',
            class: 'sortHandle',
          },
          {
            text: this.$t('accountHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.impressions'),
            value: 'total.impressions',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.taps'),
            value: 'total.taps',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('accountHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
        ];
      },
    },

    // 2-Apps Data Table
    appsHeaders: {
      cache: false,
      get() {
        const headers = [
          {
            text: this.$t('appsHeaders.appName'),
            align: 'start',
            value: 'metadata.app.appName',
            class: 'sortHandle',
          },
          {
            text: this.$t('appsHeaders.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.taps'),
            value: 'total.taps',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.spend'),
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.appId'),
            value: 'metadata.app.adamId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
        ];

        return headers;
      },
    },

    // 3-Campaign Data Table
    campaignHeaders: {
      get() {
        const headers = [
          {
            text: this.$t('campaignHeaders.campaign'),
            align: 'start',
            value: 'campaignMetadata.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('campaignHeaders.campaignId'),
            value: 'campaignMetadata.id',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.supplySource'),
            value: 'campaignMetadata.supplySources',
            sortable: false,
            class: 'sortHandle',
          },
          {
            text: this.$t('campaignHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.taps'),
            value: 'total.taps',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.spend'),
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.dailyCap'),
            value: 'campaignMetadata.dailyBudgetAmount.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.delete'),
            value: 'campaignMetadata.deleted',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.displayStatus'),
            value: 'campaignMetadata.displayStatus',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.totalBudget'),
            value: 'campaignMetadata.budgetAmount.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.appName'),
            value: 'metadata.app.appName',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('campaignHeaders.appId'),
            value: 'metadata.app.adamId',
            class: 'sortHandle',
            align: 'end',
          },
        ];

        if (this.addAttrFieldInReport && this.goalsLength > 0) {
          headers.push(...this.attrFields);
        }

        if (this.selectedSecondaryGoal && this.goalsLength > 0) {
          headers.push(...this.attrFieldsSec);
        }
        let order = ["Campaign","Campaign ID","Type","Campaign Status","Impressions","Taps","Installs (Total)","Goal Event Count","Goal ROAS","Cost Per Goal","Avg CPA (Total)","Average CPT","Spend","Daily Cap","Storefronts","Delete","Display Status","Average CPM","TTR","Conversion Rate (Tap-Through)","Total Budget","Account","App Name","App ID","Goal Revenue","Revenue Per Goal","Goal ARPU","Goal AMPU","Conversion Rate (Total)","Installs (Tap-Through)","Installs (View-Through)","Avg CPA (Tap-Through)","New Downloads (Tap-Through)","New Downloads (View-Through)","New Downloads (Total)","Redownloads (Tap-Through)","Redownloads (View-Through)","Redownloads (Total)"];
        headers.sort((a, b) => order.indexOf(a.text) - order.indexOf(b.text));
        return headers;
      },
    },

    // 3(i)- Campaign Draft Data Table
    campaignDraftHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('campaignDraftHeaders.campaignName'),
            value: 'campaign.name',
            class: 'sortHandle',
            sortable: false,
            align: 'start',
          },
          {
            text: this.$t('campaignDraftHeaders.countries'),
            value: 'campaign.countriesOrRegions',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('campaignDraftHeaders.budgetAmount'),
            value: 'campaign.budgetAmount.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('campaignDraftHeaders.resume'),
            value: 'resume',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
        ];
      },
    },

    // 4- Adgroup Data Table
    adGroupHeaders: {
      get() {
        const headers = [
          {
            text: this.$t('adGroupHeaders.adgroup'),
            align: 'start',
            value: 'adgroupMetadata.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('adGroupHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.spend'),
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            align: 'end',
            sortable: true,
          },
          {
            text: this.$t('adGroupHeaders.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.taps'),
            value: 'total.taps',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.delete'),
            value: 'adgroupMetadata.deleted',
            sortable: false,
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            class: 'sortHandle',
            align: 'end',
            sortable: true,
          },
          {
            text: this.$t('adGroupHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            class: 'sortHandle',
            align: 'end',
            sortable: true,
          },
          {
            text: this.$t('adGroupHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.displayStatus'),
            value: 'metadata.adGroupDisplayStatus',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.appId'),
            value: 'campaignMetadata.adamId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.campaignId'),
            value: 'adgroupMetadata.campaignId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.adgroupId'),
            value: 'adgroupMetadata.id',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adGroupHeaders.campaign'),
            align: 'start',
            value: 'campaignMetadata.name',
            sortable: false,
            class: 'sortHandle',
          },
        ];

        if (this.addAttrFieldInReport && this.goalsLength > 0) {
          headers.push(...this.attrFields);
        }

        if (this.selectedSecondaryGoal && this.goalsLength > 0) {
          headers.push(...this.attrFieldsSec);
        }
        let order = ["Ad Group","Ad Group ID","Ad Group Status","Impressions","Taps","Installs (Total)","Goal Event Count","Goal ROAS","Cost Per Goal","Avg CPA (Total)","Average CPT","Spend","Storefronts","TTR","Conversion Rate (Tap-Through)","Avg CPA (Tap-Through)","Average CPM","Campaign Status","Display Status","Account","App ID","Campaign ID","Campaign","Goal Revenue","Revenue Per Goal","Goal ARPU","Goal AMPU","Installs (Tap-Through)","Installs (View-Through)","New Downloads (Tap-Through)","New Downloads (View-Through)","New Downloads (Total)","Redownloads (Tap-Through)","Redownloads (View-Through)","Redownloads (Total)","Delete","Conversion Rate (Total)"];
        headers.sort((a, b) => order.indexOf(a.text) - order.indexOf(b.text));
        return headers;
      },
    },

    // 5- Keyword Data Table
    keyheaders: {
      get() {
        const headers = [
          {
            text: this.$t('keyHeaders.keyword'),
            align: 'start',
            value: 'keywordMetadata.text',
            class: 'sortHandle',
          },
          {
            text: this.$t('keyHeaders.keywordStatus'),
            value: 'keywordMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.bidAmount'),
            value: 'keywordMetadata.bidAmount.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('labels.spend'),
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            class: 'sortHandle',
            align: 'end',
            sortable: true,
          },
          {
            text: this.$t('keyHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            class: 'sortHandle',
            align: 'end',
            sortable: true,
          },
          {
            text: this.$t('keyHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            align: 'end',
            sortable: true,
          },
          {
            text: this.$t('keyHeaders.taps'),
            value: 'total.taps',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.delete'),
            value: 'keywordMetadata.deleted',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.suggestedBidAmount'),
            value: 'insights.bidRecommendation.suggestedBidAmount.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.keywordId'),
            value: 'keywordMetadata.id',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.appId'),
            value: 'campaignMetadata.adamId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.campaignId'),
            value: 'metadata.campaignId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.adgroupId'),
            value: 'keywordMetadata.adGroupId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('keyHeaders.campaign'),
            align: 'start',
            value: 'campaignMetadata.name',
            sortable: false,
            class: 'sortHandle',
          },
          {
            text: this.$t('keyHeaders.adgroup'),
            align: 'start',
            value: 'metadata.adGroupName',
            sortable: false,
            class: 'sortHandle',
          },
          {
            text: this.$t('keyHeaders.matchType'),
            align: 'start',
            value: 'keywordMetadata.matchType',
            class: 'sortHandle',
          },
        ];

        if (this.addAttrFieldInReport && this.goalsLength > 0) {
          headers.push(...this.attrFields);
        }

        if (this.selectedSecondaryGoal && this.goalsLength > 0) {
          headers.push(...this.attrFieldsSec);
        }
        let order = ["Keyword","Keyword Id","Keyword Status","Bid Amount","Impressions","Taps","Installs (Total)","Goal Event Count","Goal ROAS","Cost Per Goal","Avg CPA (Total)","Average CPT","Spend","Storefronts","Match Type","TTR","Conversion Rate (Tap-Through)","Avg CPA (Tap-Through)","Average CPM","ASA Suggested Bid","Campaign Status","Ad Group Status","App ID","Campaign ID","Ad Group ID","Campaign","Ad Group","Goal Revenue","Revenue Per Goal","Goal ARPU","Goal AMPU","Delete","Installs (Tap-Through)","Installs (View-Through)","New Downloads (Tap-Through)","New Downloads (View-Through)","New Downloads (Total)","Redownloads (Tap-Through)","Redownloads (View-Through)","Redownloads (Total)","Conversion Rate (Total)"];
        headers.sort((a, b) => order.indexOf(a.text) - order.indexOf(b.text));
        return headers;
      },
    },

    // 6- Creative Set Data Table
    creativeheaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('creativeHeaders.creativeSet'),
            align: 'start',
            value: 'creativeSetMetadata.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('creativeHeaders.creativeSetStatus'),
            value: 'creativeSetMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.spend') + ' ($)',
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.tapInstallCPI') + ' ($)',
            value: 'total.tapInstallCPI.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.totalAvgCPI') + ' ($)',
            value: 'total.totalAvgCPI.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.averageCpt') + ' ($)',
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.taps'),
            value: 'total.taps',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.ttr') + ' (%)',
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.tapInstallRate') + ' (%)',
            value: 'total.tapInstallRate',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.totalInstallRate') + ' (%)',
            value: 'total.totalInstallRate',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.cpi'),
            value: 'conversion',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.goals'),
            value: 'conversion',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.costPerGoal'),
            value: 'conversion',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.goalRate'),
            value: 'conversion',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.revenuePerGoal'),
            value: 'conversion',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.goalsRevenue'),
            value: 'conversion',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.account'),
            value: 'orgMetadata.orgName',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.appId'),
            value: 'campaignMetadata.adamId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.campaignId'),
            value: 'campaignMetadata.id',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('creativeHeaders.adgroupId'),
            value: 'adgroupMetadata.id',
            class: 'sortHandle',
            align: 'end',
          },
        ];
      },
    },

    // 7- Search Term Data Table
    searchTermsHeader: {
      cache: false,
      get() {
        const headers = [
          {
            text: this.$t('searchTermsHeader.searchTerm'),
            value: 'metadata.searchTermText',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.spend'),
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.averageCpt'),
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.taps'),
            value: 'total.taps',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.tapInstallRate'),
            value: 'total.tapInstallRate',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.totalInstallRate'),
            value: 'total.totalInstallRate',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.tapRedownloads'),
            value: 'total.tapRedownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.viewRedownloads'),
            value: 'total.viewRedownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.totalRedownloads'),
            value: 'total.totalRedownloads',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.keyword'),
            align: 'start',
            value: 'metadata.keyword',
            class: 'sortHandle',
          },
          {
            text: this.$t('searchTermsHeader.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.appId'),
            value: 'metadata.app.adamId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.campaignId'),
            value: 'metadata.campaignId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.adgroupId'),
            value: 'metadata.adGroupId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('searchTermsHeader.campaign'),
            align: 'start',
            value: 'campaignMetadata.name',
            sortable: false,
            class: 'sortHandle',
          },
          {
            text: this.$t('searchTermsHeader.adgroup'),
            align: 'start',
            sortable: false,
            value: 'metadata.adGroupName',
            class: 'sortHandle',
          },
        ];
        if (this.addAttrFieldInReport && this.goalsLength > 0) {
          headers.push(...this.attrFields);
        }

        if (this.selectedSecondaryGoal && this.goalsLength > 0) {
          headers.push(...this.attrFieldsSec);
        }
        let order = ["Search Term Text","Keyword","Impressions","Taps","Installs (Total)","Goal Event Count","Goal ROAS","Cost Per Goal","Avg CPA (Total)","Average CPT","Spend","TTR","Conversion Rate (Tap-Through)","Avg CPA (Tap-Through)","Average CPM","Ad Group Status","App ID","Campaign ID","Ad Group ID","Campaign","Ad Group","Goal Revenue","Revenue Per Goal","Goal ARPU","Goal AMPU","Installs (Tap-Through)","Installs (View-Through)","New Downloads (Tap-Through)","New Downloads (View-Through)","New Downloads (Total)","Redownloads (Tap-Through)","Redownloads (View-Through)","Redownloads (Total)","Conversion Rate (Total)"];
        headers.sort((a, b) => order.indexOf(a.text) - order.indexOf(b.text));
        return headers;
      },
    },

    // 8- Negative Keyword Data Table
    negativeHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('negativeHeaders.keyword'),
            value: 'text',
            align: 'start',
            class: 'sortHandle',
          },
          {
            text: this.$t('negativeHeaders.keywordId'),
            value: 'id',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('negativeHeaders.adgroup'),
            align: 'start',
            sortable: false,
            value: 'adgroupMetadataNeg.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('negativeHeaders.adgroupId'),
            value: 'adGroupId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('negativeHeaders.campaign'),
            align: 'start',
            sortable: false,
            value: 'campaignMetadataNeg.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('negativeHeaders.campaignId'),
            value: 'campaignId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('negativeHeaders.status'),
            value: 'status',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('negativeHeaders.deleted'),
            value: 'deleted',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('negativeHeaders.modificationTime'),
            value: 'modificationTime',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('keyOpt.action'),
            value: 'Action',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          }
          // {
          //   text: this.$t('negativeHeaders.matchType'),
          //   value: 'matchType',
          //   class: 'sortHandle',
          //   align: 'end',
          // }
        ];
      },
    },

    // 6- Creative Set Data Table
    adsHeaders: {
      cache: false,
      get() {
        const headers = [
          {
            text: this.$t('adsHeaders.ads'),
            align: 'start',
            value: 'adsMetadata.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('adsHeaders.creativeType'),
            value: 'adsMetadata.creativeType',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.creativeSetStatus'),
            value: 'adsMetadata.status',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.spend'),
            value: 'total.localSpend.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.impressions'),
            value: 'total.impressions',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.action'),
            value: 'adsMetadata.deleted',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.taps'),
            value: 'total.taps',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: 'Average CPM',
            value: 'total.avgCPM.amount',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.ttr'),
            value: 'total.ttr',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            class: 'sortHandle',
            sortable: true,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.storefronts'),
            value: 'campaignMetadata.countriesOrRegions',
            class: 'sortHandle',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.campaignStatus'),
            value: 'campaignMetadata.status',
            sortable: true,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.adgroupStatus'),
            value: 'adgroupMetadata.status',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.account'),
            value: 'orgMetadata.orgName',
            sortable: false,
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.appId'),
            value: 'campaignMetadata.adamId',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.campaignId'),
            value: 'campaignMetadata.id',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.adgroupId'),
            value: 'adgroupMetadata.id',
            class: 'sortHandle',
            align: 'end',
          },
          {
            text: this.$t('adsHeaders.campaign'),
            align: 'start',
            sortable: false,
            value: 'campaignMetadata.name',
            class: 'sortHandle',
          },
          {
            text: this.$t('adsHeaders.adgroup'),
            align: 'start',
            sortable: false,
            value: 'adgroupMetadata.name',
            class: 'sortHandle',
          },
        ];

        if (this.addAttrFieldInReport && this.goalsLength > 0) {
          headers.push(...this.attrFields);
        }

        if (this.selectedSecondaryGoal && this.goalsLength > 0) {
          headers.push(...this.attrFieldsSec);
        }
        let order = ["Ad","Creative Type","Status","Impressions","Taps","Installs (Total)","Goal Event Count","Goal ROAS","Cost Per Goal","Avg CPA (Total)","Average CPT","Spend","Storefronts","Action","Average CPM","TTR","Conversion Rate (Tap-Through)","Conversion Rate (Total)","Campaign Status","Ad Group Status","Account","App ID","Campaign ID","Ad Group ID","Campaign","Ad Group","Goal Revenue","Revenue Per Goal","Goal ARPU","Goal AMPU","Installs (Tap-Through)","Installs (View-Through)","Avg CPA (Tap-Through)","New Downloads (Tap-Through)","New Downloads (View-Through)","New Downloads (Total)","Redownloads (Tap-Through)","Redownloads (View-Through)","Redownloads (Total)"];
        headers.sort((a, b) => order.indexOf(a.text) - order.indexOf(b.text));
        return headers;
      },
    },

    //Common headers for attribution fields
    attrFields: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('appsHeaders.goalEventCount'),
            class: 'sortHandle',
            value: 'calcEventCount',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.goalsRevenue'),
            class: 'sortHandle',
            value: 'calcGoalsRevenue',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.revenuePerGoal'),
            class: 'sortHandle',
            value: 'calcRevenuePerGoal',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.costPerGoal'),
            class: 'sortHandle',
            value: 'calcCostPerGoal',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.goalsRoas'),
            class: 'sortHandle',
            value: 'calcGoalRoas',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.goalArpu'),
            class: 'sortHandle',
            value: 'calcARPU',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.goalAMPU'),
            class: 'sortHandle',
            value: 'calcAMPU',
            sortable: false,
            align: 'end',
          },
        ];
      },
    },
    attrFieldsSec: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('appsHeaders.goalEventCountSec'),
            class: 'sortHandle',
            value: 'calcEventCountSec',
            sortable: false,
            align: 'end',
          },
          {
            text: this.$t('appsHeaders.costPerGoalSec'),
            class: 'sortHandle',
            value: 'calcCostPerGoalSec',
            sortable: false,
            align: 'end',
          },
        ];
      },
    },
    filterDateRangeText() {
      if (this.filterDateRange[0] > this.filterDateRange[1]) {
        const maxDate = this.filterDateRange[0];
        this.filterDateRange[0] = this.filterDateRange[1];
        this.filterDateRange[1] = maxDate;
      }
      return this.filterDateRange.join(' - ');
    },
  },

  methods: {
    getCurrencyIcon(value) {
      if (value !== undefined || value !== null) {
        return getSymbolFromCurrency(value) == undefined ? '' : getSymbolFromCurrency(value);
      }
      return '';
    },
    async getStrategyTimelines(strategyId) {
      const res = await this.$apollo
        .query({
          query: GET_ALL_TIMELINE_VIEW,
          variables: { strategyId: strategyId },
        })
        .catch((error) => {
          this.$notify.error('Unable to get existing timelines');
          console.log('error', error);
        });

      return res.data.getAllTimelineEntries;
    },
    async fetchStrategyItems(from) {
      this.$apollo
        .query({
          query: GET_ALL_STRATEGIC_VIEW,
          variables: {},
        })
        .then((res) => {
          const strategies = res.data.getAllStrategies.filter((strategy) => strategy.targetEntities && strategy.targetEntities.length > 0);
          if (strategies.length == 0 && from == 'mounted') {
            this.onTabChange(this.tabItems[1]);
          }
          if (this.redirectStrategyId != null) {
            this.filterStrategy = strategies.find((item) => item.strategyId === this.redirectStrategyId);
            if (this.filterStrategy) {
              this.strategyFilters(this.filterStrategy);
            }
          }
          strategies.forEach((strategy) => {
            const targetEntities = strategy.targetEntities;

            let appIcon = '';
            const firstAppName = targetEntities && targetEntities.length > 0 ? targetEntities[0].appName : null;
            const restOfAppsCount =
              targetEntities && targetEntities.length > 0 ? _.uniq(targetEntities.map((te) => te.appId)).length - 1 : 0;
            if (LSS.ownApps && LSS.ownApps.length > 0) {
              for (let index = 0; index < LSS.ownApps.length; index++) {
                const element = LSS.ownApps[index];
                if (targetEntities && targetEntities.length > 0) {
                  if (targetEntities[0] && element.adamId == targetEntities[0].appId) {
                    appIcon = element.appIcon;
                  }
                }
              }
            }
            const combinedEntities = [{ appName: firstAppName, remainingApps: restOfAppsCount, appIcon: appIcon }];
            strategy.combinedEntities = combinedEntities;

            let stratCampaignsVal = 0;
            let stratKeywordsVal = 0;
            let stratAdGroupsVal = 0;
            strategy.targetEntities.map((item) => {
              if (item.type == 'CAMPAIGN') {
                stratCampaignsVal++;
              }
              if (item.type == 'KEYWORD') {
                stratKeywordsVal++;
              }
              if (item.type == 'ADGROUP') {
                stratAdGroupsVal++;
              }
            });

            strategy.stratCampaignsVal = stratCampaignsVal;
            strategy.stratKeywordsVal = stratKeywordsVal;
            strategy.stratAdGroupsVal = stratAdGroupsVal;
          });
          this.strategyitems = strategies;
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    isStrategiesListEmpty(item) {
      if (item.tabKey == AdsManagerTabs.STRATEGIES) {
        if (this.strategyitems.length) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    checkObjectsEqual(obj1, obj2) {
      const keys1 = JSON.stringify(obj1);
      const keys2 = JSON.stringify(obj2);

      if (keys1 !== keys2) {
        return false;
      }

      return true;
    },

    calculateGreaterDuration(tabName) {
      // calculate max duration takes by apps in relavent tab
      let maxDuration = '';
      switch (tabName) {
        case 'accounts_tab':
          const accountsDuration = (this.accountsEndTime - this.startAccountTime) / 1000;
          const accountsChartDuration = (this.accountsChartEndTime - this.startAccountTime) / 1000;
          maxDuration = Math.max(accountsDuration, accountsChartDuration);
          return maxDuration;
          break;
        case 'apps_tab':
          const appsDuration = (this.appsEndTime - this.startAppTime) / 1000;
          const appsChartDuration = (this.appsChartEndTime - this.startAppTime) / 1000;
          maxDuration = Math.max(appsDuration, appsChartDuration);
          return maxDuration;
          break;
        case 'campaigns_tab':
          const campaignLiveDuration = (this.campaignLiveEndTime - this.startCampaignTime) / 1000;
          const campaignPausedDuration = (this.campaignPausedEndTime - this.startCampaignTime) / 1000;
          const campaignAllDuration = (this.campaignAllEndTime - this.startCampaignTime) / 1000;
          const campaignDeletedDuration = (this.campaignDeletedEndTime - this.startCampaignTime) / 1000;
          const campaignChartDuration = (this.campaignChartEndTime - this.startCampaignTime) / 1000;
          const campaignSummaryDuration = (this.campaignSummaryEndTime - this.startCampaignTime) / 1000;
          maxDuration = Math.max(
            campaignLiveDuration,
            campaignPausedDuration,
            campaignAllDuration,
            campaignDeletedDuration,
            campaignChartDuration,
            campaignSummaryDuration
          );
          return maxDuration;
          break;
        case 'ad_groups_tab':
          const adgroupLiveDuration = (this.adgroupLiveEndTime - this.startAdgroupTime) / 1000;
          const adgroupPausedDuration = (this.adgroupPausedEndTime - this.startAdgroupTime) / 1000;
          const adgroupAllDuration = (this.adgroupAllEndTime - this.startAdgroupTime) / 1000;
          const adgroupDeletedDuration = (this.adgroupDeletedEndTime - this.startAdgroupTime) / 1000;
          const adgroupChartDuration = (this.adgroupChartEndTime - this.startAdgroupTime) / 1000;
          const adgroupSummaryDuration = (this.adgroupSummaryEndTime - this.startAdgroupTime) / 1000;
          maxDuration = Math.max(
            adgroupLiveDuration,
            adgroupPausedDuration,
            adgroupAllDuration,
            adgroupDeletedDuration,
            adgroupChartDuration,
            adgroupSummaryDuration
          );
          return maxDuration;
          break;
        case 'keywords_tab':
          const keywordLiveDuration = (this.keywordLiveEndTime - this.startKeywordTime) / 1000;
          const keywordPausedDuration = (this.keywordPausedEndTime - this.startKeywordTime) / 1000;
          const keywordAllDuration = (this.keywordAllEndTime - this.startKeywordTime) / 1000;
          const keywordDeletedDuration = (this.keywordDeletedEndTime - this.startKeywordTime) / 1000;
          const keywordChartDuration = (this.keywordChartEndTime - this.startKeywordTime) / 1000;
          const keywordSummaryDuration = (this.keywordSummaryEndTime - this.startKeywordTime) / 1000;
          maxDuration = Math.max(
            keywordLiveDuration,
            keywordPausedDuration,
            keywordAllDuration,
            keywordDeletedDuration,
            keywordChartDuration,
            keywordSummaryDuration
          );
          return maxDuration;
          break;
        case 'product_page_tab':
          const adsLiveDuration = (this.adsLiveEndTime - this.startAdTime) / 1000;
          const adsPausedDuration = (this.adsPausedEndTime - this.startAdTime) / 1000;
          const adsAllDuration = (this.adsAllEndTime - this.startAdTime) / 1000;
          const adsDeletedDuration = (this.adsDeletedEndTime - this.startAdTime) / 1000;
          const adsChartDuration = (this.adsChartEndTime - this.startAdTime) / 1000;
          const adsSummaryDuration = '';
          //   const adsSummaryDuration = (this.adsSummaryEndTime - this.startAdTime)/1000;
          maxDuration = Math.max(
            adsLiveDuration,
            adsPausedDuration,
            adsAllDuration,
            adsDeletedDuration,
            adsChartDuration,
            adsSummaryDuration
          );
          return maxDuration;
          break;
        case 'search_terms_tab':
          const searchTermDuration = (this.searchTermEndTime - this.startSearchTermTime) / 1000;
          const searchTermChartDuration = (this.searchTermChartEndTime - this.startSearchTermTime) / 1000;
          maxDuration = Math.max(searchTermDuration, searchTermChartDuration);
          return maxDuration;
          break;
        case 'neg_keywords_tab':
          const negKeywordLiveDuration = (this.negKeywordLiveEndTime - this.startNegKeywordTime) / 1000;
          const negKeywordPausedDuration = (this.negKeywordPausedEndTime - this.startNegKeywordTime) / 1000;
          const negKeywordAllDuration = (this.negKeywordAllEndTime - this.startNegKeywordTime) / 1000;
          const negKeywordDeletedDuration = (this.negKeywordDeletedEndTime - this.startNegKeywordTime) / 1000;
          maxDuration = Math.max(negKeywordLiveDuration, negKeywordPausedDuration, negKeywordAllDuration, negKeywordDeletedDuration);
          return maxDuration;
          break;
      }
    },
    getTabName(tabName) {
      const words = tabName.split('_');
      const len = words.length - 1;
      const str = [];
      for (let i = 0; i < len; i++) {
        str[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      return str.join(' ');
    },
    getLastPath(featuredPath) {
      if (
        LSS.filters?.selectedAccount?.orgName &&
        (this.currentTab == 'apps_tab' ||
          this.currentTab == 'search_terms_tab' ||
          this.currentTab == 'product_page_tab' ||
          this.currentTab == 'neg_keywords_tab')
      ) {
        featuredPath = LSS.filters.selectedAccount.orgName;
      }
      if (
        LSS.filters?.selectedApp?.appName &&
        (this.currentTab == 'campaigns_tab' ||
          this.currentTab == 'search_terms_tab' ||
          this.currentTab == 'product_page_tab' ||
          this.currentTab == 'neg_keywords_tab')
      ) {
        featuredPath = LSS.filters.selectedApp.appName;
      }
      if (
        LSS.filters?.selectedCampaign?.name &&
        (this.currentTab == 'ad_groups_tab' ||
          this.currentTab == 'search_terms_tab' ||
          this.currentTab == 'product_page_tab' ||
          this.currentTab == 'neg_keywords_tab')
      ) {
        featuredPath = LSS.filters.selectedCampaign.name;
      }

      if (
        LSS.filters?.selectedAdGroup?.name &&
        (this.currentTab == 'keywords_tab' ||
          this.currentTab == 'search_terms_tab' ||
          this.currentTab == 'product_page_tab' ||
          this.currentTab == 'neg_keywords_tab')
      ) {
        featuredPath = LSS.filters.selectedAdGroup.name;
      }
      if (LSS.filters?.selectedTargetingKeyword?.text && this.currentTab == 'keywords_tab') {
        featuredPath = LSS.filters.selectedTargetingKeyword?.text;
      }
      return featuredPath;
    },
    async logAdsActivity(goalsEnabled, tabName, logType, featuredName, featuredPath, opt) {
      let selectedTab = this.getTabName(tabName);
      let duration = '';
      switch (tabName) {
        case 'accounts_tab':
          if (this.islogAccountProcess == 0) return;
          if (opt == 'account') {
            this.accountsEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.accountsChartEndTime = new Date().getTime();
          }
          if (
            this.accountsEndTime != '' &&
            this.accountsChartEndTime != '' &&
            this.startAccountTime != '' &&
            this.startAccountRepeatTime != this.startAccountTime
          ) {
            this.startAccountRepeatTime = this.startAccountTime;

            if (this.islogAccountProcess == 1) {
              const data = {
                accountCount: this.filteredAccountsReportsCount ? this.filteredAccountsReportsCount : 0,
              };
              duration = this.calculateGreaterDuration(tabName);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const logMessage = 'View results for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';
              await logActivity(logType, logMessage, featuredName, featuredPath, { data });
              this.accountsEndTime = '';
              this.accountsChartEndTime = '';
              this.islogAccountProcess = 0;
              return false;
            }
          }
          break;
        case 'apps_tab':
          if (this.islogAppProcess == 0) return;
          if (opt == 'app') {
            this.appsEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.appsChartEndTime = new Date().getTime();
          }
          if (
            this.appsEndTime != '' &&
            this.appsChartEndTime != '' &&
            this.startAppTime != '' &&
            this.startAppRepeatTime != this.startAppTime
          ) {
            this.startAppRepeatTime = this.startAppTime;
            if (this.islogAppProcess == 1) {
              const data = {
                appsCount: this.filteredAppReportsCount ? this.filteredAppReportsCount : 0,
              };
              duration = this.calculateGreaterDuration(tabName);
              featuredPath = this.getLastPath(featuredPath);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const logMessage = 'View results for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';

              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.appsEndTime = '';
              this.appsChartEndTime = '';
              this.islogAppProcess = 0;
              return false;
            }
          }
          break;
        case 'campaigns_tab':
          if (this.islogCampaignProcess == 0) return;
          if (opt == 'live') {
            this.campaignLiveEndTime = new Date().getTime();
          } else if (opt == 'paused') {
            this.campaignPausedEndTime = new Date().getTime();
          } else if (opt == 'deleted') {
            this.campaignDeletedEndTime = new Date().getTime();
          } else if (opt == 'all') {
            this.campaignAllEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.campaignChartEndTime = new Date().getTime();
          } else if (opt == 'summary') {
            this.campaignSummaryEndTime = new Date().getTime();
          }
          if (
            this.campaignLiveEndTime != '' &&
            this.campaignPausedEndTime != '' &&
            this.campaignDeletedEndTime != '' &&
            this.campaignAllEndTime != '' &&
            this.campaignChartEndTime != '' &&
            this.campaignSummaryEndTime != '' &&
            this.startCampaignTime != '' &&
            this.startCampaignRepeatTime != this.startCampaignTime
          ) {
            this.startCampaignRepeatTime = this.startCampaignTime;
            if (this.islogCampaignProcess == 1) {
              const data = {
                liveCampaigns: this.liveReportsCount ? this.liveReportsCount : 0,
                pausedCampaigns: this.disabledReportsCount ? this.disabledReportsCount : 0,
                deletedCampaigns: this.deletedReportsCount ? this.deletedReportsCount : 0,
                allCampaigns: this.allReportsCount ? this.allReportsCount : 0,
              };
              featuredPath = this.getLastPath(featuredPath);
              duration = this.calculateGreaterDuration(tabName);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const goalsStr = goalsEnabled ? ' with goals' : '';
              const logMessage = 'View results' + goalsStr + ' for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';
              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.campaignLiveEndTime = '';
              this.campaignPausedEndTime = '';
              this.campaignDeletedEndTime = '';
              this.campaignAllEndTime = '';
              this.campaignChartEndTime = '';
              this.campaignSummaryEndTime = '';
              this.islogCampaignProcess = 0;
              return false;
            }
          }
          break;
        case 'ad_groups_tab':
          if (this.islogAdgroupProcess == 0) return;
          if (opt == 'live') {
            this.adgroupLiveEndTime = new Date().getTime();
          } else if (opt == 'paused') {
            this.adgroupPausedEndTime = new Date().getTime();
          } else if (opt == 'deleted') {
            this.adgroupDeletedEndTime = new Date().getTime();
          } else if (opt == 'all') {
            this.adgroupAllEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.adgroupChartEndTime = new Date().getTime();
          } else if (opt == 'summary') {
            this.adgroupSummaryEndTime = new Date().getTime();
          }
          if (
            this.adgroupLiveEndTime != '' &&
            this.adgroupPausedEndTime != '' &&
            this.adgroupDeletedEndTime != '' &&
            this.adgroupAllEndTime != '' &&
            this.adgroupChartEndTime != '' &&
            this.adgroupSummaryEndTime != '' &&
            this.startAdgroupTime != '' &&
            this.startAdgroupRepeatTime != this.startAdgroupTime
          ) {
            this.startAdgroupRepeatTime = this.startAdgroupTime;
            if (this.islogAdgroupProcess == 1) {
              const data = {
                liveAdGroups: this.filteredAdgroupReportsCount ? this.filteredAdgroupReportsCount : 0,
                pausedAdGroups: this.disabledAdgroupReportsCount ? this.disabledAdgroupReportsCount : 0,
                deletedAdGroups: this.deletedAdgroupReportsCount ? this.deletedAdgroupReportsCount : 0,
                allAdGroups: this.allAdgroupReportsCount ? this.allAdgroupReportsCount : 0,
              };
              featuredPath = this.getLastPath(featuredPath);
              duration = this.calculateGreaterDuration(tabName);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const goalsStr = goalsEnabled ? ' with goals' : '';
              const logMessage = 'View results' + goalsStr + ' for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';
              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.adgroupLiveEndTime = '';
              this.adgroupPausedEndTime = '';
              this.adgroupDeletedEndTime = '';
              this.adgroupAllEndTime = '';
              this.adgroupChartEndTime = '';
              this.adgroupSummaryEndTime = '';
              this.islogAdgroupProcess = 0;
              return false;
            }
          }
          break;
        case 'keywords_tab':
          if (this.islogKeywordProcess == 0) return;
          if (opt == 'live') {
            this.keywordLiveEndTime = new Date().getTime();
          } else if (opt == 'paused') {
            this.keywordPausedEndTime = new Date().getTime();
          } else if (opt == 'deleted') {
            this.keywordDeletedEndTime = new Date().getTime();
          } else if (opt == 'all') {
            this.keywordAllEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.keywordChartEndTime = new Date().getTime();
          } else if (opt == 'summary') {
            this.keywordSummaryEndTime = new Date().getTime();
          }
          if (
            this.keywordLiveEndTime != '' &&
            this.keywordPausedEndTime != '' &&
            this.keywordDeletedEndTime != '' &&
            this.keywordAllEndTime != '' &&
            this.keywordChartEndTime != '' &&
            this.keywordSummaryEndTime != '' &&
            this.startKeywordTime != '' &&
            this.startKeywordRepeatTime != this.startKeywordTime
          ) {
            this.startKeywordRepeatTime = this.startKeywordTime;

            if (this.islogKeywordProcess == 1) {
              const data = {
                liveKeywords: this.filteredKeywordReportsCount ? this.filteredKeywordReportsCount : 0,
                pausedKeywords: this.disabledKeywordReportsCount ? this.disabledKeywordReportsCount : 0,
                deletedKeywords: this.deletedKeywordReportsCount ? this.deletedKeywordReportsCount : 0,
                allKeywords: this.allKeywordReportsCount ? this.allKeywordReportsCount : 0,
              };
              featuredPath = this.getLastPath(featuredPath);
              duration = this.calculateGreaterDuration(tabName);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const goalsStr = goalsEnabled ? ' with goals' : '';
              const logMessage = 'View results' + goalsStr + ' for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';
              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.keywordLiveEndTime = '';
              this.keywordPausedEndTime = '';
              this.keywordDeletedEndTime = '';
              this.keywordAllEndTime = '';
              this.keywordChartEndTime = '';
              this.keywordSummaryEndTime = '';
              this.islogKeywordProcess = 0;
              return false;
            }
          }
          break;
        case 'product_page_tab':
          if (this.islogAdProcess == 0) return;
          if (opt == 'live') {
            this.adsLiveEndTime = new Date().getTime();
          } else if (opt == 'paused') {
            this.adsPausedEndTime = new Date().getTime();
          } else if (opt == 'deleted') {
            this.adsDeletedEndTime = new Date().getTime();
          } else if (opt == 'all') {
            this.adsAllEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.adsChartEndTime = new Date().getTime();
          }

          if (
            this.adsLiveEndTime != '' &&
            this.adsPausedEndTime != '' &&
            this.adsDeletedEndTime != '' &&
            this.adsAllEndTime != '' &&
            this.adsChartEndTime != '' &&
            this.startAdTime != '' &&
            this.startAdRepeatTime != this.startAdTime
          ) {
            this.startAdRepeatTime = this.startAdTime;
            if (this.islogAdProcess == 1) {
              const data = {
                liveAds: this.productPageReportCount ? this.productPageReportCount : 0,
                pausedAds: this.adPauseReportCount ? this.adPauseReportCount : 0,
                deletedAds: this.adDeletedReportCount ? this.adDeletedReportCount : 0,
                allAds: this.adAllReportCount ? this.adAllReportCount : 0,
              };
              featuredPath = this.getLastPath(featuredPath);
              duration = this.calculateGreaterDuration(tabName);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const goalsStr = goalsEnabled ? ' with goals' : '';
              const logMessage = 'View results' + goalsStr + ' for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';

              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.adsLiveEndTime = '';
              this.adsPausedEndTime = '';
              this.adsDeletedEndTime = '';
              this.adsAllEndTime = '';
              this.adsChartEndTime = '';
              this.adsSummaryEndTime = '';
              this.islogAdProcess = 0;
              return false;
            }
          }
          break;
        case 'search_terms_tab':
          if (this.islogSearchTermProcess == 0) return;
          if (opt == 'searchTerm') {
            this.searchTermChartEndTime = new Date().getTime();
          } else if (opt == 'chart') {
            this.searchTermEndTime = new Date().getTime();
          }
          if (
            this.searchTermEndTime != '' &&
            this.searchTermChartEndTime != '' &&
            this.startSearchTermTime != '' &&
            this.startSearchTermRepeatTime != this.startSearchTermTime
          ) {
            this.startSearchTermRepeatTime = this.startSearchTermTime;
            if (this.islogSearchTermProcess == 1) {
              const data = {
                searchTermCount: this.productSearchTermCount ? this.productSearchTermCount : 0,
              };
              duration = this.calculateGreaterDuration(tabName);
              featuredPath = this.getLastPath(featuredPath);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const logMessage = 'View results for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';

              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.searchTermEndTime = '';
              this.searchTermChartEndTime = '';
              this.islogSearchTermProcess = 0;
              return false;
            }
          }
          break;
        case 'neg_keywords_tab':
          if (this.islogNegKeywordProcess == 0) return;
          if (opt == 'live') {
            this.negKeywordLiveEndTime = new Date().getTime();
          } else if (opt == 'paused') {
            this.negKeywordPausedEndTime = new Date().getTime();
          } else if (opt == 'deleted') {
            this.negKeywordDeletedEndTime = new Date().getTime();
          } else if (opt == 'all') {
            this.negKeywordAllEndTime = new Date().getTime();
          }
          if (
            this.negKeywordLiveEndTime != '' &&
            this.negKeywordPausedEndTime != '' &&
            this.negKeywordDeletedEndTime != '' &&
            this.negKeywordAllEndTime != '' &&
            this.startNegKeywordTime != '' &&
            this.startNegKeywordRepeatTime != this.startNegKeywordTime
          ) {
            this.startNegKeywordRepeatTime = this.startNegKeywordTime;
            if (selectedTab === 'Neg Keywords') {
              selectedTab = 'Negative Keywords';
            }
            if (this.islogNegKeywordProcess == 1) {
              const data = {
                liveNegativeKeywords: this.filteredNegKeywordReportsCount ? this.filteredNegKeywordReportsCount : 0,
                pausedNegativeKeywords: this.disabledNegKeywordReportsCount ? this.disabledNegKeywordReportsCount : 0,
                deletedNegativeKeywords: this.deletedNegKeywordReportsCount ? this.deletedNegKeywordReportsCount : 0,
                allNegativeKeywords: this.allNegKeywordReportsCount ? this.allNegKeywordReportsCount : 0,
              };
              featuredPath = this.getLastPath(featuredPath);
              duration = this.calculateGreaterDuration(tabName);
              const loadStatus = logType == 'failure' ? 'errors' : 'loaded';
              const goalsStr = goalsEnabled ? ' with goals' : '';
              const logMessage = 'View results' + goalsStr + ' for ' + selectedTab + ' ' + loadStatus + ' at ' + duration + 's';
              await logActivity(logType, logMessage, featuredName, featuredPath, data);
              this.negKeywordLiveEndTime = '';
              this.negKeywordPausedEndTime = '';
              this.negKeywordDeletedEndTime = '';
              this.negKeywordAllEndTime = '';
              this.islogNegKeywordProcess = 0;
              return false;
            }
          }
          break;
      }
    },

    openKBBU() {
      this.KBBUUpdating = false;
      this.KBBUPreview = [];
      this.KBBUFileContents = [];
      this.KBBUFile = null;
      this.keywordBulkBidUpdateModal = true;
    },
    clearKBBUPreview() {
      this.KBBUUpdating = false;
      this.KBBUPreview = [];
      this.KBBUFileContents = [];
      this.KBBUFile = null;
    },
    KBBUFileChanged: async function (f) {
      this.KBBUFileContents = [];
      this.KBBUUpdating = true;
      this.fullscreenLoader = this.$loading.show();
      var reader = new FileReader();
      let tempPreview = [];
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          keywordId: elem.keyword_id,
          keyword: elem.keyword || '',
          bidAmount: elem.new_bid_amount,
        }));
        if (result.some((e) => e.keywordId === undefined || e.keyword === undefined || e.bidAmount === undefined)) {
          this.KBBUFile = null;
          this.KBBUUpdating = false;
          this.fullscreenLoader.hide();
          this.$notify.error('Enter valid File. You can check the example sheet available');
          return;
        }
        this.KBBUFileContents = result && result.length > 0 ? result : [];
        if (this.KBBUFileContents.find((c) => c.bidAmount > 1000)) {
          this.KBBUFile = null;
          this.KBBUUpdating = false;
          this.fullscreenLoader.hide();
          this.$notify.error('One or more keywords have bid amount greater than 1000 dollars. Please keep it below 1000');
          return;
        }
        tempPreview = result && result.length > 0 ? result : [];
        setTimeout(() => {
          this.KBBUPreview = tempPreview;
        }, 100);
        this.KBBUFile = null;
        this.KBBUUpdating = false;
        this.fullscreenLoader.hide();
      };
      reader.readAsArrayBuffer(f);
    },
    updateKBBU: async function (e) {
      this.keywordBulkBidUpdateModal = false;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: PROCESS_KBBU,
          variables: {
            inputsKBBU: JSON.stringify(this.KBBUFileContents),
            at: Date.now(),
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          const data = res.data.processKBBU;
          if (data.successMessage === 'OK') {
            if (data.data && data.data != '') {
              const nonExistingIds = JSON.parse(data.data);
              this.$notify.success(
                'Keyword bid updates queued successfully. However, some keywords in your csv file do not exist in your account'
              );
            } else {
              this.$notify.success('Keyword bid updates queued successfully');
            }
            for (let index = 0; index < this.KBBUFileContents.length; index++) {
              const element = this.KBBUFileContents[index];
              const idx = this.keywordReport.row.findIndex((kr) => kr.keywordMetadata.id == element.keywordId);
              if (idx < 0) continue;
              this.keywordReport.row[idx].dirty = true;
              this.keywordReport.row[idx].recentBidUpdates = [
                {
                  keywordId: +element.keywordId,
                  bidFrom: this.keywordReport.row[idx].keywordMetadata.bidAmount.amount,
                  bidTo: element.bidAmount,
                  type: 1,
                  state: 0,
                },
              ];
              this.keywordReport.row[idx].keywordMetadata.bidAmount.amount = element.bidAmount;
            }
            this.$refs.livekeywordDatatable.goToFirstPage();
          } else {
            this.$notify.error('Unable to queue bid updates');
          }
        })
        .catch((error) => {
          console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to process request');
        });
    },
    openCampaignViewDialog() {
      if (this.isReadOnly()) {
        this.openPermissionReadOnlyPopup();
        return;
      }
      this.campaignViewMetadata = {
        now: Date.now(),
      };
    },
    openPermissionReadOnlyPopup() {
      this.permissionReadOnlyMetadata = {
        date: moment.now(),
      };
    },
    openSearchTabDialog(selectTab) {
      if (selectTab == 0) {
        this.searchResultMetadata = {
          now: Date.now(),
        };
      } else if (selectTab == 1) {
        this.searchTabMetadata = {
          now: Date.now(),
        };
      } else if (selectTab == 2) {
        this.todayTabMetadata = {
          now: Date.now(),
        };
      } else if (selectTab == 3) {
        this.productPageMetadata = {
          now: Date.now(),
        };
      }
    },
    validateInput() {
      let val = '';
      if (this.editMode == true) {
        val = this.customView.CustomView;
      } else {
        val = this.newCustomView;
      }

      if (/[<>;\\[\]{}&%]/g.test(val)) {
        if (this.editMode == true) {
          this.errorEditViewMessage = this.$t('createCampaignRules.specialCharNotAllowed');
          this.isEditInputValid = false;
        } else {
          this.errorViewMessage = this.$t('createCampaignRules.specialCharNotAllowed');
          this.isInputValid = false;
        }
      } else if (/\s\s/.test(val)) {
        if (this.editMode == true) {
          this.errorEditViewMessage = this.$t('createCampaignRules.onlySinglespaceAllowed');
          this.isEditInputValid = false;
        } else {
          this.errorViewMessage = this.$t('createCampaignRules.onlySinglespaceAllowed');
          this.isInputValid = false;
        }
      } else {
        if (this.editMode == true) {
          this.isEditInputValid = true;
          this.errorEditViewMessage = '';
        } else {
          this.isInputValid = true;
          this.errorViewMessage = '';
        }
      }
    },
    checkIfGoalAttrRequired() {
      const cv = LSS.customViews;
      if (cv && (this.selectedGoal != 0 || this.strategyId != 0)) {
        const pinnedCustomViewCampaign = cv.find((e) => e.tab == 'campaigns' && e.PinnedStatus == true);
        if (pinnedCustomViewCampaign) {
          const allcampaign = pinnedCustomViewCampaign?.DatatableDatasets?.allcampaign?.split(',');
          const livecampaign = pinnedCustomViewCampaign?.DatatableDatasets?.livecampaign?.split(',');
          const pausedcampaign = pinnedCustomViewCampaign?.DatatableDatasets?.pausedcampaign?.split(',');
          const deletedcampaign = pinnedCustomViewCampaign?.DatatableDatasets?.deletedcampaign?.split(',');
          const chartcampaign = pinnedCustomViewCampaign?.ChartDatasets?.split(',');
          if (allcampaign) {
            this.showGoalinCampaignAll = this.goalAttr.some((el) => allcampaign.includes(el));
          }
          if (livecampaign) {
            this.showGoalinCampaignLive = this.goalAttr.some((el) => livecampaign.includes(el));
          }
          if (pausedcampaign) {
            this.showGoalinCampaignPaused = this.goalAttr.some((el) => pausedcampaign.includes(el));
          }
          if (deletedcampaign) {
            this.showGoalinCampaignDeleted = this.goalAttr.some((el) => deletedcampaign.includes(el));
          }
          if (chartcampaign) {
            let isChartGoalRequired = false;
            isChartGoalRequired = this.chartGoalAttr.some((el) => chartcampaign.includes(el));
            this.showGoalinCampaignChart = isChartGoalRequired;
          }
        } else {
          const defaultViewCampaign = cv.find((e) => e.tab == 'campaigns' && e._id == '1');
          if (defaultViewCampaign) {
            const chartcampaign = defaultViewCampaign?.ChartDatasets?.split(',');
            if (chartcampaign) {
              let isChartGoalRequired = false;
              isChartGoalRequired = this.chartGoalAttr.some((el) => chartcampaign.includes(el));
              this.showGoalinCampaignChart = isChartGoalRequired;
            }
            const allcampaign = defaultViewCampaign?.DatatableDatasets?.allcampaign?.split(',');
            const livecampaign = defaultViewCampaign?.DatatableDatasets?.livecampaign?.split(',');
            const pausedcampaign = defaultViewCampaign?.DatatableDatasets?.pausedcampaign?.split(',');
            const deletedcampaign = defaultViewCampaign?.DatatableDatasets?.deletedcampaign?.split(',');
            if (allcampaign) {
              this.showGoalinCampaignAll = this.goalAttr.some((el) => allcampaign.includes(el));
            }
            if (livecampaign) {
              this.showGoalinCampaignLive = this.goalAttr.some((el) => livecampaign.includes(el));
            }
            if (pausedcampaign) {
              this.showGoalinCampaignPaused = this.goalAttr.some((el) => pausedcampaign.includes(el));
            }
            if (deletedcampaign) {
              this.showGoalinCampaignDeleted = this.goalAttr.some((el) => deletedcampaign.includes(el));
            }
          }
        }

        const pinnedCustomViewAdgroup = cv.find((e) => e.tab == 'adgroups' && e.PinnedStatus == true);
        if (pinnedCustomViewAdgroup) {
          const alladgroup = pinnedCustomViewAdgroup?.DatatableDatasets?.alladgroup?.split(',');
          const deletedadgroup = pinnedCustomViewAdgroup?.DatatableDatasets?.deletedadgroup?.split(',');
          const liveadgroup = pinnedCustomViewAdgroup?.DatatableDatasets?.liveadgroup?.split(',');
          const pausedadgroup = pinnedCustomViewAdgroup?.DatatableDatasets?.pausedadgroup?.split(',');
          const chartadgroup = pinnedCustomViewCampaign?.ChartDatasets?.split(',');
          if (alladgroup) {
            this.showGoalinAdgroupAll = this.goalAttr.some((el) => alladgroup.includes(el));
          }
          if (deletedadgroup) {
            this.showGoalinAdgroupDeleted = this.goalAttr.some((el) => deletedadgroup.includes(el));
          }
          if (liveadgroup) {
            this.showGoalinAdgroupLive = this.goalAttr.some((el) => liveadgroup.includes(el));
          }
          if (pausedadgroup) {
            this.showGoalinAdgroupPaused = this.goalAttr.some((el) => pausedadgroup.includes(el));
          }
          if (chartadgroup) {
            let isChartGoalRequired = false;
            isChartGoalRequired = this.chartGoalAttr.some((el) => chartadgroup.includes(el));
            this.showGoalinAdgroupChart = isChartGoalRequired;
          }
        } else {
          const defaultViewCampaign = cv.find((e) => e.tab == 'adgroups' && e._id == '1');
          if (defaultViewCampaign) {
            const chartcampaign = defaultViewCampaign?.ChartDatasets?.split(',');
            if (chartcampaign) {
              let isChartGoalRequired = false;
              isChartGoalRequired = this.chartGoalAttr.some((el) => chartcampaign.includes(el));
              this.showGoalinAdgroupChart = isChartGoalRequired;
            }
            const alladgroup = defaultViewCampaign?.DatatableDatasets?.alladgroup?.split(',');
            const deletedadgroup = defaultViewCampaign?.DatatableDatasets?.deletedadgroup?.split(',');
            const liveadgroup = defaultViewCampaign?.DatatableDatasets?.liveadgroup?.split(',');
            const pausedadgroup = defaultViewCampaign?.DatatableDatasets?.pausedadgroup?.split(',');
            if (alladgroup) {
              this.showGoalinAdgroupAll = this.goalAttr.some((el) => alladgroup.includes(el));
            }
            if (deletedadgroup) {
              this.showGoalinAdgroupDeleted = this.goalAttr.some((el) => deletedadgroup.includes(el));
            }
            if (liveadgroup) {
              this.showGoalinAdgroupLive = this.goalAttr.some((el) => liveadgroup.includes(el));
            }
            if (pausedadgroup) {
              this.showGoalinAdgroupPaused = this.goalAttr.some((el) => pausedadgroup.includes(el));
            }
          }
        }

        const pinnedCustomViewKeyword = cv.find((e) => e.tab == 'keywords' && e.PinnedStatus == true);
        if (pinnedCustomViewKeyword) {
          const allkeyword = pinnedCustomViewKeyword?.DatatableDatasets?.allkeyword?.split(',');
          const deletedkeyword = pinnedCustomViewKeyword?.DatatableDatasets?.deletedkeyword?.split(',');
          const livekeyword = pinnedCustomViewKeyword?.DatatableDatasets?.livekeyword?.split(',');
          const pausedkeyword = pinnedCustomViewKeyword?.DatatableDatasets?.pausedkeyword?.split(',');
          const chartkeyword = pinnedCustomViewCampaign?.ChartDatasets?.split(',');
          if (allkeyword) {
            this.showGoalinKeywordAll = this.goalAttr.some((el) => allkeyword.includes(el));
          }
          if (deletedkeyword) {
            this.showGoalinKeywordDeleted = this.goalAttr.some((el) => deletedkeyword.includes(el));
          }
          if (livekeyword) {
            this.showGoalinKeywordLive = this.goalAttr.some((el) => livekeyword.includes(el));
          }
          if (pausedkeyword) {
            this.showGoalinKeywordPaused = this.goalAttr.some((el) => pausedkeyword.includes(el));
          }
          if (chartkeyword) {
            let isChartGoalRequired = false;
            isChartGoalRequired = this.chartGoalAttr.some((el) => chartkeyword.includes(el));
            this.showGoalinKeywordChart = isChartGoalRequired;
          }
        } else {
          const defaultViewCampaign = cv.find((e) => e.tab == 'keywords' && e._id == '1');
          if (defaultViewCampaign) {
            const chartcampaign = defaultViewCampaign?.ChartDatasets?.split(',');
            if (chartcampaign) {
              let isChartGoalRequired = false;
              isChartGoalRequired = this.chartGoalAttr.some((el) => chartcampaign.includes(el));
              this.showGoalinKeywordChart = isChartGoalRequired;
            }
            const allkeyword = defaultViewCampaign?.DatatableDatasets?.allkeyword?.split(',');
            const deletedkeyword = defaultViewCampaign?.DatatableDatasets?.deletedkeyword?.split(',');
            const livekeyword = defaultViewCampaign?.DatatableDatasets?.livekeyword?.split(',');
            const pausedkeyword = defaultViewCampaign?.DatatableDatasets?.pausedkeyword?.split(',');
            if (allkeyword) {
              this.showGoalinKeywordAll = this.goalAttr.some((el) => allkeyword.includes(el));
            }
            if (deletedkeyword) {
              this.showGoalinKeywordDeleted = this.goalAttr.some((el) => deletedkeyword.includes(el));
            }
            if (livekeyword) {
              this.showGoalinKeywordLive = this.goalAttr.some((el) => livekeyword.includes(el));
            }
            if (pausedkeyword) {
              this.showGoalinKeywordPaused = this.goalAttr.some((el) => pausedkeyword.includes(el));
            }
          }
        }
        const pinnedCustomViewAd = cv.find((e) => e.tab == 'ads' && e.PinnedStatus == true);
        if (pinnedCustomViewAd) {
          const allads = pinnedCustomViewAd?.DatatableDatasets?.allads?.split(',');
          const deletedads = pinnedCustomViewAd?.DatatableDatasets?.deletedads?.split(',');
          const ads = pinnedCustomViewAd?.DatatableDatasets?.ads?.split(',');
          const pausedads = pinnedCustomViewAd?.DatatableDatasets?.pausedads?.split(',');
          const adchart = pinnedCustomViewCampaign?.ChartDatasets?.split(',');
          if (allads) {
            this.showGoalinAdAll = this.goalAttr.some((el) => allads.includes(el));
          }
          if (deletedads) {
            this.showGoalinAdDeleted = this.goalAttr.some((el) => deletedads.includes(el));
          }
          if (ads) {
            this.showGoalinAdLive = this.goalAttr.some((el) => ads.includes(el));
          }
          if (pausedads) {
            this.showGoalinAdPaused = this.goalAttr.some((el) => pausedads.includes(el));
          }
          if (adchart) {
            let isChartGoalRequired = false;
            isChartGoalRequired = this.chartGoalAttr.some((el) => adchart.includes(el));
            this.showGoalinAdChart = isChartGoalRequired;
          }
        } else {
          const defaultViewCampaign = cv.find((e) => e.tab == 'ads' && e._id == '1');
          if (defaultViewCampaign) {
            const chartcampaign = defaultViewCampaign?.ChartDatasets?.split(',');
            if (chartcampaign) {
              let isChartGoalRequired = false;
              isChartGoalRequired = this.chartGoalAttr.some((el) => chartcampaign.includes(el));
              this.showGoalinAdChart = isChartGoalRequired;
            }
            const allads = defaultViewCampaign?.DatatableDatasets?.allads?.split(',');
            const deletedads = defaultViewCampaign?.DatatableDatasets?.deletedads?.split(',');
            const ads = defaultViewCampaign?.DatatableDatasets?.ads?.split(',');
            const pausedads = defaultViewCampaign?.DatatableDatasets?.pausedads?.split(',');
            if (allads) {
              this.showGoalinAdAll = this.goalAttr.some((el) => allads.includes(el));
            }
            if (deletedads) {
              this.showGoalinAdDeleted = this.goalAttr.some((el) => deletedads.includes(el));
            }
            if (ads) {
              this.showGoalinAdLive = this.goalAttr.some((el) => ads.includes(el));
            }
            if (pausedads) {
              this.showGoalinAdPaused = this.goalAttr.some((el) => pausedads.includes(el));
            }
          }
        }
        const pinnedCustomViewSearchTerm = cv.find((e) => e.tab == 'searchterm' && e.PinnedStatus == true);
        if (pinnedCustomViewSearchTerm) {
          const searchterm = pinnedCustomViewSearchTerm?.DatatableDatasets?.searchterm?.split(',');
          const searchtermchart = pinnedCustomViewCampaign?.ChartDatasets?.split(',');
          if (searchterm) {
            this.showGoalinSearchTerm = this.goalAttr.some((el) => searchterm.includes(el));
          }
          if (searchtermchart) {
            let isChartGoalRequired = false;
            isChartGoalRequired = this.chartGoalAttr.some((el) => searchtermchart.includes(el));
            this.showGoalSearchtermChart = isChartGoalRequired;
          }
        } else {
          const defaultViewCampaign = cv.find((e) => e.tab == 'searchterm' && e._id == '1');
          if (defaultViewCampaign) {
            const chartcampaign = defaultViewCampaign?.ChartDatasets?.split(',');
            if (chartcampaign) {
              let isChartGoalRequired = false;
              isChartGoalRequired = this.chartGoalAttr.some((el) => chartcampaign.includes(el));
              this.showGoalSearchtermChart = isChartGoalRequired;
            }
            const searchterm = defaultViewCampaign?.DatatableDatasets?.searchterm?.split(',');
            if (searchterm) {
              this.showGoalinSearchTerm = this.goalAttr.some((el) => searchterm.includes(el));
            }
          }
        }
      } else {
        this.showGoalinCampaignAll = false;
        this.showGoalinCampaignLive = false;
        this.showGoalinCampaignPaused = false;
        this.showGoalinCampaignDeleted = false;
        this.showGoalinCampaignChart = false;

        this.showGoalinAdgroupAll = false;
        this.showGoalinAdgroupDeleted = false;
        this.showGoalinAdgroupLive = false;
        this.showGoalinAdgroupPaused = false;
        this.showGoalinAdgroupChart = false;

        this.showGoalinKeywordAll = false;
        this.showGoalinKeywordDeleted = false;
        this.showGoalinKeywordLive = false;
        this.showGoalinKeywordPaused = false;
        this.showGoalinKeywordChart = false;

        this.showGoalinAdAll = false;
        this.showGoalinAdDeleted = false;
        this.showGoalinAdLive = false;
        this.showGoalinAdPaused = false;
        this.showGoalinAdChart = false;

        this.showGoalinSearchTerm = false;
        this.showGoalSearchtermChart = false;
      }
    },

    checkIfGoalAttrRequiredInCharts() {
      if (LSS.ownApps && LSS.ownApps.length > 0) {
        const allApps = [...LSS.ownApps];
        const attrExistAll = allApps.filter((e) => e.attrExist);
        if (attrExistAll.length > 0 && (this.selectedGoal != 0 || this.strategyId != 0)) {
          this.goalsLength = attrExistAll.length;
          this.disableGoalFilter = false;
          this.showGoalinCampaignChart = true;
          this.showGoalinAdgroupChart = true;
          this.showGoalinKeywordChart = true;
          this.showGoalinAdChart = true;
          this.showGoalSearchtermChart = true;
        } else {
          this.showGoalinCampaignChart = false;
          this.showGoalinAdgroupChart = false;
          this.showGoalinKeywordChart = false;
          this.showGoalinAdChart = false;
          this.showGoalSearchtermChart = false;
        }
        if (attrExistAll.length === 0) {
          this.disableGoalFilter = true;
          this.selectedGoal = 0;
          this.goalsLength = 0;
        }
      }
    },
    numFormat(num) {
      return numberFormat(num);
    },
    numDecimalFormat(num) {
      return numberDecimal(num);
    },
    isReadOnly() {
      return isReadOnly();
    },

    changeCustomView(e) {
      const flattenedfilters = LSS.customViews.find((i) => i._id == e._id)?.Filters;
      const tab = LSS.customViews.find((i) => i._id == e._id)?.tab;
      const sortBy = LSS.customViews.find((i) => i._id == e._id)?.SortBy;
      const sortDesc = LSS.customViews.find((i) => i._id == e._id)?.SortDesc;

      if (flattenedfilters) {
        LSS.filters = JSON.parse(flattenedfilters);
      }
      this.selectedAccount = LSS.filters?.selectedAccount || 0;
      this.selectedApp = LSS.filters?.selectedApp || 0;
      this.selectedCampaign = LSS.filters?.selectedCampaign || 0;
      this.selectedAdGroup = LSS.filters?.selectedAdGroup || 0;
      this.selectedTargetingKeyword = LSS.filters?.selectedTargetingKeyword || 0;
      this.selectedGoal = LSS.filters?.selectedGoal || 0;
      this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource || 0;
      this.selectedAd = LSS.filters?.selectedAd || 0;

      if (tab) this.setSortInfo(tab, sortBy, sortDesc);
      this.applyFilters();
      this.applyGraphFilters();
    },
    setSortInfo(tab, sortBy, sortDesc) {
      let iSortBy, iSortDesc;
      if (sortBy) iSortBy = JSON.parse(sortBy);
      else {
        const { sortBy } = this.getSortInfo(tab);
        iSortBy = sortBy;
      }
      if (sortDesc) iSortDesc = JSON.parse(sortDesc);
      else {
        const { sortDesc } = this.getSortInfo(tab);
        iSortDesc = sortDesc;
      }
      switch (tab) {
        case 'accounts': {
          this.AccountSortBy = iSortBy.accounts;
          this.AccountSortDesc = iSortDesc.accounts;
          break;
        }
        case 'apps': {
          this.appsReportSortBy = iSortBy.apps;
          this.appsReportSortDesc = iSortDesc.apps;
          break;
        }
        case 'campaigns': {
          this.LiveCampaignSortBy = iSortBy.liveCampaign;
          this.LiveCampaignSortDesc = iSortDesc.liveCampaign;

          this.PausedCampaignSortBy = iSortBy.pausedCampaign;
          this.PausedCampaignSortDesc = iSortDesc.pausedCampaign;

          this.DeletedCampaignSortBy = iSortBy.deletedCampaign;
          this.DeletedCampaignSortDesc = iSortDesc.deletedCampaign;

          this.AllCampaignSortBy = iSortBy.allCampaign;
          this.AllCampaignSortDesc = iSortDesc.allCampaign;

          this.DraftCampaignSortBy = iSortBy.draftCampaign;
          this.DraftCampaignSortDesc = iSortDesc.draftCampaign;
          break;
        }
        case 'adgroups': {
          this.LiveAdGroupSortBy = iSortBy.liveAdgroups;
          this.LiveAdGroupSortDesc = iSortDesc.liveAdgroups;

          this.PausedAdGroupSortBy = iSortBy.pausedAdgroups;
          this.PausedAdGroupSortDesc = iSortDesc.pausedAdgroups;

          this.DeletedAdGroupSortBy = iSortBy.deletedAdgroups;
          this.DeletedAdGroupSortDesc = iSortDesc.deletedAdgroups;

          this.AllAdGroupSortBy = iSortBy.allAdgroups;
          this.AllAdGroupSortDesc = iSortDesc.allAdgroups;
          break;
        }
        case 'keywords': {
          this.LiveKeywordSortBy = iSortBy.liveKeywords;
          this.LiveKeywordsSortDesc = iSortDesc.liveKeywords;

          this.PausedKeywordSortBy = iSortBy.pausedKeywords;
          this.PausedKeywordsSortDesc = iSortDesc.pausedKeywords;

          this.DeletedKeywordSortBy = iSortBy.deletedKeywords;
          this.DeletedKeywordsSortDesc = iSortDesc.deletedKeywords;
          this.AllKeywordSortBy = iSortBy.allKeywords;
          this.AllKeywordsSortDesc = iSortDesc.allKeywords;
          break;
        }
        case 'ads': {
          this.LiveAdsSortBy = iSortBy.liveAds;
          this.LiveAdsSortDesc = iSortDesc.liveAds;

          this.PausedAdsSortBy = iSortBy.pausedAds;
          this.PausedAdsSortDesc = iSortDesc.pausedAds;

          this.DeletedAdsSortBy = iSortBy.deletedAds;
          this.DeletedAdsSortDesc = iSortDesc.deletedAds;

          this.AllAdsSortBy = iSortBy.deletedAds;
          this.AllAdsSortDesc = iSortDesc.deletedAds;
          break;
        }
        case 'searchterm': {
          this.searchTermsReportsSortBy = iSortBy.searchterm;
          this.searchTermsReportsSortDesc = iSortDesc.searchterm;
          break;
        }
        case 'negativekeywords': {
          this.LiveNegtiveKeySortBy = iSortBy.liveNegativekeywords;
          this.LiveNegtiveKeySortDesc = iSortDesc.liveNegativekeywords;

          this.PausedNegtiveKeySortBy = iSortBy.pausedNegativekeywords;
          this.PausedNegtiveKeySortDesc = iSortDesc.pausedNegativekeywords;

          this.DeletedNegtiveKeySortBy = iSortBy.allNegativekeywords;
          this.DeletedNegtiveKeySortDesc = iSortDesc.deletedNegativekeywords;

          this.AllNegtiveKeySortBy = iSortBy.allNegativekeywords;
          this.AllNegtiveKeySortDesc = iSortDesc.allNegativekeywords;
          break;
        }
      }
    },
    askConfirm($event, item, tab) {
      if (tab == 'campaign') {
        if (this.disableTogglePopup) {
          this.onCampaignStatusChange($event, item);
        } else {
          this.decisionAlertFlag = true;
          this.decisionAlertTitle = '';
          if ($event == 'PAUSED') this.decisionAlertMessage = 'Would you like to pause this campaign?';
          else this.decisionAlertMessage = 'Would you like to activate this campaign?';
          this.decisionAlertData = {
            from: 'adsmanager-toggle',
            now: Date.now(),
            event: $event,
            item: item,
            tab: tab,
          };
        }
      } else if (tab == 'adgroup') {
        if (this.disableTogglePopup) {
          this.onAdgroupStatusChange($event, item);
        } else {
          this.decisionAlertFlag = true;
          this.decisionAlertTitle = '';
          if ($event == 'PAUSED') this.decisionAlertMessage = 'Would you like to pause this adgroup?';
          else this.decisionAlertMessage = 'Would you like to activate this adgroup?';
          this.decisionAlertData = {
            from: 'adsmanager-toggle',
            now: Date.now(),
            event: $event,
            item: item,
            tab: tab,
          };
        }
      } else if (tab == 'keyword') {
        if (this.disableTogglePopup) {
          this.targetKeywordStatusChange($event, item);
        } else {
          this.decisionAlertFlag = true;
          this.decisionAlertTitle = '';
          if ($event == 'PAUSED') this.decisionAlertMessage = 'Would you like to pause this keyword?';
          else this.decisionAlertMessage = 'Would you like to activate this keyword?';
          this.decisionAlertData = {
            from: 'adsmanager-toggle',
            now: Date.now(),
            event: $event,
            item: item,
            tab: tab,
          };
        }
      }
    },
    decisionOkAction($event) {
      this.decisionAlertFlag = false;
      switch ($event.tab) {
        case 'campaign': {
          this.onCampaignStatusChange($event.event, $event.item);
          if ($event.checkbox) {
            LSS.disableTogglePopup = true;
          }
          return;
        }
        case 'adgroup': {
          this.onAdgroupStatusChange($event.event, $event.item);
          if ($event.checkbox) {
            LSS.disableTogglePopup = true;
          }
          return;
        }
        case 'keyword': {
          this.targetKeywordStatusChange($event.event, $event.item);
          if ($event.checkbox) {
            LSS.disableTogglePopup = true;
          }
          return;
        }
      }
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    fetchMoreNegativeKeywordsLive(pagination) {
      this.LiveNegtivekeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.LiveNegtivefetchLimit = pagination.itemsPerPage;
      this.LiveNegtiveKeySortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.LiveNegtiveKeySortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    fetchSearchNegativekeywordsLive(searchFilter) {
      // add search var here
      this.searchNegativeInputsLive = searchFilter;
      this.getNegKeyReportsLive();
    },
    getNegKeyReportsLive() {
      this.$apollo.queries.negativeKeywordReportsLive.refetch();
    },

    fetchMoreNegativeKeywordsPaused(pagination) {
      this.PausedNegtivekeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.PausedNegtivefetchLimit = pagination.itemsPerPage;
      this.PausedNegtiveKeySortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.PausedNegtiveKeySortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    fetchSearchNegativekeywordsPaused(searchFilter) {
      // add search var here
      this.searchNegativeInputsPaused = searchFilter;
      this.getMoreNegativeKeywordsPaused();
    },
    getMoreNegativeKeywordsPaused() {
      this.$apollo.queries.negativeKeywordReportsPaused.refetch();
    },

    fetchMoreNegativeKeywordsDeleted(pagination) {
      this.DeletedNegtivekeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.DeletedNegtivefetchLimit = pagination.itemsPerPage;
      this.DeletedNegtiveKeySortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.DeletedNegtiveKeySortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    fetchSearchNegativekeywordsDeleted(searchFilter) {
      // add search var here
      this.searchNegativeInputsDeleted = searchFilter;
      this.getMoreNegativeKeywordsDeleted();
    },
    getMoreNegativeKeywordsDeleted() {
      this.$apollo.queries.negativeKeywordReportsDeleted.refetch();
    },
    fetchMoreNegativeKeywordsAll(pagination) {
      this.AllNegtivekeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AllNegtivefetchLimit = pagination.itemsPerPage;
      this.AllNegtiveKeySortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.AllNegtiveKeySortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    fetchSearchNegativekeywordsAll(searchFilter) {
      // add search var here
      this.searchNegativeInputsAll = searchFilter;
      this.getMoreNegativeKeywordsAll();
    },
    getMoreNegativeKeywordsAll() {
      this.$apollo.queries.negativeKeywordReportsAll.refetch();
    },

    fetchMoreKeywordsLive(pagination) {
      this.LivekeywordskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.LivekeywordsfetchLimit = pagination.itemsPerPage;
      (this.LiveKeywordSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none'),
        (this.LiveKeywordsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false);
    },
    searchKeywordsLive(search) {
      // add variable for search
      this.searchKeywordsInputsLive = search;
      this.getMoreKeywordsLive();
    },
    getMoreKeywordsLive() {
      this.$apollo.queries.targetingKeywordReportsLive.refetch();
    },

    fetchMoreKeywordsPaused(pagination) {
      this.PausedkeywordskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.PausedkeywordsfetchLimit = pagination.itemsPerPage;
      (this.PausedKeywordSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none'),
        (this.PausedKeywordsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false);
    },
    searchKeywordsPaused(search) {
      // add variable here for search
      this.searchKeywordsInputsPaused = search;
      this.getKeywordPausedReports();
    },
    getKeywordPausedReports() {
      this.$apollo.queries.targetingKeywordReportsPaused.refetch();
    },

    fetchMoreKeywordsDeleted(pagination) {
      this.DeletedkeywordskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.DeletedkeywordsfetchLimit = pagination.itemsPerPage;
      (this.DeletedKeywordSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none'),
        (this.DeletedKeywordsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false);
    },
    searchKeywordsDeleted(search) {
      // add variable for search here
      this.searchKeywordsInputsDeleted = search;
      this.getKeywordsDeletedReports();
    },
    getKeywordsDeletedReports() {
      this.$apollo.queries.targetingKeywordReportsDeleted.refetch();
    },

    fetchMoreKeywordsAll(pagination) {
      this.AllkeywordskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AllkeywordsfetchLimit = pagination.itemsPerPage;
      (this.AllKeywordSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none'),
        (this.AllKeywordsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false);
    },
    searchKeywordsAll(search) {
      // add variable for search here
      this.searchKeywordsInputsAll = search;
      this.getKeywordsAllReports();
    },
    getKeywordsAllReports() {
      this.$apollo.queries.targetingKeywordReportsAll.refetch();
    },

    fetchRowsPerPageAds(pagination) {
      this.LivekeAdskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.LivekeAdsfetchLimit = pagination.itemsPerPage;
      this.LiveAdsSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.LiveAdsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchLiveAds(search) {
      // add search var here
      this.searchAdsInputsLive = search;
      this.getLiveAds();
    },
    getLiveAds() {
      this.$apollo.queries.productPageReportsLive.refetch();
    },

    // product  Page  Reports  Paused

    fetchRowsAdsPaused(pagination) {
      this.PausedAdskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.PausedAdsfetchLimit = pagination.itemsPerPage;
      this.PausedAdsSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.PausedAdsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchPausedAds(search) {
      // add searchvar here
      this.searchAdsInputsPaused = search;
      this.getAdsPausedReports();
    },
    getAdsPausedReports() {
      this.$apollo.queries.productPageReportsPaused.refetch();
    },

    // product  Page  Reports  Deleted

    fetchRowsAdDeleted(pagination) {
      this.DeletedAdskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.DeletedAdsfetchLimit = pagination.itemsPerPage;
      this.DeletedAdsSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.DeletedAdsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchDeletedAds(search) {
      // add search var here
      this.searchAdsInputsDeleted = search;
      this.getAdsDeletedReports();
    },
    getAdsDeletedReports() {
      this.$apollo.queries.productPageReportsDeleted.refetch();
    },
    fetchRowsAdAll(pagination) {
      this.AllAdskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AllAdsfetchLimit = pagination.itemsPerPage;
      this.AllAdsSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.AllAdsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchAllAds(search) {
      // add search var here
      this.searchAdsInputsAll = search;
      this.getAdsAllReports();
    },
    getAdsAllReports() {
      this.$apollo.queries.productPageReportsAll.refetch();
    },

    calculateAdsTotalSummary() {
      let arr = [];
      if (this.productPageReport && this.productPageReport.grandTotals && this.productPageReport.grandTotals.total) {
        arr.push(this.productPageReport.grandTotals.total);
      }
      if (this.adPauseReport && this.adPauseReport.grandTotals && this.adPauseReport.grandTotals.total) {
        arr.push(this.adPauseReport.grandTotals.total);
      }
      if (this.adDeletedReport && this.adDeletedReport.grandTotals && this.adDeletedReport.grandTotals.total) {
        arr.push(this.adDeletedReport.grandTotals.total);
      }

      const deepMergeSum = (obj1, obj2) => {
        return Object.keys(obj1).reduce((acc, key) => {
          if (typeof obj2[key] === 'object') {
            acc[key] = deepMergeSum(obj1[key], obj2[key]);
          } else if (obj2.hasOwnProperty(key) && !isNaN(parseFloat(obj2[key]))) {
            acc[key] = parseFloat(obj1[key]) + parseFloat(obj2[key]);
          }
          return acc;
        }, {});
      };

      if (arr.length) {
        const result = arr.reduce((acc, obj) => (acc = deepMergeSum(acc, obj)));
        this.adsTotalSummary = result;
      }
    },

    fetchMoreSearchTerms(pagination) {
      this.SearchTermskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.SearchTermsfetchLimit = pagination.itemsPerPage;
      this.searchTermsReportsSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.searchTermsReportsSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    fetchSearchTerms(searchFilter) {
      this.searchTermsSearchInputs = searchFilter;
      this.getMoreSearchTerms();
    },
    getMoreSearchTerms() {
      this.$apollo.queries.searchTermsReports.refetch();
    },

    fetchMoreApps(pagination) {
      this.AppskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AppsfetchLimit = pagination.itemsPerPage;
      this.appsReportSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.appsReportSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    fetchSearchApps(search) {
      // add search variable here
      this.searchAppsInputs = search;
      this.getAppsReports();
    },
    getAppsReports() {
      this.$apollo.queries.appsReports.refetch();
    },

    fetchRowsPerPageAccounts(pagination) {
      this.AccountskeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AccountfetchLimit = pagination.itemsPerPage;
      this.AccountSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.AccountSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    getAccountReports() {
      this.$apollo.queries.accountsReports.refetch();
    },
    fetchSearchAccounts(searchFilter) {
      this.searchAccountInputs = searchFilter;
      this.getAccountReports();
    },

    fetchMoreCampaignsLive(pagination) {
      this.LiveCampaignkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.LiveCampaignfetchLimit = pagination.itemsPerPage;
      this.LiveCampaignSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.LiveCampaignSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchCampaignsLive(search) {
      // set new variable here
      this.searchCampaignInputsLive = search;
      this.getMoreCampaignnsLive();
    },
    getMoreCampaignnsLive() {
      this.$apollo.queries.campaignReportsLive.refetch();
    },
    fetchMoreCampaignsPaused(pagination) {
      this.PausedCampaignkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.PausedCampaignfetchLimit = pagination.itemsPerPage;
      this.PausedCampaignSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.PausedCampaignSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchCampaignsPaused(search) {
      // add paused search var here and assign search
      this.searchCampaignInputsPaused = search;
      this.getMoreCampaignnsPaused();
    },
    getMoreCampaignnsPaused() {
      this.$apollo.queries.campaignReportsPaused.refetch();
    },
    fetchMoreCampaignsDeleted(pagination) {
      this.DeletedCampaignkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.DeletedCampaignfetchLimit = pagination.itemsPerPage;
      this.DeletedCampaignSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.DeletedCampaignSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchCampaignsDeleted(search) {
      // add variable for deleted
      this.searchCampaignInputsDeleted = search;
      this.getMoreCampaignsDeleted();
    },

    getMoreCampaignsDeleted() {
      this.$apollo.queries.campaignReportsDeleted.refetch();
    },
    fetchMoreCampaignsAll(pagination) {
      this.AllCampaignkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AllCampaignfetchLimit = pagination.itemsPerPage;
      this.AllCampaignSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.AllCampaignSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchCampaignsAll(search) {
      // add variable for deleted
      this.searchCampaignInputsAll = search;
      this.getMoreCampaignsAll();
    },
    getMoreCampaignsAll() {
      this.$apollo.queries.campaignReportsAll.refetch();
    },
    fetchMoreAdgroupsLive(pagination) {
      this.LiveAdGroupkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.LiveAdGroupfetchLimit = pagination.itemsPerPage;
      this.LiveAdGroupSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.LiveAdGroupSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchAdgroupsLive(searchAdGroupLive) {
      this.searchAdgroupInputsLive = searchAdGroupLive;
      this.getMoreAdgroupsLive();
    },
    getMoreAdgroupsLive() {
      this.$apollo.queries.adGroupReportsLive.refetch();
    },
    fetchMoreAdgroupsPaused(pagination) {
      this.PausedAdGroupkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.PausedAdGroupfetchLimit = pagination.itemsPerPage;
      this.PausedAdGroupSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.PausedAdGroupSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchAdgroupsPaused(searchAdGroupPaused) {
      // add seach var here
      this.searchAdgroupInputsPaused = searchAdGroupPaused;
      this.getMoreAdgroupsPaused();
    },
    getMoreAdgroupsPaused() {
      this.$apollo.queries.adGroupReportsPaused.refetch();
    },
    fetchMoreAdgroupsDeleted(pagination) {
      this.DeletedAdGroupkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.DeletedAdGroupfetchLimit = pagination.itemsPerPage;
      this.DeletedAdGroupSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.DeletedAdGroupSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchAdgroupsDeleted(searchDeleteAdGroup) {
      // add custom var here
      this.searchAdGroupInputsDeleted = searchDeleteAdGroup;
      this.getMoreAdgroupsDeleted();
    },
    getMoreAdgroupsDeleted() {
      this.$apollo.queries.adGroupReportsDeleted.refetch();
    },
    fetchMoreAdgroupsAll(pagination) {
      this.AllAdGroupkeyOffset = pagination.itemsPerPage * (pagination.page - 1);
      this.AllAdGroupfetchLimit = pagination.itemsPerPage;
      this.AllAdGroupSortBy = pagination.sortBy[0] ? pagination.sortBy[0] : 'none';
      this.AllAdGroupSortDesc = pagination.sortDesc[0] ? pagination.sortDesc[0] : false;
    },
    searchAdgroupsAll(searchAllAdGroup) {
      // add custom var here
      this.searchAdGroupInputsAll = searchAllAdGroup;
      this.getMoreAdgroupsAll();
    },
    getMoreAdgroupsAll() {
      this.$apollo.queries.adGroupReportsAll.refetch();
    },
    safeDivision(dividend, divisor, isRate?) {
      const result = divisor ? dividend / divisor : 0;
      const returnIt = isRate ? result * 100 : result;
      return Number(returnIt.toFixed(2));
    },
    openPermissionPopup() {
      this.permissionMetadata = {
        date: moment.now(),
      };
    },
    openPermissionReadOnlyPopup() {
      this.permissionReadOnlyMetadata = {
        date: moment.now(),
      };
    },
    cancelEditView() {
      this.editMode = false;
      this.errorEditViewMessage = '';
      this.isEditInputValid = false;
      const customViews = LSS.customViews;
      const customView = customViews.find((o) => o._id == this.customView._id);
      this.customView.CustomView = customView.CustomView;
    },
    getSortInfo(tab) {
      switch (tab) {
        case 'accounts': {
          const sortBy = {
            accounts: this.AccountSortBy,
          };
          const sortDesc = {
            accounts: this.AccountSortDesc,
          };
          return { sortBy, sortDesc };
          break;
        }
        case 'apps': {
          const sortBy = {
            apps: this.appsReportSortBy,
          };
          const sortDesc = {
            apps: this.appsReportSortDesc,
          };
          return { sortBy, sortDesc };
          break;
        }
        case 'campaigns': {
          const sortBy = {
            liveCampaign: this.LiveCampaignSortBy,
            pausedCampaign: this.PausedCampaignSortBy,
            deletedCampaign: this.DeletedCampaignSortBy,
            allCampaign: this.AllCampaignSortBy,
            draftCampaign: this.DraftCampaignSortBy,
          };
          const sortDesc = {
            liveCampaign: this.LiveCampaignSortDesc,
            pausedCampaign: this.PausedCampaignSortDesc,
            deletedCampaign: this.DeletedCampaignSortDesc,
            allCampaign: this.AllCampaignSortDesc,
            draftCampaign: this.DraftCampaignSortDesc,
          };

          return { sortBy, sortDesc };
          break;
        }
        case 'adgroups': {
          const sortBy = {
            liveAdgroups: this.LiveAdGroupSortBy,
            pausedAdgroups: this.PausedAdGroupSortBy,
            deletedAdgroups: this.DeletedAdGroupSortBy,
            allAdgroups: this.AllAdGroupSortBy,
          };
          const sortDesc = {
            liveAdgroups: this.LiveAdGroupSortDesc,
            pausedAdgroups: this.PausedAdGroupSortDesc,
            deletedAdgroups: this.DeletedAdGroupSortDesc,
            allAdgroups: this.AllAdGroupSortDesc,
          };

          return { sortBy, sortDesc };
          break;
        }
        case 'keywords': {
          const sortBy = {
            liveKeywords: this.LiveKeywordSortBy,
            pausedKeywords: this.PausedKeywordSortBy,
            deletedKeywords: this.DeletedKeywordSortBy,
            allKeywords: this.AllKeywordSortBy,
          };
          const sortDesc = {
            liveKeywords: this.LiveKeywordsSortDesc,
            pausedKeywords: this.PausedKeywordsSortDesc,
            deletedKeywords: this.DeletedKeywordsSortDesc,
            allKeywords: this.AllKeywordsSortDesc,
          };

          return { sortBy, sortDesc };
          break;
        }
        case 'ads': {
          const sortBy = {
            liveAds: this.LiveAdsSortBy,
            pausedAds: this.PausedAdsSortBy,
            deletedAds: this.DeletedAdsSortBy,
            allAds: this.AllAdsSortBy,
          };
          const sortDesc = {
            liveAds: this.LiveAdsSortDesc,
            pausedAds: this.PausedAdsSortDesc,
            deletedAds: this.DeletedAdsSortDesc,
            allAds: this.AllAdsSortDesc,
          };

          return { sortBy, sortDesc };
          break;
        }
        case 'searchterm': {
          const sortBy = {
            searchterm: this.searchTermsReportsSortBy,
          };
          const sortDesc = {
            searchterm: this.searchTermsReportsSortDesc,
          };

          return { sortBy, sortDesc };
          break;
        }
        case 'negativekeywords': {
          const sortBy = {
            liveNegativekeywords: this.LiveNegtiveKeySortBy,
            pausedNegativekeywords: this.PausedNegtiveKeySortBy,
            deletedNegativekeywords: this.DeletedNegtiveKeySortBy,
            allNegativekeywords: this.AllNegtiveKeySortBy,
          };
          const sortDesc = {
            liveNegativekeywords: this.LiveNegtiveKeySortDesc,
            pausedNegativekeywords: this.PausedNegtiveKeySortDesc,
            deletedNegativekeywords: this.DeletedNegtiveKeySortDesc,
            allNegativekeywords: this.AllNegtiveKeySortDesc,
          };

          return { sortBy, sortDesc };
          break;
        }
      }
    },
    saveCustomView(tab) {
      const { sortBy, sortDesc } = this.getSortInfo(tab);
      const customViews = LSS.customViews;
      let result = customViews.find((o) => o._id == this.customView._id);
      if (!result) result = customViews.find((o) => o._id == '1' && o.tab == tab);
      if (!result) {
        result = {
          tab: tab,
          ChartDatasets: '',
          DatatableDatasets: '',
          granularity: DEFAULT_GRANULARITY,
        };
      }
      let payload = {
        _id: random().toString(),
        customView: this.newCustomView,
        tab: result.tab,
        datasets: result.ChartDatasets ? result.ChartDatasets.toString() : '',
        datatable: result.DatatableDatasets ? result.DatatableDatasets.toString() : '',
        granularity: result.Granularity ? result.Granularity : DEFAULT_GRANULARITY,
        filters: LSS.filters ? JSON.stringify(LSS.filters) : '',
        sortBy: JSON.stringify(sortBy),
        sortDesc: JSON.stringify(sortDesc),
      };
      if (typeof result.DatatableDatasets === 'object') {
        payload.datatable = JSON.stringify(result.DatatableDatasets);
      }
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .mutate({
          mutation: INSERT_CUSTOM_VIEW,
          variables: payload,
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          this.showViewForm = false;
          this.$notify.success('Custom View Saved');
          this.$apollo
            .query({
              query: FETCH_CUSTOM_PRESETS,
            })
            .then((info) => {
              this.newCustomView = '';
              for (const data of info.data.fetchCustomView) {
                try {
                  data.DatatableDatasets = JSON.parse(data.DatatableDatasets);
                } catch (e) {}
              }

              const getCurrentTempViews = LSS.customViews.filter((o) => o.CustomView == 'TempView' && o.tab == tab);
              for (let key of getCurrentTempViews) {
                key.DatatableDatasets = '';
                key.ChartDatasets = '';
              }
              LSS.customViews = [...info.data.fetchCustomView, ...getCurrentTempViews];
              this.setCustomViewDropDown(tab);
              const cv = LSS.customViews.find((o) => o._id == res?.data?.insertCustomView?.data);
              this.customView = { _id: cv._id, CustomView: cv.CustomView };
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Something went wrong, pleas try again');
        });
    },

    deleteCustomView(viewId, tab) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .mutate({
          mutation: DELETE_CUSTOM_VIEW,
          variables: {
            _id: viewId,
          },
        })
        .then((res) => {
          this.editMode = false;
          this.fullscreenLoader.hide();
          this.$notify.success('Custom View Deleted');
          LSS.customViews = LSS.customViews.filter((o) => o._id !== viewId);
          this.setCustomViewDropDown(tab);
          this.customView = { _id: '0', CustomView: 'Default View' };
          this.showTextField = false;
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Something went wrong, pleas try again');
        });
    },

    updateCustomView(tab) {
      const customViews = [...LSS.customViews];
      let result = customViews.find((o) => o._id == this.customView._id);
      this.showViewForm = false;
      const { sortBy, sortDesc } = this.getSortInfo(result.tab);
      if (!result) {
        this.$notify.error('View Name cannot be Empty');
        return;
      }
      if (!this.customView.CustomView) {
        this.$notify.error('View Name cannot be Empty');
        return;
      }
      let payload = {
        _id: result._id,
        customView: this.customView.CustomView,
        tab: result.tab,
        datasets: result.ChartDatasets ? result.ChartDatasets.toString() : '',
        datatable: result.DatatableDatasets ? result.DatatableDatasets.toString() : '',
        granularity: result.Granularity ? result.Granularity : DEFAULT_GRANULARITY,
        filters: LSS.filters ? JSON.stringify(LSS.filters) : '',
        sortBy: JSON.stringify(sortBy),
        sortDesc: JSON.stringify(sortDesc),
      };
      if (typeof result.DatatableDatasets === 'object') {
        payload.datatable = JSON.stringify(result.DatatableDatasets);
      }
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .mutate({
          mutation: SAVE_CUSTOM_VIEW,
          variables: payload,
        })
        .then((res) => {
          this.editMode = false;
          this.fullscreenLoader.hide();
          this.$notify.success('Custom View Updated');
          this.fetchCustomViews();
          const objIndex = customViews.findIndex((obj) => obj._id === this.customView._id);
          customViews[objIndex].CustomView = this.customView.CustomView;
          customViews[objIndex].Filters = LSS.filters ? JSON.stringify(LSS.filters) : '';
          customViews[objIndex].SortBy = JSON.stringify(sortBy);
          customViews[objIndex].SortDesc = JSON.stringify(sortDesc);

          LSS.customViews = customViews;
          this.showTextField = false;
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Something went wrong, pleas try again');
        });
    },

    // function Pinne status
    pinnedCustomView(PinnedStatus) {
      const customViews = [...LSS.customViews];
      let result = customViews.find((o) => o._id == this.customView._id);

      if (!result) {
        this.$notify.error('View Name cannot be Empty');
        return;
      }
      if (!this.customView.CustomView) {
        this.$notify.error('View Name cannot be Empty');
        return;
      }
      let payload = {
        _id: result._id,
        customView: this.customView.CustomView,
        tab: result.tab,
        PinnedStatus: PinnedStatus,
      };
      if (typeof result.DatatableDatasets === 'object') {
        payload.datatable = JSON.stringify(result.DatatableDatasets);
      }
      this.$apollo
        .mutate({
          mutation: PINNED_CUSTOM_VIEW,
          variables: payload,
        })
        .then((res) => {
          if (PinnedStatus == false) {
            this.$notify.success('Custom View UnPinned');
            const objIndex = customViews.findIndex((obj) => obj._id === this.customView._id);
            customViews[objIndex].PinnedStatus = PinnedStatus;
            LSS.customViews = customViews;
            this.setCustomUnPinnedViewDropDown(result.tab);
          } else {
            this.$notify.success('Custom View Pinned');
            const objIndex = customViews.findIndex((obj) => obj._id === this.customView._id);
            customViews[objIndex].PinnedStatus = PinnedStatus;
            LSS.customViews = customViews;
            this.setCustomPinnedViewDropDown(PinnedStatus, result.tab, this.customView._id);
          }
        })
        .catch((error) => {
          this.$notify.error('Something went wrong, pleas try again');
        });
    },
    openViewForm() {
      this.isInputValid = false;
      this.errorViewMessage = '';
      this.showViewForm = true;
    },
    calcGoalsRevenue(item, field) {
      return numberDecimal(Number(item[field]?.content?.revenue?.total || 0));
    },
    calcGoalRoas(item, field) {
      const amount = String(item.total?.localSpend?.amount || 0)
      if (!Number(amount || 0)) return '0.00';
      const rev = String(item[field]?.content?.revenue?.total || 0)
      return numberDecimal(Number(rev || 0) / Number(amount));
    },
    calcEventCount(item, field) {
      return numberFormat(Number(item[field]?.content?.eventCount?.count || 0));
    },

    calcEventCountSec(item, field) {
      return numberFormat(Number(item[field]?.secondaryContent?.eventCount?.count || 0));
    },

    calcCostPerGoalSec(item, field) {
      const amount = String(item.total?.localSpend?.amount || 0)
      if (!Number(item[field]?.secondaryContent?.eventCount?.count || 0)) return '0.00';
      return numberDecimal(Number(amount) / Number(item[field]?.secondaryContent?.eventCount?.count));
    },

    calcCostPerGoal(item, field) {
      const amount = String(item.total?.localSpend?.amount || 0)
      if (!Number(item[field]?.content?.eventCount?.count || 0)) return '0.00';
      return numberDecimal(Number(amount) / Number(item[field]?.content?.eventCount?.count));
    },

    calcRevenuePerGoal(item, field) {
      if (!Number(item[field]?.content?.eventCount?.count || 0)) return '0.00';
      const rev = String(item[field]?.content?.revenue?.total || 0)
      return numberDecimal(Number(rev || 0) / Number(item[field]?.content?.eventCount?.count));
    },

    calcARPU(item, field) {
      const tapInstalls = String(item.total?.tapInstalls || 0)
        .split(',')
        .join('');
      if (!Number(tapInstalls || 0)) return '0.00';
      const rev = String(item[field]?.content?.revenue?.total || 0)
      return numberDecimal(Number(rev || 0) / Number(tapInstalls));
    },

    calcAMPU(item, field) {
      const tapInstalls = String(item.total?.tapInstalls || 0)
        .split(',')
        .join('');
      const amount = String(item.total?.localSpend?.amount || 0)
      if (!Number(tapInstalls || 0)) return '0.00';
      const rev = String(item[field]?.content?.revenue?.total || 0)
      const goalRoas = String(numberDecimal(Number(rev || 0) / Number(amount)))
      return numberDecimal(Number(goalRoas || 0) / Number(tapInstalls));
    },

    appListing(data) {
      for (let j = 0; j < data.appsReportsForFilter.row.length; j++) {
        if (LSS.ownApps && LSS.ownApps.length > 0) {
          for (let index = 0; index < LSS.ownApps.length; index++) {
            const element = LSS.ownApps[index];
            if (element.adamId == data.appsReportsForFilter.row[j].metadata.app.adamId) {
              data.appsReportsForFilter.row[j].metadata.app.appIcon = element.appIcon;
            }
          }
        }
      }
      this.appFilterData = data.appsReportsForFilter;
    },
    toIntegrations() {
      this.$router.push('integrations');
    },
    async onCampaignStatusChange(value, item) {
      this.fullscreenLoader = this.$loading.show();
      item.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: TOGGLE_CAMPAIGNS,
          variables: {
            orgId: item.campaignMetadata.orgId,
            campaignId: item.campaignMetadata.id,
            campaignStatus: { status: value },
          },
        });

        if (result.data.enableDisableCampaign.successMessage) {
          if (value == 'PAUSED') this.$notify.success(this.$t('successMsg.campaignMovedMsgPaused'));
          if (value == 'ENABLED') this.$notify.success(this.$t('successMsg.campaignMovedMsgLive'));
          this.onUpdate();
        } else {
          item.campaignMetadata.status = value === 'ENABLED' ? 'PAUSED' : 'ENABLED';
          this.$notify.error(this.$t('successMsg.campaignMoveFailMsg'));
          this.onUpdate();
        }
        item.loading = false;
        this.fullscreenLoader.hide();
      } catch (error) {
        item.campaignMetadata.status = value === 'ENABLED' ? 'PAUSED' : 'ENABLED';
        item.loading = false;
        this.fullscreenLoader.hide();
      }
    },
    searchdialog1(item) {
      localStorage.setItem(LS_DRAFT_KEY, item._id);
      this.searchResultMetadata = {
        draftId: item._id,
      };
    },
    openDeleteCampaignDialog({ campaignMetadata }) {
      this.campaignDeletionMetadata = {
        campaignId: campaignMetadata.id,
        orgId: campaignMetadata.orgId,
        now: Date.now(),
      };
    },
    openDeleteAdgroupDialog({ adgroupMetadata }) {
      this.adgroupDeletionMetadata = {
        adgroupId: adgroupMetadata.id,
        campaignId: adgroupMetadata.campaignId,
        orgId: adgroupMetadata.orgId,
        now: Date.now(),
      };
    },

    openDeleteKeywordDialog({ keywordMetadata, adgroupMetadata }) {
      this.keywordDeletionMetadata = {
        adgroupId: keywordMetadata.adGroupId,
        campaignId: adgroupMetadata.campaignId,
        keywordId: keywordMetadata.id,
        orgId: adgroupMetadata.orgId,
        now: Date.now(),
      };
    },

    onUpdate() {
      this.refetchData();
    },
    onCampaignUpdate() {
      switch (this.campaignListingTab) {
        case 0: {
          this.$apollo.queries.campaignReportsLive.refetch();
          break;
        }
        case 1: {
          this.$apollo.queries.campaignReportsPaused.refetch();
          break;
        }
        case 2: {
          this.$apollo.queries.campaignReportsDeleted.refetch();
          break;
        }
        case 4: {
          this.$apollo.queries.campaignReportsAll.refetch();
          break;
        }
      }
    },
    openEditCampaignWizard(data) {
      const metadata = data.campaignMetadata;
      const reportMetadata = data.metadata;
      const campaignDetails = {
        orgId: metadata.orgId,
        campaignId: metadata.id,
        name: metadata.name,
        countriesOrRegions: metadata.countriesOrRegions,
        app: reportMetadata.app,
        budgetAmount: metadata.budgetAmount,
        dailyBudgetAmount: metadata.dailyBudgetAmount,
        now: Date.now(),
      };
      this.campaignMetadata = campaignDetails;
    },

    openSelectAdgroupForKeywords(data) {
      this.selectAdgroupForKeywords = {
        selectedAdgroup: data,
        context: AdGroupSelectorContext.KEYWORD,
        now: Date.now(),
      };
    },

    openAddNegativeKeyword() {
      this.openAddNegativeKeywords = {
        now: Date.now(),
      };
    },

    openDeleteNegKeyDialog(negKeyData) {
      this.negKeyDeletionData = {
        campaignId: negKeyData.campaignId,
        adgroupId: negKeyData.adGroupId,
        keywordId: negKeyData.id,
        now: Date.now(),
      };
    },
    openDeleteAdDialog({ adsMetadata }) {
      this.adsDeletionMetadata = {
        id: adsMetadata.id,
        adgroupId: adsMetadata.adGroupId,
        campaignId: adsMetadata.campaignId,
        orgId: adsMetadata.orgId,
        now: Date.now(),
      };
    },
    openEditAdWizard({ adsMetadata }) {
      const adsDetails = {
        orgId: adsMetadata.orgId,
        campaignId: adsMetadata.campaignId,
        id: adsMetadata.id,
        name: adsMetadata.name,
        adgroupId: adsMetadata.adGroupId,
        creativeId: adsMetadata.creativeId,
        now: Date.now(),
      };
      this.adsMetadata = adsDetails;
    },

    onDeletingNegKey() {
      this.refetchData();
    },

    openSelectAdgroupForCs() {
      this.selectAdgroupForCs = {
        context: AdGroupSelectorContext.CREATIVESET,
        now: Date.now(),
      };
    },

    openAddAdGroup() {
      this.openAddAdgroup = {
        now: Date.now(),
      };
    },

    openEditBidAmount(item) {
      const metadata = {
        orgId: item.adgroupMetadata.orgId,
        keywordId: item.keywordMetadata.id,
        campaignId: item.adgroupMetadata.campaignId,
        adGroupId: item.keywordMetadata.adGroupId,
        bidAmount: item.keywordMetadata.bidAmount,
        now: Date.now(),
      };
      this.keywordBidData = metadata;
    },

    async onAdgroupStatusChange(value, item) {
      item.loading = true;
      this.fullscreenLoader = this.$loading.show();
      try {
        const result = await this.$apollo.mutate({
          mutation: TOGGLE_ADGROUP,
          variables: {
            orgId: item.adgroupMetadata.orgId,
            campaignId: item.adgroupMetadata.campaignId,
            adgroupId: item.adgroupMetadata.id,
            adGroupStatus: { status: value },
          },
        });

        if (result.data.enableDisableAdGroup.successMessage) {
          if (value == 'PAUSED') this.$notify.success(this.$t('successMsg.addgroupUpdatedMsgPaused'));
          if (value == 'ENABLED') this.$notify.success(this.$t('successMsg.addgroupUpdatedMsgLive'));
          this.onUpdate();
        } else {
          item.adgroupMetadata.status = value === 'ENABLED' ? 'PAUSED' : 'ENABLED';
          this.$notify.error(this.$t('successMsg.adgroupUpdateFailMsg'));
          this.onUpdate();
        }
        this.fullscreenLoader.hide();
      } catch (error) {
        item.adgroupMetadata.status = value === 'ENABLED' ? 'PAUSED' : 'ENABLED';
      }
      item.loading = false;
      this.fullscreenLoader.hide();
    },

    openEditAddgroupWizard(data) {
      const metadata = data.adgroupMetadata;
      const adgroupDetails = {
        adamId: data.campaignMetadata.adamId,
        countriesOrRegions: data.campaignMetadata.countriesOrRegions,
        orgId: metadata.orgId,
        adgroupId: metadata.id,
        campaignId: metadata.campaignId,
        name: metadata.name,
        defaultBidAmount: metadata.defaultBidAmount,
        cpaGoal: metadata.cpaGoal,
        startTime: metadata.startTime,
        endTime: metadata.endTime,
        automatedKeywordsOptIn: metadata.automatedKeywordsOptIn,
        targetingDimensions: metadata.targetingDimensions,
        status: metadata.status,
        displayStatus: data.metadata.adGroupDisplayStatus,
        supplySources: data.campaignMetadata.supplySources,
        adChannelType: data.campaignMetadata.adChannelType,
        now: Date.now(),
      };
      this.editAdgroupMetadata = adgroupDetails;
    },
    async openUpdateCreativeSet(item) {
      this.updateCreativeSetData = {
        csName: item.creativeSetMetadata.name,
        creativeSetId: item.creativeSetMetadata.creativeSetId,
        orgId: item.adgroupMetadata.orgId,
        language: item.creativeSetMetadata.languageCode,
        adamId: item.campaignMetadata.adamId,
        countries: item.campaignMetadata.countriesOrRegions,
        now: Date.now(),
      };
    },

    async onCreativeSetStatusChange(value, item) {
      item.loading = true;
      const enablePauseCreativeSetReq = {
        adgroupId: item.creativeSetMetadata.adGroupId,
        campaignId: item.creativeSetMetadata.campaignId,
        creativeSetId: item.creativeSetMetadata.id,
        status: value,
      };
      try {
        const result = await this.$apollo.mutate({
          mutation: TOGGLE_CREATIVE_SET,
          variables: {
            orgId: item.adgroupMetadata.orgId,
            enablePauseCreativeSetReq,
          },
        });
        item.loading = false;
        if (result.data.enablePauseCreativeSet.successMessage) {
          this.$notify.success(this.$t('Status changed. Creative set moved to different tab'));
        } else {
          this.$notify.error(this.$t('Error while changing status'));
          item.creativeSetMetadata.status = value === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        }
      } catch (error) {
        item.loading = false;
        item.creativeSetMetadata.status = value === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
      }
    },

    async targetKeywordStatusChange(value, item) {
      item.loading = true;
      this.fullscreenLoader = this.$loading.show();
      const changeTargetKeyStatusReq = {
        adgroupId: item.keywordMetadata.adGroupId,
        campaignId: item.adgroupMetadata.campaignId,
        status: value,
        keywordId: item.keywordMetadata.id,
      };
      try {
        const result = await this.$apollo.mutate({
          mutation: TARGET_KEYWORD_STATUS,
          variables: {
            orgId: item.adgroupMetadata.orgId,
            changeTargetKeyStatusReq,
          },
        });
        item.loading = false;
        if (result.data.changeTargetKeyStatus.successMessage) {
          if (value == 'PAUSED') this.$notify.success(this.$t('successMsg.keywordMovedMsgPaused'));
          if (value == 'ACTIVE') this.$notify.success(this.$t('successMsg.keywordMovedMsgLive'));
          this.onUpdate();
        } else {
          item.keywordMetadata.status = value === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
          this.$notify.error(this.$t('successMsg.keywordMoveFailMsg'));
          this.onUpdate();
        }
        this.fullscreenLoader.hide();
      } catch (error) {
        this.fullscreenLoader.hide();
        item.loading = false;
        item.keywordMetadata.status = value === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
      }
    },

    adgroupsSelectedForKeyword(appsWithCampaigns) {
      // getting adgroups
      const adgroups = this.getNested(appsWithCampaigns?.data, 'ADGROUP');
      this.adgroupMetadata = {
        selectedAdgroups: adgroups,
        now: Date.now(),
        selectedApp: appsWithCampaigns.selectedApp,
      };
    },

    addKeyToFiltAdgroup() {
      const data = [
        {
          orgId: this.selectedAccount?.orgId || this.selectedAdGroup?.orgId,
          campaignId: this.selectedCampaign?.id || this.selectedAdGroup?.campaignId,
          id: this.selectedAdGroup.id,
          defaultBidAmount: this.selectedAdGroup?.defaultBidAmount || null,
        },
      ];
      this.adgroupMetadata = {
        selectedAdgroups: data,
        selectedApp: this.selectedApp,
        now: Date.now(),
      };
    },

    adgroupsSelectedForNegativeKeyword(appsWithCampaigns) {
      // getting adgroups
      const adgroups = this.getNested(appsWithCampaigns, 'ADGROUP');
      this.adgroupMetadata = { selectedAdgroups: adgroups, now: Date.now() };
    },

    adgroupsSelectedForCs(appsWithCampaigns) {
      const campaigns = this.getNested(appsWithCampaigns.data, 'CAMPAIGN');
      // in case of creative set, only one adgroup selection allowed.
      // hence it's safe to select the first campaign that has an adgroup
      const campaign = campaigns.filter((campaign) => campaign.adgroups.length)[0];
      const adgroup = campaign.adgroups[0];

      this.creativeSetMetadata = {
        orgId: adgroup.orgId,
        adamId: campaign.adamId,
        adgroupId: adgroup.id,
        campaignId: campaign.id,
        countries: campaign.countriesOrRegions,
        now: Date.now(),
      };
    },

    getNested(appsWithCampaigns, level: 'APP' | 'CAMPAIGN' | 'ADGROUP') {
      // returning nested data as requested (apps > campaigns > adgroups)
      return level === 'APP'
        ? appsWithCampaigns
        : [].concat(
            ...appsWithCampaigns.map((app) =>
              level === 'CAMPAIGN' ? app.campaigns : [].concat(...app.campaigns.map((campaign) => campaign.adgroups))
            )
          );
    },

    async addCreativeSet({ creativeSet, orgId }) {
      const creativeSetReq = {
        creativeSet,
        orgId,
        adgroupId: this.creativeSetMetadata.adgroupId,
        campaignId: this.creativeSetMetadata.campaignId,
      };

      try {
        const result = await this.$apollo.mutate({
          mutation: ADD_CREATIVE_SET,
          variables: creativeSetReq,
        });

        if (result.data.addCreativeSet.searchAdsErrors) {
          this.$notify.error(result.data.addCreativeSet.searchAdsErrors);
        } else {
          this.$notify.success(this.$t('successMsg.creativeAddedMsg'));
          updateCLF('addCS', this);
        }
      } catch {}
    },

    setChartData(data, propName): void {
      data.datasets.map((item) => {
        if (item.label == 'tapInstalls' || item.label == 'Installs Tap Through' || item.label == 'Installs (Tap-Through)') {
          item.label = 'Installs (Tap-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        }  else if (item.label == 'viewInstalls' || item.label == 'Installs View Through' || item.label == 'Installs (View-Through)') {
          item.label = 'Installs (View-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        }  else if (item.label == 'totalInstalls' || item.label == 'Installs Total' || item.label == 'Installs (Total)') {
          item.label = 'Installs (Total)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'tapRedownloads' || item.label == 'Tap-Redownloads' || item.label == 'Redownloads Tap Through' || item.label == 'Redownloads (Tap-Through)' || item.label == 'Redownloads') {
          item.label = 'Redownloads (Tap-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'viewRedownloads' || item.label == 'View-Redownloads' || item.label == 'Redownloads View Through' || item.label == 'Redownloads (View-Through)') {
          item.label = 'Redownloads (View-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'totalRedownloads' || item.label == 'Total-Redownloads' || item.label == 'Redownloads Total' || item.label == 'Redownloads (Total)') {
          item.label = 'Redownloads (Total)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'tapNewDownloads' || item.label == 'New Downloads Tap Through' || item.label == 'New Downloads (Tap-Through)') {
          item.label = 'New Downloads (Tap-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'viewNewDownloads' || item.label == 'New Downloads View Through' || item.label == 'New Downloads (View-Through)') {
          item.label = 'New Downloads (View-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'totalNewDownloads' || item.label == 'New Downloads Total' || item.label == 'New Downloads (Total)') {
          item.label = 'New Downloads (Total)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'localSpend') {
          item.label = 'Spend';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'ttr') {
          item.label = 'TTR';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'tapInstallCPI' || item.label == 'Avg CPA (Tap-Through)' || item.label == 'Avg CPA' || item.label == 'Tap Install CPI') {
          item.label = 'Avg CPA (Tap-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'totalAvgCPI' || item.label == 'Avg CPA (Total)' || item.label == 'Total Average CPI' || item.label == 'Total Avg CPI') {
          item.label = 'Avg CPA (Total)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        }  else if (item.label == 'tapInstallRate' || item.label == 'Conversion Rate' || item.label == 'Conversion Rate TapThrough' || item.label == 'Conversion Rate (Tap-Through)') {
          item.label = 'Conversion Rate (Tap-Through)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'totalInstallRate' || item.label == 'Conversion Rate Total' || item.label == 'Conversion Rate (Total)') {
          item.label = 'Conversion Rate (Total)';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'avgCPT') {
          item.label = 'Average CPT';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'avgCPM') {
          item.label = 'Average CPM';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else if (item.label == 'Goal Roas') {
          item.label = 'Goal ROAS';
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        } else {
          item.label = _.startCase(item.label);
          item.yAxisID = item.label;
          item.fill = false;
          item.borderColor = this.getRandomColor(item.label);
          item.borderWidth = 1;
        }
      });
      const dateList = this.setEmptyDates(this.chartGranularity, this.filterDateRangeModel[0], this.filterDateRangeModel[1]);
      let temparr = [];
      if (data.datasets.length && data.labels.length) {
        dateList.some((element) => {
          if (data.labels.includes(element)) return true;
          temparr.push(element);
          data.datasets.forEach((element) => {
            element.data.unshift(0);
          });
        });
        data.labels = temparr.concat(data.labels);

        //filling intermediate dates
        if (this.chartGranularity == 'DAILY') {
          for (const [index, date] of dateList.entries()) {
            if (data.labels.includes(date)) continue;
            data.datasets.forEach((element) => {
              element.data.splice(index, 0, 0);
            });
            data.labels.splice(index, 0, date);
          }
        }
        //filling intermediate dates
      }
      this[propName] = { ...data, time: Date.now().toString() };
    },
    setEmptyDates(granularity, startDate, endDate) {
      switch (granularity) {
        case 'DAILY': {
          var dateList = this.getDaysBetweenDates(startDate, endDate);
          return dateList;
        }
        case 'WEEKLY': {
          var dateList = this.getWeeksBetweenDates(startDate, endDate);
          return dateList;
        }
        case 'MONTHLY': {
          var dateList = this.getMonthsBetweenDates(startDate, endDate);
          return dateList;
        }
      }
    },
    getDaysBetweenDates(startDate, endDate) {
      startDate = moment(startDate, 'YYYY-MM-DD');
      endDate = moment(endDate, 'YYYY-MM-DD');
      var now = startDate.clone(),
        dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
      }
      return dates;
    },
    getWeeksBetweenDates(startDate, endDate) {
      startDate = moment(startDate, 'YYYY-MM-DD');
      endDate = moment(endDate, 'YYYY-MM-DD');
      var now = startDate.clone(),
        dates = [];

      while (now.isSameOrBefore(endDate)) {
        let week = now.isoWeek() + '-' + now.year();
        dates.push(week);
        now.add(7, 'days');
      }
      let endweek = endDate.isoWeek() + '-' + endDate.year();
      if (!dates.includes(endweek)) dates.push(endweek);
      return dates;
    },

    getMonthsBetweenDates(startDate, endDate) {
      startDate = moment(startDate, 'YYYY-MM-DD');
      endDate = moment(endDate, 'YYYY-MM-DD');
      var now = startDate.clone(),
        dates = [];

      while (now.isSameOrBefore(endDate)) {
        let week = now.format('MMMM-YYYY');
        dates.push(week);
        now.add(1, 'M');
      }
      let endweek = now.format('MMMM-YYYY');
      if (!dates.includes(endweek)) dates.push(endweek);
      return dates;
    },
    getRandomColor(item) {
      switch (item) {
        case 'Installs (Tap-Through)':
        case 'tapInstalls':
        case 'TapInstalls':
          return '#312D4E';
        case 'Installs (View-Through)':
        case 'viewInstalls':
        case 'ViewInstalls':
          return '#38316e';
        case 'Installs (Total)':
        case 'totalInstalls':
        case 'TotalInstalls':
          return '#211c45';
        case 'New Downloads (Tap-Through)':
        case 'New Downloads (Tap-Through)':
        case 'tapNewDownloads':
          return '#662d91';
        case 'New Downloads (View-Through)':
        case 'New Downloads (View-Through)':
        case 'viewNewDownloads':
          return '#4a1f6b';
        case 'New Downloads (Total)':
        case 'New Downloads (Total)':
        case 'totalNewDownloads':
          return '#571e82';
        case 'Redownloads (Tap-Through)':
        case 'tapRedownloads':
        case 'Redownloads (Tap-Through)':
        case 'Tap-Redownloads':
          return '#8b8096';
        case 'Redownloads (View-Through)':
        case 'viewRedownloads':
        case 'Redownloads (View-Through)':
        case 'View-Redownloads':
          return '#817091';
        case 'Redownloads (Total)':
        case 'totalRedownloads':
        case 'Redownloads (Total)':
        case 'Total-Redownloads':
          return '#635a6b';
        case 'Impressions':
          return '#9092d1';
        case 'Taps':
          return '#ec008c';
        case 'Spend':
          return '#d78b4b';
        case 'TTR':
          return '#ed1c24';
        case 'Conversion Rate (Tap-Through)':
        case 'tapInstallRate':
        case 'Conversion Rate (Tap-Through)':
          return '#f7941d';
        case 'Conversion Rate (Total)':
        case 'totalInstallRate':
        case 'Conversion Rate (Total)':
          return '#d4872a';
        case 'Avg CPA (Tap-Through)':
        case 'tapInstallCPI':
        case 'Tap Install CPI':
          return '#ffcc00';
        case 'Avg CPA (Total)':
        case 'totalAvgCPI':
        case 'Total Average CPI':
          return '#deb204';
        case 'Average CPT':
          return '#905320';
        case 'Goal Revenue':
          return '#8dc63f';
        case 'Goal Event Count':
          return '#00a651';
        case 'Goal ROAS':
          return '#2e3192';
        case 'Cost Per Goal':
          return '#00cbbd';
        case 'Revenue Per Goal':
          return '#00aeef';
        case 'Goal ARPU':
          return '#0072bc';
        case 'Goal AMPU':
          return '#2fe46f';
        default: {
          var letters = '0123456789ABCDEF';
          var color = '#';
          for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }
      }
    },

    handleGranularityChange(chartGranularity: string): void {
      this.chartGranularity = chartGranularity;

      const graphFilter = {
        ...this.filter,
        ...this.createGraphFilters(),
      };
      this.graphFilter = graphFilter;
    },

    saveGranularityToCustomView(chartGranularity: Object) {
      let tabInfo = chartGranularity?.tabInfo;
      let granularity = chartGranularity?.granularity;
      this.updateGranularityInCustomView(tabInfo, granularity);
    },

    async persistDatasets(persistDatasets: Object): void {
      return;
    },

    async saveCustomViews(persistDatasets: Object): void {
      let tabInfo = persistDatasets?.tabInfo;
      let presets = persistDatasets?.selectedSetNamesToPersist.toString();
      this.updateCustomViewLSS(tabInfo, presets);
    },

    async saveCustomTableViews(persistDatasets: Object): void {
      if (persistDatasets?.customTab) {
        let customTab = persistDatasets?.customTab;
        let tabInfo = persistDatasets?.tabInfo;
        let presets = persistDatasets?.selectedDataTable.toString();
        this.updateCustomViewTableLSS(tabInfo, presets, customTab);
      } else {
        let tabInfo = persistDatasets?.tabInfo;
        let presets = persistDatasets?.selectedDataTable.toString();
        this.updateCustomViewTableLSS(tabInfo, presets);
      }
    },
    updateCustomViewLSS(tabInfo, presets): void {
      let customViews = LSS.customViews;
      if (customViews) {
        let obj = customViews.find((o) => o._id == this.customView._id && o.tab == tabInfo);
        if (obj) obj.ChartDatasets = presets;
        else if (customViews.find((o) => o._id == '1' && o.tab == tabInfo)) {
          const tempView = customViews.find((o) => o._id == '1' && o.tab == tabInfo);
          tempView.ChartDatasets = presets;
        } else {
          customViews = [
            {
              _id: '1',
              CustomView: 'TempView',
              tab: tabInfo,
              ChartDatasets: presets,
            },
            ...customViews,
          ];
        }
        LSS.customViews = customViews;
      }
    },

    updateGranularityInCustomView(tabInfo, granularity) {
      let customViews = LSS.customViews;
      if (customViews) {
        let obj = customViews.find((o) => o._id == this.customView._id && o.tab == tabInfo);
        if (obj) obj.Granularity = granularity;
        else if (customViews.find((o) => o._id == '1' && o.tab == tabInfo)) {
          const tempView = customViews.find((o) => o._id == '1' && o.tab == tabInfo);
          tempView.Granularity = granularity;
        } else {
          customViews = [
            {
              _id: '1',
              CustomView: 'TempView',
              tab: tabInfo,
              Granularity: granularity,
            },
            ...customViews,
          ];
        }
        LSS.customViews = customViews;
      }
    },
    updateFilterLSS(tabInfo, presets): void {
      let customViews = LSS.customViews;
      if (customViews) {
        let obj = customViews.find((o) => o._id == this.customView._id && o.tab == tabInfo);
        if (obj) obj.filters = presets;
        else if (customViews.find((o) => o._id == '1' && o.tab == tabInfo)) {
          const tempView = customViews.find((o) => o._id == '1' && o.tab == tabInfo);
          tempView.filters = presets;
        } else {
          customViews = [
            {
              _id: '1',
              CustomView: 'TempView',
              tab: tabInfo,
              filters: presets,
            },
            ...customViews,
          ];
        }
        LSS.customViews = customViews;
      }
    },
    updateCustomViewTableLSS(tabInfo, presets, customTab = null): void {
      let customViews = LSS.customViews;

      if (customViews) {
        let obj = undefined;
        if (customTab) {
          switch (tabInfo) {
            case 'apps': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                /* obj.DatatableDatasets.livecampaign = presets; */
                obj.DatatableDatasets = { ...obj.DatatableDatasets, apps: presets };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = { ...tempView.DatatableDatasets, apps: presets };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { apps: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }

            case 'accounts': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                /* obj.DatatableDatasets.livecampaign = presets; */
                obj.DatatableDatasets = { ...obj.DatatableDatasets, accounts: presets };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = { ...tempView.DatatableDatasets, accounts: presets };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { accounts: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }
            case 'searchterm': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                /* obj.DatatableDatasets.livecampaign = presets; */
                obj.DatatableDatasets = { ...obj.DatatableDatasets, searchterm: presets };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = { ...tempView.DatatableDatasets, searchterm: presets };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { searchterm: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }
            case 'ads':
            case 'pausedads':
            case 'deletedads':
            case 'allads': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                /* obj.DatatableDatasets.livecampaign = presets; */
                obj.DatatableDatasets = {
                  ...obj.DatatableDatasets,
                  ads: presets,
                  pausedads: presets,
                  deletedads: presets,
                  allads: presets,
                };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = {
                  ...tempView.DatatableDatasets,
                  ads: presets,
                  pausedads: presets,
                  deletedads: presets,
                  allads: presets,
                };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { ads: presets, pausedads: presets, deletedads: presets, allads: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }

            case 'livecampaign':
            case 'pausedcampaign':
            case 'deletedcampaign':
            case 'allcampaign': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                /* obj.DatatableDatasets.livecampaign = presets; */
                obj.DatatableDatasets = {
                  ...obj.DatatableDatasets,
                  livecampaign: presets,
                  pausedcampaign: presets,
                  deletedcampaign: presets,
                  allcampaign: presets,
                };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = {
                  ...tempView.DatatableDatasets,
                  livecampaign: presets,
                  pausedcampaign: presets,
                  deletedcampaign: presets,
                  allcampaign: presets,
                };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { livecampaign: presets, pausedcampaign: presets, deletedcampaign: presets, allcampaign: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }
            case 'draftcampaign': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                obj.DatatableDatasets = { ...obj.DatatableDatasets, draftcampaign: presets };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = { ...tempView.DatatableDatasets, draftcampaign: presets };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { draftcampaign: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }
            case 'liveadgroup':
            case 'pausedadgroup':
            case 'deletedadgroup':
            case 'alladgroup': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                obj.DatatableDatasets = {
                  ...obj.DatatableDatasets,
                  liveadgroup: presets,
                  pausedadgroup: presets,
                  deletedadgroup: presets,
                  alladgroup: presets,
                };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = {
                  ...tempView.DatatableDatasets,
                  liveadgroup: presets,
                  pausedadgroup: presets,
                  deletedadgroup: presets,
                  alladgroup: presets,
                };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { liveadgroup: presets, pausedadgroup: presets, deletedadgroup: presets, alladgroup: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }
            case 'livekeyword':
            case 'pausedkeyword':
            case 'deletedkeyword':
            case 'allkeyword': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                obj.DatatableDatasets = {
                  ...obj.DatatableDatasets,
                  livekeyword: presets,
                  pausedkeyword: presets,
                  deletedkeyword: presets,
                  allkeyword: presets,
                };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = {
                  ...tempView.DatatableDatasets,
                  livekeyword: presets,
                  pausedkeyword: presets,
                  deletedkeyword: presets,
                  allkeyword: presets,
                };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: { livekeyword: presets, pausedkeyword: presets, deletedkeyword: presets, allkeyword: presets },
                  },
                  ...customViews,
                ];
              }
              break;
            }

            case 'livenegativekeyword':
            case 'pausednegativekeyword':
            case 'deletednegativekeyword':
            case 'allnegativekeyword': {
              obj = customViews.find((o) => o._id == this.customView._id && o.tab == customTab);
              if (obj) {
                obj.DatatableDatasets = {
                  ...obj.DatatableDatasets,
                  livenegativekeyword: presets,
                  pausednegativekeyword: presets,
                  deletednegativekeyword: presets,
                  allnegativekeyword: presets,
                };
              } else if (customViews.find((o) => o._id == this.customView._id && o.tab != customTab && this.customView._id != '1')) {
              } else if (customViews.find((o) => o._id == '1' && o.tab == customTab)) {
                const tempView = customViews.find((o) => o._id == '1' && o.tab == customTab);
                tempView.DatatableDatasets = {
                  ...tempView.DatatableDatasets,
                  livenegativekeyword: presets,
                  pausednegativekeyword: presets,
                  deletednegativekeyword: presets,
                  allnegativekeyword: presets,
                };
              } else {
                customViews = [
                  {
                    _id: '1',
                    CustomView: 'TempView',
                    tab: customTab,
                    DatatableDatasets: {
                      livenegativekeyword: presets,
                      pausednegativekeyword: presets,
                      deletednegativekeyword: presets,
                      allnegativekeyword: presets,
                    },
                  },
                  ...customViews,
                ];
              }
              break;
            }
          }
        } else {
          let obj = customViews.find((o) => o._id == this.customView._id && o.tab == tabInfo);
          if (obj) obj.DatatableDatasets = presets;
          else if (customViews.find((o) => o._id == '1' && o.tab == tabInfo)) {
            const tempView = customViews.find((o) => o._id == '1' && o.tab == tabInfo);
            tempView.DatatableDatasets = presets;
          } else {
            customViews = [
              {
                _id: '1',
                CustomView: 'TempView',
                tab: tabInfo,
                DatatableDatasets: presets,
              },
              ...customViews,
            ];
          }
        }

        LSS.customViews = customViews;
      }
    },

    updateLSS(tabInfo, presets): void {
      let chartDataSetsLSS = LSS.chartDatasets;
      if (chartDataSetsLSS) {
        switch (tabInfo) {
          case 'accounts':
            chartDataSetsLSS.accounts = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'apps':
            chartDataSetsLSS.apps = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'campaign':
            chartDataSetsLSS.campaign = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'adgroup':
            chartDataSetsLSS.adgroup = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'keyword':
            chartDataSetsLSS.keyword = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'creativeset':
            chartDataSetsLSS.creativeset = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'searchterm':
            chartDataSetsLSS.searchterm = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'negativekeyword':
            chartDataSetsLSS.negativekeyword = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
          case 'ads':
            chartDataSetsLSS.ads = presets;
            LSS.chartDatasets = chartDataSetsLSS;
            break;
        }
      }
    },

    async persistDatatable(persistDatatable): void {
      return;
    },

    async downloadReport(reportFor: Object) {
      // this.$notify.success('Downloading report.')
      switch (reportFor.reportFor) {
        case 'accounts': {
          const { data } = await this.$apollo.query({
            query: ACCOUNTS_REPORTS,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.filteredAccountsReportsCount,
              sortBy: this.AccountSortBy,
              sortDesc: this.AccountSortDesc,
              accountSearch: this.searchAccountInputs,
            },
          });
          this.$refs.accountDatatable.downloadReportsEvent(data.accountsReports?.row || []);
          break;
        }

        case 'apps': {
          const { data } = await this.$apollo.query({
            query: APPS_REPORTS,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.filteredAppReportsCount,
              sortBy: this.appsReportSortBy,
              sortDesc: this.appsReportSortDesc,
              search: this.searchAppsInputs,
            },
          });
          this.$refs.appsDatatable.downloadReportsEvent(data.appsReports?.row || []);
          break;
        }
        case 'livecampaign': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_CAMPAIGN_LIVE,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.liveReportsCount,
              sortBy: this.LiveCampaignSortBy,
              sortDesc: this.LiveCampaignSortDesc,
              campSearch: this.searchCampaignInputsLive,
              getGoals: this.showGoalinCampaignLive,
            },
          });
          this.$refs.liveCampaignDatatable.downloadReportsEvent(data.campaignReportsLive?.row || []);
          break;
        }
        case 'pausedcampaign': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_CAMPAIGN_PAUSED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.disabledReportsCount,
              sortBy: this.PausedCampaignSortBy,
              sortDesc: this.PausedCampaignSortDesc,
              campSearch: this.searchCampaignInputsPaused,
              getGoals: this.showGoalinCampaignPaused,
            },
          });
          this.$refs.pausedCampaignDatatable.downloadReportsEvent(data.campaignReportsPaused?.row || []);
          break;
        }
        case 'deletedcampaign': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_CAMPAIGN_DELETED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.deletedReportsCount,
              sortBy: this.DeletedCampaignSortBy,
              sortDesc: this.DeletedCampaignSortDesc,
              campSearch: this.searchCampaignInputsDeleted,
              getGoals: this.showGoalinCampaignDeleted,
            },
          });
          this.$refs.deletedCampaignDatatable.downloadReportsEvent(data.campaignReportsDeleted?.row || []);
          break;
        }
        case 'allcampaign': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_CAMPAIGN_ALL,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.allReportsCount,
              sortBy: this.AllCampaignSortBy,
              sortDesc: this.AllCampaignSortDesc,
              campSearch: this.searchCampaignInputsAll,
              getGoals: this.showGoalinCampaignAll,
            },
          });
          this.$refs.allCampaignDatatable.downloadReportsEvent(data.campaignReportsAll?.row || []);
          break;
        }
        case 'liveadgroup': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_GROUP_LIVE,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.filteredAdgroupReportsCount,
              sortBy: this.LiveAdGroupSortBy,
              sortDesc: this.LiveAdGroupSortDesc,
              adGroupSearch: this.searchAdgroupInputsLive,
              getGoals: this.showGoalinAdgroupLive,
            },
          });
          this.$refs.liveAdgroupDatatable.downloadReportsEvent(data.adGroupReportsLive?.row || []);
          break;
        }
        case 'pausedadgroup': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_GROUP_PAUSED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.disabledAdgroupReportsCount,
              sortBy: this.PausedAdGroupSortBy,
              sortDesc: this.PausedAdGroupSortDesc,
              adGroupSearch: this.searchAdgroupInputsPaused,
              getGoals: this.showGoalinAdgroupPaused,
            },
          });
          this.$refs.pausedAdgroupDatatable.downloadReportsEvent(data.adGroupReportsPaused?.row || []);
          break;
        }
        case 'deletedadgroup': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_GROUP_DELETED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.deletedAdgroupReportsCount,
              sortBy: this.DeletedAdGroupSortBy,
              sortDesc: this.DeletedAdGroupSortDesc,
              adGroupSearch: this.searchAdGroupInputsDeleted,
              getGoals: this.showGoalinAdgroupDeleted,
            },
          });
          this.$refs.deletedAdgroupDatatable.downloadReportsEvent(data.adGroupReportsDeleted?.row || []);
          break;
        }
        case 'alladgroup': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_GROUP_ALL,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.allAdgroupReportsCount,
              sortBy: this.AllAdGroupSortBy,
              sortDesc: this.AllAdGroupSortDesc,
              adGroupSearch: this.searchAdGroupInputsAll,
              getGoals: this.showGoalinAdgroupAll,
            },
          });
          this.$refs.allAdgroupDatatable.downloadReportsEvent(data.adGroupReportsAll?.row || []);
          break;
        }
        case 'livekeyword': {
          const count = this.filteredKeywordReportsCount;
          const limit = 500;
          let loopCount = 0;
          let row = [];
          if (count % 500 == 0) loopCount = count / 500;
          else loopCount = Math.floor(count / 500) + 1;

          for (let i = 0; i < loopCount; i++) {
            if (i == 0) {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_LIVE,
                variables: {
                  filter: this.filter,
                  offset: 0,
                  limit: limit,
                  sortBy: this.LiveKeywordSortBy,
                  sortDesc: this.LiveKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsLive,
                  getGoals: this.showGoalinKeywordLive,
                },
              });
              row.push(...data.targetingKeywordReportsLive?.row);
            } else {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_LIVE,
                variables: {
                  filter: this.filter,
                  offset: limit * i,
                  limit: limit,
                  sortBy: this.LiveKeywordSortBy,
                  sortDesc: this.LiveKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsLive,
                  getGoals: this.showGoalinKeywordLive,
                },
              });
              row.push(...data.targetingKeywordReportsLive?.row);
            }
          }

          this.$refs.livekeywordDatatable.downloadReportsEvent(row || []);
          break;
        }
        case 'pausedkeyword': {
          const count = this.disabledKeywordReportsCount;
          const limit = 500;
          let loopCount = 0;
          let row = [];
          if (count % 500 == 0) loopCount = count / 500;
          else loopCount = Math.floor(count / 500) + 1;
          for (let i = 0; i < loopCount; i++) {
            if (i == 0) {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_PAUSED,
                variables: {
                  filter: this.filter,
                  offset: 0,
                  limit: limit,
                  sortBy: this.PausedKeywordSortBy,
                  sortDesc: this.PausedKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsPaused,
                  getGoals: this.showGoalinKeywordPaused,
                },
              });
              row.push(...data.targetingKeywordReportsPaused?.row);
            } else {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_PAUSED,
                variables: {
                  filter: this.filter,
                  offset: limit * i,
                  limit: limit,
                  sortBy: this.PausedKeywordSortBy,
                  sortDesc: this.PausedKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsPaused,
                  getGoals: this.showGoalinKeywordPaused,
                },
              });
              row.push(...data.targetingKeywordReportsPaused?.row);
            }
          }
          this.$refs.pausedKeywordDatatable.downloadReportsEvent(row || []);
          break;
        }
        case 'deletedkeyword': {
          const count = this.deletedKeywordReportsCount;
          const limit = 500;
          let loopCount = 0;
          let row = [];
          if (count % 500 == 0) loopCount = count / 500;
          else loopCount = Math.floor(count / 500) + 1;
          for (let i = 0; i < loopCount; i++) {
            if (i == 0) {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_DELETED,
                variables: {
                  filter: this.filter,
                  offset: 0,
                  limit: limit,
                  sortBy: this.DeletedKeywordSortBy,
                  sortDesc: this.DeletedKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsDeleted,
                  getGoals: this.showGoalinKeywordDeleted,
                },
              });
              row.push(...data.targetingKeywordReportsDeleted?.row);
            } else {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_DELETED,
                variables: {
                  filter: this.filter,
                  offset: limit * i,
                  limit: limit,
                  sortBy: this.DeletedKeywordSortBy,
                  sortDesc: this.DeletedKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsDeleted,
                  getGoals: this.showGoalinKeywordDeleted,
                },
              });
              row.push(...data.targetingKeywordReportsDeleted?.row);
            }
          }
          this.$refs.deletedKeywordDatatable.downloadReportsEvent(row || []);
          break;
        }
        case 'allkeyword': {
          const count = this.allKeywordReportsCount;
          const limit = 500;
          let loopCount = 0;
          let row = [];
          if (count % 500 == 0) loopCount = count / 500;
          else loopCount = Math.floor(count / 500) + 1;
          for (let i = 0; i < loopCount; i++) {
            if (i == 0) {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_ALL,
                variables: {
                  filter: this.filter,
                  offset: 0,
                  limit: limit,
                  sortBy: this.AllKeywordSortBy,
                  sortDesc: this.AllKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsAll,
                  getGoals: this.showGoalinKeywordAll,
                },
              });
              row.push(...data.targetingKeywordReportsAll?.row);
            } else {
              const { data } = await this.$apollo.query({
                query: ADS_MANAGER_KEYWORD_ALL,
                variables: {
                  filter: this.filter,
                  offset: limit * i,
                  limit: limit,
                  sortBy: this.AllKeywordSortBy,
                  sortDesc: this.AllKeywordsSortDesc,
                  keywordSearch: this.searchKeywordsInputsAll,
                  getGoals: this.showGoalinKeywordAll,
                },
              });
              row.push(...data.targetingKeywordReportsAll?.row);
            }
          }
          this.$refs.allKeywordDatatable.downloadReportsEvent(row || []);
          break;
        }
        case 'ads': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_LIVE,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.productPageReportCount,
              sortBy: this.LiveAdsSortBy,
              sortDesc: this.LiveAdsSortDesc,
              adsSearch: this.searchAdsInputsLive,
              getGoals: this.showGoalinAdLive,
            },
          });
          this.$refs.adsDatatable.downloadReportsEvent(data.productPageReportsLive?.row || []);
          break;
        }
        case 'pausedads': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_PAUSED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.adPauseReportCount,
              sortBy: this.PausedAdsSortBy,
              sortDesc: this.PausedAdsSortDesc,
              adsSearch: this.searchAdsInputsPaused,
              getGoals: this.showGoalinAdPaused,
            },
          });
          this.$refs.pausedAdsDatatable.downloadReportsEvent(data.productPageReportsPaused?.row || []);
          break;
        }
        case 'deletedads': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_DELETED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.adDeletedReportCount,
              sortBy: this.DeletedAdsSortBy,
              sortDesc: this.DeletedAdsSortDesc,
              adsSearch: this.searchAdsInputsDeleted,
              getGoals: this.showGoalinAdDeleted,
            },
          });
          this.$refs.deletedAdsDatatable.downloadReportsEvent(data.productPageReportsDeleted?.row || []);
          break;
        }
        case 'allads': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_AD_ALL,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.adAllReportCount,
              sortBy: this.AllAdsSortBy,
              sortDesc: this.AllAdsSortDesc,
              adsSearch: this.searchAdsInputsAll,
              getGoals: this.showGoalinAdAll,
            },
          });
          this.$refs.allAdsDatatable.downloadReportsEvent(data.productPageReportsAll?.row || []);
          break;
        }
        case 'searchterm': {
          const { data } = await this.$apollo.query({
            query: SEARCH_TERMS_REPORTS,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.productSearchTermCount,
              sortBy: this.searchTermsReportsSortBy,
              sortDesc: this.searchTermsReportsSortDesc,
              searchTermSearch: this.searchTermsSearchInputs,
              getGoals: this.showGoalinSearchTerm,
            },
          });
          this.$refs.searchtermDatatable.downloadReportsEvent(data.searchTermsReports?.row || []);
          break;
        }
        case 'livenegativekeyword': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_NEG_KEYWORD_LIVE,
            variables: {
              filter: {
                metadata: {
                  ...(this.selectedAccount && {
                    orgId: this.selectedAccount.orgId,
                  }),
                  ...(this.selectedApp && {
                    app: {
                      adamId: this.selectedApp.adamId,
                    },
                  }),
                },
                dateRange: this.filterDateRange,
              },
              offset: 0,
              limit: this.filteredNegKeywordReportsCount,
              sortBy: this.LiveNegtiveKeySortBy,
              sortDesc: this.LiveNegtiveKeySortDesc,
              negSearch: this.searchNegativeInputsLive,
            },
          });
          this.$refs.livenegativeDatatable.downloadReportsEvent(data.negativeKeywordReportsLive?.row || []);
          break;
        }
        case 'pausednegativekeyword': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_NEG_KEYWORD_PAUSED,
            variables: {
              filter: {
                metadata: {
                  ...(this.selectedAccount && {
                    orgId: this.selectedAccount.orgId,
                  }),
                  ...(this.selectedApp && {
                    app: {
                      adamId: this.selectedApp.adamId,
                    },
                  }),
                },
                dateRange: this.filterDateRange,
              },
              offset: 0,
              limit: this.disabledNegKeywordReportsCount,
              sortBy: this.PausedNegtiveKeySortBy,
              sortDesc: this.PausedNegtiveKeySortDesc,
              negSearch: this.searchNegativeInputsPaused,
            },
          });
          this.$refs.pausedNegativeKeywordsDatatable.downloadReportsEvent(data.negativeKeywordReportsPaused?.row || []);
          break;
        }
        case 'deletednegativekeyword': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_NEG_KEYWORD_DELETED,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.deletedNegKeywordReportsCount,
              sortBy: this.DeletedNegtiveKeySortBy,
              sortDesc: this.DeletedNegtiveKeySortDesc,
              negSearch: this.searchNegativeInputsDeleted,
            },
          });
          this.$refs.deletednegativeDatatable.downloadReportsEvent(data.negativeKeywordReportsDeleted?.row || []);
          break;
        }
        case 'allnegativekeyword': {
          const { data } = await this.$apollo.query({
            query: ADS_MANAGER_NEG_KEYWORD_ALL,
            variables: {
              filter: this.filter,
              offset: 0,
              limit: this.allNegKeywordReportsCount,
              sortBy: this.AllNegtiveKeySortBy,
              sortDesc: this.AllNegtiveKeySortDesc,
              negSearch: this.searchNegativeInputsAll,
            },
          });
          this.$refs.allnegativeDatatable.downloadReportsEvent(data.negativeKeywordReportsAll?.row || []);
          break;
        }
      }
    },
    updateDatatableLSS(tabInfo, presets): void {
      let chartDataSetsLSS = LSS.datatableDatasets;
      if (chartDataSetsLSS) {
        switch (tabInfo) {
          case 'accounts':
            chartDataSetsLSS.accounts = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'apps':
            chartDataSetsLSS.apps = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'livecampaign':
            chartDataSetsLSS.livecampaign = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'pausedcampaign':
            chartDataSetsLSS.pausedcampaign = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'deletedcampaign':
            chartDataSetsLSS.deletedcampaign = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'allcampaign':
            chartDataSetsLSS.allcampaign = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'draftcampaign':
            chartDataSetsLSS.draftcampaign = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'liveadgroup':
            chartDataSetsLSS.liveadgroup = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'pausedadgroup':
            chartDataSetsLSS.pausedadgroup = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'deletedadgroup':
            chartDataSetsLSS.deletedadgroup = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'alladgroup':
            chartDataSetsLSS.alladgroup = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'livekeyword':
            chartDataSetsLSS.keyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'pausedkeyword':
            chartDataSetsLSS.pausedkeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'deletedkeyword':
            chartDataSetsLSS.deletedkeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'allkeyword':
            chartDataSetsLSS.allkeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'livecreativeset':
            chartDataSetsLSS.livecreativeset = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'pausedcreativeset':
            chartDataSetsLSS.pausedcreativeset = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'deletedcreativeset':
            chartDataSetsLSS.deletedcreativeset = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'searchterm':
            chartDataSetsLSS.searchterm = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'livenegativekeyword':
            chartDataSetsLSS.livenegativekeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'pausednegativekeyword':
            chartDataSetsLSS.pausednegativekeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'deletednegativekeyword':
            chartDataSetsLSS.deletednegativekeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'allnegativekeyword':
            chartDataSetsLSS.allnegativekeyword = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'ads':
            chartDataSetsLSS.ads = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'pausedads':
            chartDataSetsLSS.pausedads = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'deletedads':
            chartDataSetsLSS.deletedads = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
          case 'allads':
            chartDataSetsLSS.allads = presets;
            LSS.datatableDatasets = chartDataSetsLSS;
            break;
        }
      }
    },

    shouldFilterAppear(filter: string): boolean {
      const tabItem = this.tabItems.find((item) => item.tabKey === this.currentTab);
      const allowedFilters = tabItem.filters;

      return allowedFilters.includes(filter);
    },

    async onTabChange(tabChangeEvent: any): void {
      this.isEditInputValid = false;
      this.errorEditViewMessage = '';
      this.isInputValid = false;
      (this.errorViewMessage = ''), (this.currentTab = tabChangeEvent.tabKey);
      this.switchTabBoolean();
      this.showViewForm = false;
      this.editMode = false;
      this.newCustomView = '';
      this.chartGranularity = DEFAULT_GRANULARITY;
      this.logType = 'Activity';
      this.runGranularityFilters();
      if (this.currentTab != AdsManagerTabs.PRODUCT_PAGES) {
        this.setCustomViewDropDown('ads');
        this.newCustomView = '';
        this.selectedAd = 0;
      }
      if (this.currentTab == AdsManagerTabs.STRATEGIES) {
        this.setCustomViewDropDown('strategies');
        this.newCustomView = '';
        this.navstrategyFilters();
        this.fetchStrategyItems('tabChange');
      }
      if (this.currentTab == AdsManagerTabs.ACCOUNTS) {
        this.startAccountTime = new Date().getTime();
        this.islogAccountProcess = 1;
        this.accountsEndTime = '';
        this.accountsChartEndTime = '';
        this.setCustomViewDropDown('accounts');
        this.newCustomView = '';
        this.navAccountFilter();
      } else if (this.currentTab == AdsManagerTabs.APPS) {
        this.startAppTime = new Date().getTime();
        this.islogAppProcess = 1;
        this.appsEndTime = '';
        this.appsChartEndTime = '';
        this.setCustomViewDropDown('apps');
        this.newCustomView = '';
        this.navAppFilter();
      } else if (this.currentTab == AdsManagerTabs.CAMPAIGNS) {
        this.startCampaignTime = new Date().getTime();
        this.islogCampaignProcess = 1;
        this.campaignLiveEndTime = '';
        this.campaignPausedEndTime = '';
        this.campaignDeletedEndTime = '';
        this.campaignAllEndTime = '';
        this.campaignChartEndTime = '';
        this.campaignSummaryEndTime = '';
        this.setCustomViewDropDown('campaigns');
        this.newCustomView = '';
        this.navCampaignFilter();
      } else if (this.currentTab == AdsManagerTabs.AD_GROUPS) {
        this.startAdgroupTime = new Date().getTime();
        this.islogAdgroupProcess = 1;
        this.adgroupLiveEndTime = '';
        this.adgroupPausedEndTime = '';
        this.adgroupDeletedEndTime = '';
        this.adgroupAllEndTime = '';
        this.adgroupChartEndTime = '';
        this.adgroupSummaryEndTime = '';
        this.setCustomViewDropDown('adgroups');
        this.newCustomView = '';
        this.navAdgroupFilter();
      } else if (this.currentTab == AdsManagerTabs.KEYWORDS) {
        this.startKeywordTime = new Date().getTime();
        this.islogKeywordProcess = 1;
        this.keywordLiveEndTime = '';
        this.keywordPausedEndTime = '';
        this.keywordDeletedEndTime = '';
        this.keywordAllEndTime = '';
        this.keywordChartEndTime = '';
        this.keywordSummaryEndTime = '';
        this.setCustomViewDropDown('keywords');
        this.newCustomView = '';
        this.navKeywordFilter();
      } else if (this.currentTab == AdsManagerTabs.PRODUCT_PAGES) {
        this.startAdTime = new Date().getTime();
        this.islogAdProcess = 1;
        this.adsLiveEndTime = '';
        this.adsPausedEndTime = '';
        this.adsDeletedEndTime = '';
        this.adsAllEndTime = '';
        this.adsChartEndTime = '';
        this.adsSummaryEndTime = '';
        this.setCustomViewDropDown('ads');
        this.newCustomView = '';
        this.navAdsFilter();
      } else if (this.currentTab == AdsManagerTabs.SEARCH_TERMS) {
        this.startSearchTermTime = new Date().getTime();
        this.islogSearchTermProcess = 1;
        this.searchTermEndTime = '';
        this.searchTermChartEndTime = '';
        this.setCustomViewDropDown('searchterm');
        this.newCustomView = '';
        this.navSearchTermFilter();
      } else if (this.currentTab == AdsManagerTabs.NEG_KEYWORDS) {
        this.startNegKeywordTime = new Date().getTime();
        this.islogNegKeywordProcess = 1;
        this.negKeywordLiveEndTime = '';
        this.negKeywordPausedEndTime = '';
        this.negKeywordDeletedEndTime = '';
        this.negKeywordAllEndTime = '';
        this.negKeywordChartEndTime = '';
        this.negKeywordSummaryEndTime = '';
        this.setCustomViewDropDown('negativekeywords');
        this.newCustomView = '';
        this.navNegativeKeywordFilter();
      }
    },

    switchTabBoolean(): void {
      // falsify all
      Object.keys(AdsManagerTabs).forEach((key) => {
        this[AdsManagerTabs[key]] = false;
      });

      this[this.currentTab] = true;
    },

    applyDateRange() {
      this.filterDateRange = this.filterDateRangeModel;
      // persisting filters that are already applied
      this.filterDateRange.sort();
      this.filter = Object.assign({}, this.filter, {
        dateRange: this.filterDateRange,
      });

      const graphFilter = {
        ...this.filter,
        ...this.createGraphFilters(),
      };
      this.graphFilter = graphFilter;
      this.rangePicker = false;
      this.mobileRangePickerDialog = false;
    },

    createFilters() {
      return {
        metadata: {
          ...(this.selectedAccount && {
            orgId: this.selectedAccount.orgId,
          }),
          ...(this.selectedApp && {
            app: {
              adamId: this.selectedApp.adamId,
            },
          }),
          ...(this.selectedCampaign && {
            campaignId: this.selectedCampaign.id,
          }),
          ...(this.selectedAdGroup && {
            adGroupId: this.selectedAdGroup.id,
          }),
          ...(this.selectedTargetingKeyword && {
            keywordId: this.selectedTargetingKeyword.id,
          }),
          ...(this.selectedAd && {
            adId: this.selectedAd.id,
          }),
          ...(this.currentTab == 'campaigns_tab' &&
            this.selectedCampaignSupplySource && {
              supplySources: this.selectedCampaignSupplySource.supplySources,
            }),
        },
        ...(this.selectedGoal && {
          goalId: this.selectedGoal._id,
        }),
        ...(this.selectedSecondaryGoal && {
          secondaryGoalId: this.selectedSecondaryGoal._id,
        }),
        ...(this.strategyId && {
          strategyId: this.strategyId,
        }),
        ...(this.isSelectedStrategies && {
          isSelectedStrategies: this.isSelectedStrategies,
        }),
        dateRange: this.filterDateRange,
      };
    },

    createGraphFilters() {
      return {
        granularity: this.chartGranularity,
        dateRange: this.filterDateRange,
      };
    },

    applyGraphFilters() {
      const graphFilter = {
        ...this.filter,
        ...this.createGraphFilters(),
      };
      this.graphFilter = graphFilter;
    },

    applyFilters() {
      const filter = this.createFilters();
      this.filter = filter;
      if (this.filter?.goalId && this.currentTab == 'campaigns_tab') {
        this.islogCampaignProcess = 1;
        this.startCampaignTime = new Date().getTime();
      }
      if (this.filter?.goalId && this.currentTab == 'ad_groups_tab') {
        this.islogAdgroupProcess = 1;
        this.startAdgroupTime = new Date().getTime();
      }
      if (this.filter?.goalId && this.currentTab == 'product_page_tab') {
        this.islogAdProcess = 1;
        this.startAdTime = new Date().getTime();
      }
      if (this.filter?.goalId && this.currentTab == 'search_terms_tab') {
        this.islogSearchTermProcess = 1;
        this.startSearchTermTime = new Date().getTime();
      }
    },

    async runFilterQueries(from) {
      if (this.currentTab != 'campaigns_tab') {
        if (!this.selectedCampaign) this.selectedCampaignSupplySource = 0;
      }

      await this.applyFilters();
      await this.applyGraphFilters();
      this.drawer = false;

      if (from == 'click') {
        LSS.filters = {
          selectedAccount: this.selectedAccount,
          selectedApp: this.selectedApp,
          selectedCampaign: this.selectedCampaign,
          selectedCampaignSupplySource: this.selectedCampaignSupplySource,
          selectedAdGroup: this.selectedAdGroup,
          selectedTargetingKeyword: this.selectedTargetingKeyword,
          selectedAd: this.selectedAd,
          selectedGoal: this.selectedGoal,
        };
      }
    },

    async resetFilters() {
      this.timeLineEntries = [];
      this.keyFilter = '';
      this.isStrategyEntityEmpty = false;
      await this.applySearchFilter();
      this.selectedStatestrategy = 0;
      this.selectedApp = 0;
      this.selectedAccount = 0;
      this.selectedCampaign = 0;
      this.selectedCampaignSupplySource = 0;
      this.selectedGoal = 0;
      this.selectedSecondaryGoal = 0;
      this.selectedCampaignType = null;
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.filter = { dateRange: this.filterDateRange };
      this.graphFilter = this.createGraphFilters();
      this.drawer = false;
      LSS.filters = null;
      this.enabledGoals = false;
      this.isIconDisabled = false;
      this.isSelectedStrategies = false;
      this.strategyId = 0;
      this.redirectStrategyId = null;
    },

    // Create an array the length of our items
    // with all values as true
    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },
    async strategyFilters(item) {
      this.isStrategyEntityEmpty = false;
      await this.resetFilters();
      this.selectedStatestrategy = item.name;
      const entitiesType = item.targetEntities.map((item) => item.type);
      if (entitiesType.length != 0) {
        if (entitiesType.includes('CAMPAIGN')) {
          this.tab = 3;
          await this.onTabChange(this.tabItems[3]);
        } else if (entitiesType.includes('ADGROUP')) {
          this.tab = 4;
          await this.onTabChange(this.tabItems[4]);
        } else if (entitiesType.includes('KEYWORD')) {
          this.tab = 5;
          await this.onTabChange(this.tabItems[5]);
        } else {
        }

        this.timeLineEntries = await this.getStrategyTimelines(item.strategyId);
      } else {
        this.isStrategyEntityEmpty = true;
      }
      this.strategyId = item.strategyId;
      this.isSelectedStrategies = true;
      LSS.filters = {
        selectedStatestrategyName: this.selectedStatestrategy,
        selectedAccount: this.selectedAccount,
        selectedApp: this.selectedApp,
        selectedCampaign: this.selectedCampaign,
        selectedAdGroup: this.selectedAdGroup,
        selectedTargetingKeyword: this.selectedTargetingKeyword,
        selectedGoal: this.selectedGoal,
      };
      this.isIconDisabled = true;
      this.checkIfGoalAttrRequired();
      await this.runFilterQueries();
    },
    async accountFilters(org) {
      this.tab = 2;
      await this.onTabChange(this.tabItems[2]);
      this.selectedAccount = org;
      LSS.filters = {
        selectedAccount: this.selectedAccount,
        selectedApp: this.selectedApp,
        selectedCampaign: this.selectedCampaign,
        selectedAdGroup: this.selectedAdGroup,
        selectedTargetingKeyword: this.selectedTargetingKeyword,
        selectedGoal: this.selectedGoal,
      };
      await this.runFilterQueries();
    },

    async appFilters(org, appData) {
      const appD = this.appFilterData.row.filter((e) => e.metadata.app.adamId == appData.app.adamId);
      const finalAppData = appD[0].metadata.app;
      this.tab = 3;
      await this.onTabChange(this.tabItems[3]);
      this.selectedApp = finalAppData;
      LSS.filters = {
        selectedAccount: this.selectedAccount,
        selectedApp: this.selectedApp,
        selectedCampaign: this.selectedCampaign,
        selectedAdGroup: this.selectedAdGroup,
        selectedTargetingKeyword: this.selectedTargetingKeyword,
        selectedGoal: this.selectedGoal,
      };
      await this.runFilterQueries();
    },

    async campaignFilters(org, metadata, campaign) {
      const appD = this.appFilterData.row.filter((e) => e.metadata.app.adamId == metadata.app.adamId);
      const finalAppData = appD[0].metadata.app;
      this.tab = 4;
      await this.onTabChange(this.tabItems[4]);
      this.adgroupListingTab = this.campaignListingTab;
      this.selectedApp = finalAppData;
      this.selectedCampaign = { id: campaign.id, name: campaign.name };
      this.selectedCampaignType = metadata.adChannelType;
      LSS.filters = {
        selectedAccount: this.selectedAccount,
        selectedApp: this.selectedApp,
        selectedCampaign: this.selectedCampaign,
        selectedCampaignSupplySource: this.selectedCampaignSupplySource,
        selectedAdGroup: this.selectedAdGroup,
        selectedTargetingKeyword: this.selectedTargetingKeyword,
        selectedGoal: this.selectedGoal,
      };
      await this.runFilterQueries();
    },

    async adgroupFilters(org, app, campaign, adgroup) {
      const appD = this.appFilterData.row.filter((e) => e.metadata.app.adamId == app.adamId);
      const finalAppData = appD[0].metadata.app;
      this.tab = 5;
      await this.onTabChange(this.tabItems[5]);
      this.keywordListingTab = this.adgroupListingTab;
      this.selectedApp = finalAppData;
      this.selectedAdGroup = adgroup; //{id : adgroup.id, name: adgroup.name};
      this.selectedCampaignType = campaign.adChannelType;
      LSS.filters = {
        selectedAccount: this.selectedAccount,
        selectedApp: this.selectedApp,
        selectedCampaign: this.selectedCampaign,
        selectedAdGroup: this.selectedAdGroup,
        selectedTargetingKeyword: this.selectedTargetingKeyword,
        selectedGoal: this.selectedGoal,
      };
      await this.runFilterQueries();
    },
    async keywordFilters(keyword, metadata) {
      this.selectedTargetingKeyword = keyword;
      this.selectedApp = metadata.app;
      LSS.filters = {
        selectedAccount: this.selectedAccount,
        selectedApp: this.selectedApp,
        selectedCampaign: this.selectedCampaign,
        selectedAdGroup: this.selectedAdGroup,
        selectedTargetingKeyword: this.selectedTargetingKeyword,
        selectedGoal: this.selectedGoal,
      };
      this.islogKeywordProcess = 1;
      this.startKeywordTime = new Date().getTime();
      await this.runFilterQueries();
    },

    applySearchFilter() {
      const filterCount = true;
      const key = this.keyFilter;
      switch (this.tab) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
        case 4:
        case 6:
          break;
        case 7:
          break;
      }
      this.drawer = false;
    },

    accountsSearchFilter(key) {
      this.filteredAccountReports = this.accountsReport;

      let tempAccountsReports = [];
      for (let index = 0; index < this.filteredAccountReports.row.length; index++) {
        const element = this.filteredAccountReports.row[index];

        if (key != '') {
          if (element.orgMetadata.orgName.toLowerCase().includes(key.toLowerCase())) {
            tempAccountsReports.push(element);
          }
        } else {
          tempAccountsReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredAccountReports.grandTotals,
        row: tempAccountsReports,
      };
      this.filteredAccountReports = reportsSchema;
    },

    appsSearchFilter(key) {
      this.filteredAppReports = this.appsReport;

      let tempAppsReports = [];
      for (let index = 0; index < this.filteredAppReports.row.length; index++) {
        const element = this.filteredAppReports.row[index];

        if (key != '') {
          if (element.metadata.app.appName.toLowerCase().includes(key.toLowerCase())) {
            tempAppsReports.push(element);
          }
        } else {
          tempAppsReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredAppReports.grandTotals,
        row: tempAppsReports,
      };
      this.filteredAppReports = reportsSchema;
    },

    adgroupSearchFilter(key) {
      this.filteredAdgroupReports = this.adgroupReport;
      let tempAdgroupReports = [];
      for (const element of this.filteredAdgroupReports.row) {
        if (key != '' && element.adgroupMetadata.status == 'ENABLED' && !element.adgroupMetadata.deleted) {
          if (element.adgroupMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempAdgroupReports.push(element);
          }
        } else if (element.adgroupMetadata.status == 'ENABLED' && !element.adgroupMetadata.deleted) {
          tempAdgroupReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredAdgroupReports.grandTotals,
        row: tempAdgroupReports,
      };
      this.filteredAdgroupReportsCount = tempAdgroupReports.length;
      this.filteredAdgroupReports = reportsSchema;
    },

    keywordSearchFilter(key, filterCount) {
      this.filteredKeywordReports = this.keywordReport;

      let tempKeywordReports = [];
      for (const element of this.filteredKeywordReports.row) {
        if (key != '' && element.keywordMetadata.status == 'ACTIVE' && !element.keywordMetadata.deleted) {
          if (element.keywordMetadata.text.toLowerCase().includes(key.toLowerCase())) {
            tempKeywordReports.push(element);
          }
        } else if (element.keywordMetadata.status == 'ACTIVE' && !element.keywordMetadata.deleted) {
          tempKeywordReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredKeywordReports.grandTotals,
        row: tempKeywordReports,
      };
      if (filterCount) {
        this.filteredKeywordReportsCount = tempKeywordReports.length;
      }
      this.filteredKeywordReports = reportsSchema;
    },

    creativesetSearchFilter(key) {
      this.filteredCreativeSetReports = this.creativeSetReport;

      let tempCreativeSetReports = [];
      for (const element of this.filteredCreativeSetReports.row) {
        if (key != '' && element.creativeSetMetadata.status == 'ENABLED' && !element.campaignMetadata.deleted) {
          if (element.creativeSetMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCreativeSetReports.push(element);
          }
        } else if (element.creativeSetMetadata.status == 'ENABLED' && !element.campaignMetadata.deleted) {
          tempCreativeSetReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredCreativeSetReports.grandTotals,
        row: tempCreativeSetReports,
      };
      this.filteredCreativeSetReportsCount = tempCreativeSetReports.length;
      this.filteredCreativeSetReports = reportsSchema;
    },

    searchTermsSearchFilter(key) {
      this.filteredSearchTermsReports = this.searchTermsReport;

      let tempSearchTermsReports = [];
      for (let index = 0; index < this.filteredSearchTermsReports.row.length; index++) {
        const element = this.filteredSearchTermsReports.row[index];

        if (key != '') {
          if (element.creativeSetMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempSearchTermsReports.push(element);
          }
        } else {
          tempSearchTermsReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.filteredSearchTermsReports.grandTotals,
        row: tempSearchTermsReports,
      };
      this.filteredSearchTermsReports = reportsSchema;
    },

    negKeywordSearchFilter(key) {
      this.filteredNegKeywordReports = this.negKeywordReport;

      let tempNegKeyReports = [];
      for (const element of this.filteredNegKeywordReports) {
        if (key != '' && element.status == 'ACTIVE' && element.deleted == false) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.status == 'ACTIVE' && element.deleted == false) {
          tempNegKeyReports.push(element);
        }
      }
      this.filteredNegKeywordReportsCount = tempNegKeyReports.length;
      this.filteredNegKeywordReports = tempNegKeyReports;
    },

    liveCampaignReport(key) {
      this.liveCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.liveCampaignReports.row) {
        if (key != '' && element.campaignMetadata.status == 'ENABLED' && !element.campaignMetadata.deleted) {
          if (element.campaignMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCampaignReports.push(element);
          }
        } else if (element.campaignMetadata.status == 'ENABLED' && !element.campaignMetadata.deleted) {
          tempCampaignReports.push(element);
        }
      }
      let campaignReportsSchema = {
        grandTotals: this.liveCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.liveReportsCount = tempCampaignReports.length;
      this.liveCampaignReports = campaignReportsSchema;
    },

    disabledCampaignReport(key) {
      this.disabledCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.disabledCampaignReports.row) {
        if (key != '' && element.campaignMetadata.status == 'PAUSED' && !element.campaignMetadata.deleted) {
          if (element.campaignMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCampaignReports.push(element);
          }
        } else if (element.campaignMetadata.status == 'PAUSED' && !element.campaignMetadata.deleted) {
          tempCampaignReports.push(element);
        }
      }
      let campaignReportsSchema = {
        grandTotals: this.disabledCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.disabledReportsCount = tempCampaignReports.length;
      this.disabledCampaignReports = campaignReportsSchema;
    },

    deletedCampaignReport(key) {
      this.deletedCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.deletedCampaignReports.row) {
        if (key != '' && element.campaignMetadata.deleted) {
          if (element.campaignMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCampaignReports.push(element);
          }
        } else if (element.campaignMetadata.deleted) {
          tempCampaignReports.push(element);
        }
      }
      let campaignReportsSchema = {
        grandTotals: this.deletedCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.deletedReportsCount = tempCampaignReports.length;
      this.deletedCampaignReports = campaignReportsSchema;
    },
    allCampaignReport(key) {
      this.allCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.allCampaignReports.row) {
        if (
          key != '' &&
          element.campaignMetadata.deleted &&
          element.campaignMetadata.status == 'PAUSED' &&
          element.campaignMetadata.status == 'ENABLED'
        ) {
          if (element.campaignMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCampaignReports.push(element);
          }
        } else if (
          element.campaignMetadata.deleted &&
          element.campaignMetadata.status == 'PAUSED' &&
          element.campaignMetadata.status == 'ENABLED'
        ) {
          tempCampaignReports.push(element);
        }
      }
      let campaignReportsSchema = {
        grandTotals: this.allCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.allReportsCount = tempCampaignReports.length;
      this.allCampaignReports = campaignReportsSchema;
    },
    disabledAdgroupReport(key) {
      this.disabledAdgroupReports = this.adgroupReport;
      let tempAdgroupReports = [];
      for (const element of this.disabledAdgroupReports.row) {
        if (key != '' && element.adgroupMetadata.status == 'PAUSED' && !element.adgroupMetadata.deleted) {
          if (element.adgroupMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempAdgroupReports.push(element);
          }
        } else if (element.adgroupMetadata.status == 'PAUSED' && !element.adgroupMetadata.deleted) {
          tempAdgroupReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.disabledAdgroupReports.grandTotals,
        row: tempAdgroupReports,
      };
      this.disabledAdgroupReportsCount = tempAdgroupReports.length;
      this.disabledAdgroupReports = reportsSchema;
    },

    deletedAdgroupReport(key) {
      this.deletedAdgroupReports = this.adgroupReport;
      let tempAdgroupReports = [];
      for (const element of this.deletedAdgroupReports.row) {
        if (key != '' && element.adgroupMetadata.deleted) {
          if (element.adgroupMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempAdgroupReports.push(element);
          }
        } else if (element.adgroupMetadata.deleted) {
          tempAdgroupReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.deletedAdgroupReports.grandTotals,
        row: tempAdgroupReports,
      };
      this.deletedAdgroupReportsCount = tempAdgroupReports.length;
      this.deletedAdgroupReports = reportsSchema;
    },

    disabledKeywordReport(key, filterCount) {
      this.disabledKeywordReports = this.keywordReport;
      let tempKeywordReports = [];
      for (const element of this.disabledKeywordReports.row) {
        if (key != '' && element.keywordMetadata.status == 'PAUSED' && !element.keywordMetadata.deleted) {
          if (element.keywordMetadata.text.toLowerCase().includes(key.toLowerCase())) {
            tempKeywordReports.push(element);
          }
        } else if (element.keywordMetadata.status == 'PAUSED' && !element.keywordMetadata.deleted) {
          tempKeywordReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.disabledKeywordReports.grandTotals,
        row: tempKeywordReports,
      };

      if (filterCount) {
        this.disabledKeywordReportsCount = tempKeywordReports.length;
      }

      this.disabledKeywordReports = reportsSchema;
    },

    deletedKeywordReport(key, filterCount) {
      this.deletedKeywordReports = this.keywordReport;
      let tempKeywordReports = [];
      for (const element of this.deletedKeywordReports.row) {
        if (key != '' && element.keywordMetadata.deleted) {
          if (element.keywordMetadata.text.toLowerCase().includes(key.toLowerCase())) {
            tempKeywordReports.push(element);
          }
        } else if (element.keywordMetadata.deleted) {
          tempKeywordReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.deletedKeywordReports.grandTotals,
        row: tempKeywordReports,
      };

      if (filterCount) {
        this.deletedKeywordReportsCount = tempKeywordReports.length;
      }

      this.deletedKeywordReports = reportsSchema;
    },

    disabledCreativeSetReport(key) {
      this.disabledCreativeSetReports = this.creativeSetReport;
      let tempCreativeSetReports = [];
      for (const element of this.disabledCreativeSetReports.row) {
        if (key != '' && element.creativeSetMetadata.status == 'PAUSED' && !element.campaignMetadata.deleted) {
          if (element.creativeSetMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCreativeSetReports.push(element);
          }
        } else if (element.creativeSetMetadata.status == 'PAUSED' && !element.campaignMetadata.deleted) {
          tempCreativeSetReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.disabledCreativeSetReports.grandTotals,
        row: tempCreativeSetReports,
      };
      this.disabledCreativeSetReportsCount = tempCreativeSetReports.length;
      this.disabledCreativeSetReports = reportsSchema;
    },

    deletedCreativeSetReport(key) {
      this.deletedCreativeSetReports = this.creativeSetReport;
      let tempCreativeSetReports = [];
      for (const element of this.deletedCreativeSetReports.row) {
        if (key != '' && element.campaignMetadata.deleted) {
          if (element.creativeSetMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCreativeSetReports.push(element);
          }
        } else if (element.campaignMetadata.deleted) {
          tempCreativeSetReports.push(element);
        }
      }
      let reportsSchema = {
        grandTotals: this.deletedCreativeSetReports.grandTotals,
        row: tempCreativeSetReports,
      };
      this.deletedCreativeSetReportsCount = tempCreativeSetReports.length;
      this.deletedCreativeSetReports = reportsSchema;
    },

    disabledNegKeywordReport(key) {
      this.disabledNegKeywordReports = this.negKeywordReport;
      let tempNegKeyReports = [];
      for (const element of this.disabledNegKeywordReports) {
        if (key != '' && element.status == 'PAUSED' && element.deleted == false) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.status == 'PAUSED' && element.deleted == false) {
          tempNegKeyReports.push(element);
        }
      }
      this.disabledNegKeywordReportsCount = tempNegKeyReports.length;
      this.disabledNegKeywordReports = tempNegKeyReports;
    },

    deletedNegKeywordReport(key) {
      this.deletedNegKeywordReports = this.negKeywordReport;
      let tempNegKeyReports = [];
      for (const element of this.deletedNegKeywordReports) {
        if (key != '' && element.deleted == true) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.deleted == true) {
          tempNegKeyReports.push(element);
        }
      }
      this.deletedNegKeywordReportsCount = tempNegKeyReports.length;
      this.deletedNegKeywordReports = tempNegKeyReports;
    },
    allNegKeywordReport(key) {
      this.allNegKeywordReports = this.negKeywordReport;
      let tempNegKeyReports = [];
      for (const element of this.allNegKeywordReports) {
        if (
          key != '' &&
          element.deleted == true &&
          element.campaignMetadata.deleted &&
          element.status == 'PAUSED' &&
          element.deleted == false
        ) {
          if (element.text.toLowerCase().includes(key.toLowerCase())) {
            tempNegKeyReports.push(element);
          }
        } else if (element.deleted == true && element.campaignMetadata.deleted && element.status == 'PAUSED' && element.deleted == false) {
          tempNegKeyReports.push(element);
        }
      }
      this.allNegKeywordReportsCount = tempNegKeyReports.length;
      this.allNegKeywordReports = tempNegKeyReports;
    },

    async refetchData() {
      switch (this.currentTab) {
        case AdsManagerTabs.ACCOUNTS: {
          this.$apollo.queries.accountsGraph.refetch();
          this.$apollo.queries.accountsReports.refetch();
          break;
        }
        case AdsManagerTabs.APPS: {
          this.$apollo.queries.appsChart.refetch();
          this.$apollo.queries.appsReports.refetch();
          break;
        }
        case AdsManagerTabs.CAMPAIGNS: {
          this.$apollo.queries.campaignChart.refetch();
          this.$apollo.queries.campaignReportsLive.refetch();
          this.$apollo.queries.campaignReportsPaused.refetch();
          this.$apollo.queries.campaignReportsDeleted.refetch();
          this.$apollo.queries.campaignReportsAll.refetch();
          break;
        }
        case AdsManagerTabs.AD_GROUPS: {
          this.$apollo.queries.adGroupGraph.refetch();
          this.$apollo.queries.adGroupReportsLive.refetch();
          this.$apollo.queries.adGroupReportsPaused.refetch();
          this.$apollo.queries.adGroupReportsDeleted.refetch();
          this.$apollo.queries.adGroupReportsAll.refetch();

          break;
        }
        case AdsManagerTabs.KEYWORDS: {
          this.$apollo.queries.keywordsGraph.refetch();
          this.$apollo.queries.targetingKeywordReportsLive.refetch();
          this.$apollo.queries.targetingKeywordReportsPaused.refetch();
          this.$apollo.queries.targetingKeywordReportsDeleted.refetch();
          this.$apollo.queries.targetingKeywordReportsAll.refetch();
          break;
        }

        // product Page Reports Live
        case AdsManagerTabs.PRODUCT_PAGES: {
          this.$apollo.queries.productPagesGraph.refetch();
          this.$apollo.queries.productPageReportsLive.refetch();
          this.$apollo.queries.productPageReportsPaused.refetch();
          this.$apollo.queries.productPageReportsDeleted.refetch();
          this.$apollo.queries.productPageReportsAll.refetch();
          break;
        }
        case AdsManagerTabs.CREATIVE_SETS: {
          this.$apollo.queries.creativeSetGraph.refetch();
          this.$apollo.queries.creativeSetReports.refetch();
          break;
        }
        case AdsManagerTabs.SEARCH_TERMS: {
          this.$apollo.queries.searchTermsGraph.refetch();
          this.$apollo.queries.searchTermsReports.refetch();
          break;
        }
        case AdsManagerTabs.NEG_KEYWORDS: {
          this.$apollo.queries.negativeKeywordReportsLive.refetch();
          this.$apollo.queries.negativeKeywordReportsPaused.refetch();
          this.$apollo.queries.negativeKeywordReportsDeleted.refetch();
          this.$apollo.queries.negativeKeywordReportsAll.refetch();

          break;
        }
      }
    },
    async navstrategyFilters() {
      this.selectedAccount = 0;
      this.selectedApp = 0;
      this.selectedCampaign = 0;
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.selectedGoal = 0;
      this.selectedCampaignType = null;
      this.currentTab = AdsManagerTabs.STRATEGIES;
      this.tab = 0;
      this.strategies_tab = true;
      await this.runFilterQueries();
    },

    async navAccountFilter() {
      this.selectedAccount = 0;
      this.selectedApp = 0;
      this.selectedCampaign = 0;
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.selectedGoal = 0;
      this.selectedSecondaryGoal = 0;
      this.selectedCampaignType = null;
      this.currentTab = AdsManagerTabs.ACCOUNTS;
      this.tab = 1;
      this.accounts_tab = true;
      await this.runFilterQueries();
    },

    async navAppFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
      }
      this.selectedGoal = 0;
      this.selectedSecondaryGoal = 0;
      this.selectedApp = 0;
      this.selectedCampaign = 0;
      this.selectedCampaignSupplySource = 0;
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.selectedCampaignType = null;
      this.currentTab = AdsManagerTabs.APPS;
      this.tab = 2;
      this.apps_tab = true;
      await this.runFilterQueries();
    },

    async navCampaignFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
        this.selectedApp = LSS.filters?.selectedApp;
        if (LSS.filters?.selectedGoal != 0) {
          this.selectedGoal = LSS.filters?.selectedGoal;
        }
        if (LSS.filters?.selectedCampaignSupplySource != 0) {
          this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource;
        }
      }
      this.selectedCampaign = 0;
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.selectedCampaignType = null;
      this.currentTab = AdsManagerTabs.CAMPAIGNS;
      this.tab = 3;
      this.campaigns_tab = true;
      await this.runFilterQueries();
    },

    async navAdgroupFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
        this.selectedApp = LSS.filters?.selectedApp;
        this.selectedCampaign = LSS.filters?.selectedCampaign;
        if (LSS.filters?.selectedGoal != 0) {
          this.selectedGoal = LSS.filters?.selectedGoal;
        }
        if (LSS.filters?.selectedCampaignSupplySource != 0) {
          this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource;
        }
      }
      this.selectedAdGroup = 0;
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.currentTab = AdsManagerTabs.AD_GROUPS;
      this.tab = 4;
      this.ad_groups_tab = true;
      await this.runFilterQueries();
    },

    async navKeywordFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
        this.selectedApp = LSS.filters?.selectedApp;
        this.selectedCampaign = LSS.filters?.selectedCampaign;
        if (LSS.filters?.selectedGoal != 0) {
          this.selectedGoal = LSS.filters?.selectedGoal;
        }
        if (LSS.filters?.selectedCampaignSupplySource != 0) {
          this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource;
        }
        this.selectedAdGroup = LSS.filters?.selectedAdGroup;
      }
      this.selectedTargetingKeyword = 0;
      this.selectedAd = 0;
      this.currentTab = AdsManagerTabs.KEYWORDS;
      this.tab = 5;
      this.keywords_tab = true;
      await this.runFilterQueries();
    },

    async navAdsFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
        this.selectedApp = LSS.filters?.selectedApp;
        this.selectedCampaign = LSS.filters?.selectedCampaign;
        if (LSS.filters?.selectedGoal != 0) {
          this.selectedGoal = LSS.filters?.selectedGoal;
        }
        if (LSS.filters?.selectedCampaignSupplySource != 0) {
          this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource;
        }
        this.selectedAdGroup = LSS.filters?.selectedAdGroup;
      }

      this.selectedAd = 0;
      this.currentTab = AdsManagerTabs.PRODUCT_PAGES;
      this.product_page_tab = true;
      await this.runFilterQueries();
    },
    async navSearchTermFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
        this.selectedApp = LSS.filters?.selectedApp;
        this.selectedCampaign = LSS.filters?.selectedCampaign;
        if (LSS.filters?.selectedGoal != 0) {
          this.selectedGoal = LSS.filters?.selectedGoal;
        }
        if (LSS.filters?.selectedCampaignSupplySource != 0) {
          this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource;
        }
        this.selectedAdGroup = LSS.filters?.selectedAdGroup;
      }

      this.selectedAd = 0;
      this.currentTab = AdsManagerTabs.SEARCH_TERMS;
      this.search_terms_tab = true;
      await this.runFilterQueries();
    },

    async navNegativeKeywordFilter() {
      if (LSS.filters != null) {
        this.selectedAccount = LSS.filters?.selectedAccount;
        this.selectedApp = LSS.filters?.selectedApp;
        this.selectedCampaign = LSS.filters?.selectedCampaign;
        if (LSS.filters?.selectedGoal != 0) {
          this.selectedGoal = LSS.filters?.selectedGoal;
        }
        if (LSS.filters?.selectedCampaignSupplySource != 0) {
          this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource;
        }
        this.selectedAdGroup = LSS.filters?.selectedAdGroup;
      }

      this.selectedAd = 0;
      this.currentTab = AdsManagerTabs.NEG_KEYWORDS;
      this.neg_keywords_tab = true;
      await this.runFilterQueries();
    },

    formatReasons(servingStateReasons) {
      let reasons = [];
      if (Array.isArray(servingStateReasons) && servingStateReasons.length) {
        servingStateReasons.forEach((r) => {
          const reason = this.titleCase(r.toLowerCase());
          reasons.push(reason);
        });
      }

      return reasons.length
        ? this.$t('messages.onHold') + ' ' + this.$t('messages.reasons') + ': ' + reasons.join(', ')
        : this.$t('messages.onHold');
    },
    titleCase(s) {
      return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));
    },

    selectPreset(preset) {
      switch (preset) {
        case 'TODAY':
          this.filterDateRangeModel = [moment().utc().format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          break;
        case 'YESTERDAY':
          this.filterDateRangeModel = [
            moment().utc().subtract(1, 'days').format('YYYY-MM-DD'),
            moment().utc().subtract(1, 'days').format('YYYY-MM-DD'),
          ];
          break;
        case 'WEEKTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('week').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          break;
        case 'LASTWEEK':
          this.filterDateRangeModel = [
            moment().utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            moment().utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
          ];
          break;
        case 'LASTSEVENDAYS':
          this.filterDateRangeModel = [moment().utc().subtract(6, 'days').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          break;
        case 'MONTHTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('month').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          break;
        case 'LASTMONTH':
          this.filterDateRangeModel = [
            moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          ];
          break;
        case 'LASTTHIRTYDAY':
          this.filterDateRangeModel = [moment().utc().subtract(29, 'days').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          break;
      }
    },
    fetchPinnedView(tab) {
      //fetchnig of all views
      this.$apollo
        .query({
          query: FETCH_CUSTOM_PRESETS,
        })
        .then((info) => {
          for (const data of info.data.fetchCustomView) {
            try {
              data.DatatableDatasets = JSON.parse(data.DatatableDatasets);
            } catch (e) {}
          }
          const getCurrentTempViews = LSS.customViews.filter((o) => o.CustomView == 'TempView');
          LSS.customViews = [...info.data.fetchCustomView, ...getCurrentTempViews];
        });

      const customViews = [...LSS.customViews];

      const cv = customViews.find((o) => o.tab == tab && o.PinnedStatus == true);

      if (cv) {
        this.customView = { _id: cv._id, CustomView: cv.CustomView };
        const flattenedfilters = LSS.customViews.find((i) => i._id == cv._id)?.Filters;
        const tab = LSS.customViews.find((i) => i._id == cv._id)?.tab;
        const sortBy = LSS.customViews.find((i) => i._id == cv._id)?.SortBy;
        const sortDesc = LSS.customViews.find((i) => i._id == cv._id)?.SortDesc;

        if (flattenedfilters) {
          LSS.filters = JSON.parse(flattenedfilters);
        }
        this.selectedAccount = LSS.filters?.selectedAccount || 0;
        this.selectedApp = LSS.filters?.selectedApp || 0;
        this.selectedCampaign = LSS.filters?.selectedCampaign || 0;
        this.selectedAdGroup = LSS.filters?.selectedAdGroup || 0;
        this.selectedTargetingKeyword = LSS.filters?.selectedTargetingKeyword || 0;
        this.selectedGoal = LSS.filters?.selectedGoal || 0;
        this.selectedCampaignSupplySource = LSS.filters?.selectedCampaignSupplySource || 0;
        this.selectedAd = LSS.filters?.selectedAd || 0;

        this.setSortInfo(tab, sortBy, sortDesc);
        this.applyFilters();
        this.applyGraphFilters();
      } else {
        this.customView = { CustomView: 'Default View', _id: '0' };
      }
    },
    setCustomPinnedViewDropDown(PinnedStatus, tab, customId) {
      if (LSS.customViews && LSS.customViews.length > 0) {
        const res = [...LSS.customViews];
        Object.keys(res).forEach((key) => {
          if (res[key]._id !== customId && res[key].tab == tab) {
            res[key].PinnedStatus = false;
          }
        });
        LSS.customViews = res;
        const customViews = [...LSS.customViews];
        let result = customViews
          .filter((o) => o.tab == tab && o.CustomView != 'TempView')
          .map(({ _id, CustomView, PinnedStatus }) => ({ _id, CustomView, PinnedStatus }));
        if (result) this.customViews = [{ CustomView: 'Default View', _id: '0' }, ...result];
        else this.customViews = [{ CustomView: 'Default View', _id: '0' }];
      }
    },
    setCustomViewDropDown(tab) {
      if (LSS.customViews && LSS.customViews.length > 0) {
        //fetch pinned view
        this.fetchPinnedView(tab);
        const customViews = [...LSS.customViews];
        let result = customViews
          .filter((o) => o.tab == tab && o.CustomView != 'TempView')
          .map(({ _id, CustomView, PinnedStatus }) => ({ _id, CustomView, PinnedStatus }));

        if (result) this.customViews = [{ CustomView: 'Default View', _id: '0' }, ...result];
        else this.customViews = [{ CustomView: 'Default View', _id: '0' }];
      }
    },
    setCustomUnPinnedViewDropDown(tab) {
      if (LSS.customViews && LSS.customViews.length > 0) {
        const customViews = [...LSS.customViews];
        let result = customViews
          .filter((o) => o.tab == tab && o.CustomView != 'TempView')
          .map(({ _id, CustomView, PinnedStatus }) => ({ _id, CustomView, PinnedStatus }));
        if (result) this.customViews = [{ CustomView: 'Default View', _id: '0' }, ...result];
        else this.customViews = [{ CustomView: 'Default View', _id: '0' }];
      }
    },
    deleteTempView() {
      if (LSS.customViews && LSS.customViews.length > 0) {
        const customViews = [...LSS.customViews];
        let result = customViews.filter((o) => o._id != '1');

        if (result) LSS.customViews = result;
      }
    },
    async fetchGoals() {
      try {
        const result = await this.$apollo.query({
          query: GET_GOALS,
        });

        this.goalsLength = result.data.getGoals.length;
      } catch (error) {
        this.$notify.error('Error fetching goals');
      }
    },
    fetchCustomViews() {
      this.$apollo
        .query({
          query: FETCH_CUSTOM_PRESETS,
        })
        .then((info) => {
          for (const data of info.data.fetchCustomView) {
            try {
              data.DatatableDatasets = JSON.parse(data.DatatableDatasets);
            } catch (e) {}
          }
          this.customViews = LSS.customViews = info.data.fetchCustomView;
        })
        .catch((error) => {
          this.$notify.error('Facing issues while fetching custom views');
        });
    },
    runGranularityFilters() {
      const graphFilter = {
        ...this.filter,
        ...this.createGraphFilters(),
      };
      this.graphFilter = graphFilter;
    },
    loadGranularityFromCustomView(tab, viewId) {
      const presets = LSS.customViews;
      if (viewId == '0') {
        const preset = presets.find((o) => o._id == '1' && o.tab == tab);
        if (preset && preset.Granularity) {
          this.chartGranularity = preset.Granularity;
          this.runGranularityFilters();
        } else {
          this.chartGranularity = DEFAULT_GRANULARITY;
          this.runGranularityFilters();
        }
      } else {
        const preset = presets.find((o) => o._id == viewId && o.tab == tab);
        if (preset) {
          if (preset.Granularity) {
            this.chartGranularity = preset.Granularity;
            this.runGranularityFilters();
          } else {
            this.chartGranularity = DEFAULT_GRANULARITY;
            this.runGranularityFilters();
          }
        }
      }
    },
  },
  watch: {
    filterDateRange(data) {
      LSS.dateRange = data;
    },
    customView(data) {
      this.showTextField = false;
      switch (this.currentTab) {
        case 'strategies_tab': {
          this.loadGranularityFromCustomView('strategies', data._id);
          break;
        }
        case 'accounts_tab': {
          this.loadGranularityFromCustomView('accounts', data._id);
          break;
        }
        case 'apps_tab': {
          this.loadGranularityFromCustomView('apps', data._id);
          break;
        }
        case 'campaigns_tab': {
          this.loadGranularityFromCustomView('campaigns', data._id);
          break;
        }
        case 'ad_groups_tab': {
          this.loadGranularityFromCustomView('adgroups', data._id);
          break;
        }
        case 'keywords_tab': {
          this.loadGranularityFromCustomView('keywords', data._id);
          break;
        }
        case 'product_page_tab': {
          this.loadGranularityFromCustomView('ads', data._id);
          break;
        }
        case 'search_terms_tab': {
          this.loadGranularityFromCustomView('searchterm', data._id);
          break;
        }
        case 'keywords_tab': {
          break;
        }
      }
    },

    selectedGoal() {
      this.checkIfGoalAttrRequired();
    },
  },
  async mounted() {
    this.fetchGoals();
    if (this.$route.query.strategyId) {
      this.redirectStrategyId = this.$route.query.strategyId;
      this.$router.replace('/ads-manager');
    }
    this.fetchStrategyItems('mounted');
    this.locale = localStorage.getItem('locale') || 'en';
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
    });

    if (LSS.token && decodeToken(LSS.token)) {
      this.TeamID = decodeToken(LSS.token).TeamID;
    }
    Vue.prototype.$sckt.on(this.TeamID, (message) => {
      if (message.type === 'KBBU_UPDATE') {
        for (let index = 0; index < message.keywords.length; index++) {
          const element = message.keywords[index];
          const idx = this.keywordReport.row.findIndex((kr) => kr.keywordMetadata.id == element);
          if (idx < 0) continue;
          this.keywordReport.row[idx].dirty = false;
        }
        this.$refs.livekeywordDatatable.goToFirstPage();
      }
    });

    this.$root.$on('socket-msg-ba', (msg) => {
      if (msg && !msg.hasOwnProperty('error')) {
        this.$notify.success(msg.message);
        this.refetchData();
      } else if (msg && msg.hasOwnProperty('error')) {
        this.$notify.error(msg.message);
        this.refetchData();
      }
    });

    if (LSS.ownApps && LSS.ownApps.length > 0) {
      const allApps = [...LSS.ownApps];
      const attrExistAll = allApps.filter((e) => e.attrExist);
      if (attrExistAll.length > 0) {
        this.addAttrFieldInReport = true;
      }
    }
    this.$root.$on('update-api', (msg) => {
      if (msg.getGoals && (this.selectedGoal != 0 || this.strategyId != 0)) {
        if (msg.tabInfo == 'allcampaign') {
          this.showGoalinCampaignAll = msg.getGoals;
        }
        if (msg.tabInfo == 'livecampaign') {
          this.showGoalinCampaignLive = msg.getGoals;
        }
        if (msg.tabInfo == 'pausedcampaign') {
          this.showGoalinCampaignPaused = msg.getGoals;
        }
        if (msg.tabInfo == 'deletedcampaign') {
          this.showGoalinCampaignDeleted = msg.getGoals;
        }

        if (msg.tabInfo == 'alladgroup') {
          this.showGoalinAdgroupAll = msg.getGoals;
        }
        if (msg.tabInfo == 'liveadgroup') {
          this.showGoalinAdgroupLive = msg.getGoals;
        }
        if (msg.tabInfo == 'pausedadgroup') {
          this.showGoalinAdgroupPaused = msg.getGoals;
        }
        if (msg.tabInfo == 'deletedadgroup') {
          this.showGoalinAdgroupDeleted = msg.getGoals;
        }

        if (msg.tabInfo == 'allkeyword') {
          this.showGoalinKeywordAll = msg.getGoals;
        }
        if (msg.tabInfo == 'livekeyword') {
          this.showGoalinKeywordLive = msg.getGoals;
        }
        if (msg.tabInfo == 'pausedkeyword') {
          this.showGoalinKeywordPaused = msg.getGoals;
        }
        if (msg.tabInfo == 'deletedkeyword') {
          this.showGoalinKeywordDeleted = msg.getGoals;
        }

        if (msg.tabInfo == 'allads') {
          this.showGoalinAdAll = msg.getGoals;
        }
        if (msg.tabInfo == 'ads') {
          this.showGoalinAdLive = msg.getGoals;
        }
        if (msg.tabInfo == 'pausedads') {
          this.showGoalinAdPaused = msg.getGoals;
        }
        if (msg.tabInfo == 'deletedads') {
          this.showGoalinAdDeleted = msg.getGoals;
        }

        if (msg.tabInfo == 'searchterm') {
          this.showGoalinSearchTerm = msg.getGoals;
        }
      }
    });
    this.$root.$on('update-chart-api', (msg) => {
      if (msg.getGoals && (this.selectedGoal != 0 || this.strategyId != 0)) {
        if (msg.tabInfo == 'campaigns') {
          this.showGoalinCampaignChart = msg.getGoals;
        }
        if (msg.tabInfo == 'adgroups') {
          this.showGoalinAdgroupChart = msg.getGoals;
        }
        if (msg.tabInfo == 'keywords') {
          this.showGoalinKeywordChart = msg.getGoals;
        }
        if (msg.tabInfo == 'ads') {
          this.showGoalinAdChart = msg.getGoals;
        }
        if (msg.tabInfo == 'searchterm') {
          this.showGoalSearchtermChart = msg.getGoals;
        }
      }
    });
    this.setCustomViewDropDown('accounts');

    const tab = this.$route.query.tab ? this.$route.query.tab : '';

    if (tab == 'keywords') {
      this.tab = 5;
      await this.onTabChange(this.tabItems[5]);
    } else if (tab == 'adgroups') {
      this.tab = 4;
      await this.onTabChange(this.tabItems[4]);
    } else if (tab == 'campaigns') {
      this.tab = 3;
      await this.onTabChange(this.tabItems[3]);
    } else {
    }

    updateCLF('adsManager', this);
  },
  created() {
    this.startAccountTime = new Date().getTime();
    this.islogAccountProcess = 1;
    bus.$on(BusEvents.DATA_PULLED, async () => {
      this.refetchData();
      await getOwnApps('sync-complete', this);
      LSS.isRefetchRequired = false;
    });
  },
  beforeDestroy() {
    this.$root.$off('socket-msg-ba');
    this.$root.$off('locale-changed');
    this.$root.$off('update-api');
    this.$root.$off('update-chart-api');
    this.selectedGoal = null;
    this.accountsReport = null;
    this.filteredAccountReports = null;
    this.appsReports = null;
    this.filteredAppReports = null;
    this.campaignReportsLive = null;
    this.campaignReport = null;
    this.disabledCampaignReports = null;
    this.campaignReportsPaused = null;
    this.campaignReportsDeleted = null;
    this.deletedCampaignReports = null;
    this.campaignReportsAll = null;
    this.allCampaignReports = null;
    this.adGroupReportsLive = null;
    this.adgroupReport = null;
    this.adGroupReportsPaused = null;
    this.disabledAdgroupReports = null;
    this.adGroupReportsDeleted = null;
    this.deletedAdgroupReports = null;
    this.adGroupReportsAll = null;
    this.allAdgroupReports = null;
    this.targetingKeywordReportsLive = null;
    this.keywordReport = null;
    this.targetingKeywordReportsPaused = null;
    this.disabledKeywordReports = null;
    this.targetingKeywordReportsDeleted = null;
    this.deletedKeywordReports = null;
    this.targetingKeywordReportsAll = null;
    this.allKeywordReports = null;
    this.productPageReportsLive = null;
    this.productPageReport = null;
    this.productPageReportsPaused = null;
    this.adPauseReport = null;
    this.productPageReportsDeleted = null;
    this.adDeletedReport = null;
    this.productPageReportsAll = null;
    this.adAllReport = null;
    this.searchTermsReports = null;
    this.filteredSearchTermsReports = null;
    this.negativeKeywordReportsLive = null;
    this.negKeywordReport = null;
    this.negativeKeywordReportsPaused = null;
    this.disabledNegKeywordReports = null;
    this.negativeKeywordReportsDeleted = null;
    this.deletedNegKeywordReports = null;
    this.negativeKeywordReportsAll = null;
    this.allNegKeywordReports = null;
    this.campaignMetadata = null;
    this.campaignViewMetadata = null;
    this.searchTabMetadata = null;
    this.searchResultMetadata = null;
    this.todayTabMetadata = null;
    this.productPageMetadata = null;
    this.adgroupsTotalSummaryData = null;
    this.keywordsChartData = null;
    this.keywordsTotalSummaryData = null;
    this.productPagesChartData = null;
    this.adsTotalSummary = null;
    this.searchTermsChartData = null;
    this.searchTermsReport = null;
    this.accountsChartData = null;
    this.appsChartData = null;
    this.campaignChartData = null;
    this.campaignTotalSummaryData = null;
    this.campaignMetadata = null;
    this.campaignDeletionMetadata = null;
    this.customView = null;
    this.datasets = null;
    this.datatable = null;
    this.filter = null;
    this.graphFilter = null;
    this.customViews = null;
    this.campaignGraphData = null;
    this.accountGraphData = null;
    this.appsGraphData = null;
    this.adgroupsGraphData = null;
    this.adsGraphData = null;
    this.searchTermGraphData = null;
    this.keyordsGraphData = null;
    this.negkeywordsGraphData = null;
  },
  beforeMount() {
    this.checkIfGoalAttrRequiredInCharts();
    this.checkIfGoalAttrRequired();
    //
  },
});
